import React, { useContext, useState } from 'react'
import { Backdrop, Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import numeral from 'numeral';
import { Link } from 'react-router-dom'
import { Context } from '../../store';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { changeActiveCurrency, getBalanceInFiat, getSelectedFiatCharacter, setActive, updateWallet } from '../../helpers/publicHelper';
import { currencyDetail, currencyFormat, currencyIcon } from '../../helpers/currency';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';


const IOSSwitch = styled((props) => (
    <Box
        onClick={() => props.onChange({ target: { checked: !props.checked } })}
        className={`small cursor-pointer  px-1 m-auto position-relative rounded-pill d-flex justify-content-center align-items-center ${props.checked ? 'bg-success' : 'bg-warning'}`}
        sx={{
            width: '4rem',
            height: 26
        }}>
        <Typography sx={{
            right: 0,
            display: props.checked ? 'none' : 'black'
        }} className={`position-absolute`} variant='caption'>
            <span className={`pe-1 me-1 text-end}`}>Crypto</span>
        </Typography>
        <Box>
            <Box

                className='rounded-circle position-absolute d-flex justify-content-center align-items-center' sx={{
                    backgroundColor: '#fff',
                    height: 22,
                    width: 22,
                    right: props.checked ? 2 : 'auto',
                    left: !props.checked ? 2 : 'auto',
                    top: 2
                }}>
                {props.checked && <MDBIcon className={'text-success'} icon={'dollar-sign'}></MDBIcon>}
                {!props.checked && <Box sx={{ '& img': { padding: '0.1rem' } }}><img className='img-fluid' src='/cryptosymbol.svg' /></Box>}
            </Box>
        </Box>
        {/* <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} /> */}
        <Typography sx={{
            left: 0,
            display: props.checked ? 'block' : 'none'
        }} className={`position-absolute`} variant='caption'>
            <span className={`ps-3 text-start}`}>Fiat</span>
        </Typography>
    </Box>
))(({ theme }) => ({
    width: '100%',
    height: 26,
    padding: 0,
    margin: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#2ECA45',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'transparent',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            // color:
            //     theme.palette.mode === 'light'
            //         ? theme.palette.green[100]
            //         : theme.palette.green[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#FFA900',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const useStyles = makeStyles((theme) => ({
    depositRoot: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    currencyMobile: {
        textAlign: 'center',
        cursor: 'pointer',
        color: '#fff',
        border: 0,
        width: 30,
        padding: '0 0 0',
        color: '#fff',
        marginLeft: 3,
        borderRadius: 0,
        '& small': {
            display: 'inline-block',
            width: '65%',
            fontWeight: 'bold',
            float: 'left',
            color: '#fff'
        },
        '& i': {
            float: 'right',
            paddingLeft: '6px',
            fontSize: "0.7em"
        }
    },
    depositButton: {
        border: 0,
        right: '0.3rem',
        top: '6px',
        width: '1rem',
        height: 30,
        textAlign: 'right',
        padding: '0px 0px 9px',
        position: 'fixed',
        '& i': {
            fontSize: '0.6em',
            marginTop: '-0.5rem'
        }
    },
    inputWrapper: {
        marginTop: '0px',
        '& small': {
            fontSize: '0.7em',
            position: 'absolute',
            right: '6px',
            top: '0px',
            color: '#fff'
        }
    },
    input: {
        border: 0,
        boxShadow: 'inset -1px -0.8px 4px -1px #fff6',
        padding: '0',
        width: '11rem',
        textAlign: 'center',
        color: '#fff',
        margin: 'auto',
        fontSize: '0.8rem !important'
    },
    currencyIcon: {
        height: '1.3rem',
        margin: '-5px 0 2px 0.1rem'
    },
}));

const BalanceSection = (props) => {
    const { setOpenRight } = props;
    const [state, dispatch] = useContext(Context);
    const [open, setOpen] = useState(false)

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setOpen(!open)
        if (event) {
            setAnchorEl(event.currentTarget);
        }
    };

    return (<>

        {state.login ? <Box className="input-group position-fixed text-center mx-auto p-0 overflow-hidden" sx={{
            left: '0',
            right: '0',
            top: ['0.35rem','0.7rem'],
            width: '11rem',

        }}>
            <div className={classes.inputWrapper + ` position-relative cursor-pointer rounded-pill`} onClick={handleClick}>
                <span className={classes.currencyMobile + ' position-absolute input-group-text'}>
                    <img src={currencyIcon(state.user.profile.currency)} className={classes.currencyIcon} />
                </span>
                <input onFocus={(e) => e.currentTarget.blur()} type="text"
                    className={classes.input + ` form-control rounded-pill py-1 bg-black cursor-pointer ${state.active.env == 'stage' ? 'border border-warning ' : ''} `}
                    style={{ fontSize: '1rem' }}
                    value={
                        state.active.showFiat ?
                            ('$' + getBalanceInFiat(state, state.user.profile.currency, state.active.fiat))
                            :
                            numeral((state.user.wallet && state.user.wallet[state.user.profile.currency]) ? state.user.wallet[state.user.profile.currency].balance : 0).format(currencyFormat(state.user.profile.currency))
                    } readOnly />
                <span className={classes.depositButton + ` text-white rounded-0 position-absolute input-group-text text-center text-white`}>
                    {state.active.currencyUpdating && false ?
                        <MDBIcon icon='sync' spin></MDBIcon> : <MDBIcon icon='chevron-down'></MDBIcon>
                    }
                </span>
            </div>

            <CurrencySelect setOpenRight={setOpenRight} open={open} setOpen={setOpen} state={state} dispatch={dispatch} anchorEl={anchorEl} handleClick={handleClick}></CurrencySelect>
        </Box>
            : <div className="input-group position-fixed text-center mx-auto p-0 overflow-hidden my-2 align-items-center" style={{
                left: '0',
                right: '0',
                top: '0rem',
                width: 'fit-content',
            }}>
                <Fade
                    timeout={1000}
                    className='cursor-pointer' in={!state.login} >
                    <div>
                        <Link to='/'>
                            <Box sx={{ height: ['2rem', '2rem', '45px'] }}>
                                <img src={`/logo/${state.active.env == 'stage' ? 'logo-neon-gold' : 'logo-tm'}.svg`} className='' style={{ height: '80%' }} />
                            </Box>
                        </Link>
                    </div>
                </Fade>
            </div>}

        <Backdrop
            sx={{ zIndex: -1, width: '100vw', left: 0, position: 'fixed' }}
            open={open}
            onClick={() => setOpen(false)}>
        </Backdrop>

    </>);
}

const CurrencySelect = (props) => {
    const { anchorEl, state, dispatch, open, setOpen, setOpenRight } = props;
    const { enqueueSnackbar } = useSnackbar();

    const handleSetActiveCurrency = (currency) => {
        changeActiveCurrency(state, dispatch, currency);
    }

    const [isUpdating, setIsUpdating] = useState('')

    const updating = (currency) => {
        setIsUpdating(currency);
        updateWallet(state, currency, enqueueSnackbar)
        setTimeout(() => {
            setIsUpdating('');
        }, 60000);
    }

    const handleShowFiat = (e) => {
        console.log(e.target.checked);
        setActive(state, dispatch, { showFiat: e.target.checked })
    }
    return (
        <Popper className='mt-1' style={{ zIndex: 9999 }} open={open} anchorEl={anchorEl} placement='bottom-end' transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper className='box-shadow-neon rounded-3 overflow-hidden'>
                        <List className='' sx={{ width: '11rem', bgcolor: 'background.paper' }}>
                            {
                                state.config.currencyList.map((currency, i) => <ListItem
                                    className='cursor-pointer text-hover-shadow-neon pb-0 pt-1 px-2'
                                    key={i}>
                                    <ListItemAvatar
                                        onClick={() => { handleSetActiveCurrency(currency); setOpen(false) }}
                                        className='position-relative' style={{ minWidth: '2.1rem' }}>
                                        <div className='position-relative' style={{ height: '1.5rem', display: 'flex', alignItems: 'center' }}>
                                            <img className='position-absolute'
                                                style={{
                                                    left: 0,
                                                    borderRadius: '50rem',
                                                    width: '1.5rem',
                                                    height: '1.5rem',
                                                }} src={currencyIcon(currency)} />
                                            <span className='position-absolute'
                                                style={{
                                                    position: 'absolute',
                                                    display: 'inline-block',
                                                    left: '0.05rem',
                                                    top: '0.0rem',
                                                    borderRadius: '50rem',
                                                    border: state.user.profile.currency == currency ? '0.15rem solid #26ddff' : '0.15rem solid transparent',
                                                    boxShadow: state.user.profile.currency == currency ? '0px 0px 1rem #26ddff' : '',
                                                    width: '1.45rem',
                                                    height: '1.45rem',
                                                    zIndex: 999
                                                }}>
                                            </span>
                                        </div>
                                    </ListItemAvatar>
                                    {!state.active.showFiat && <ListItemText
                                        onClick={() => { handleSetActiveCurrency(currency); setOpen(false) }}
                                        className={state.user.profile.currency == currency ? 'text-neon' : 'text-white'}
                                        primary={<small style={{ textTransform: 'uppercase', fontSize: '0.7rem' }}>{numeral(state.user.wallet[currency] ? state.user.wallet[currency].balance : 0).format(currencyDetail[currency].format)} <small className='small'>{currency}</small></small>}
                                        secondary={<small className='opacity-80'>{getSelectedFiatCharacter(state.active.fiat)} {getBalanceInFiat(state, currency, state.active.fiat)}</small>} />}
                                    {state.active.showFiat && <ListItemText
                                        onClick={() => { handleSetActiveCurrency(currency); setOpen(false) }}
                                        className={state.user.profile.currency == currency ? 'text-neon' : 'text-white'}
                                        primary={<small style={{ textTransform: 'uppercase', fontSize: '0.7rem' }}>$ {getBalanceInFiat(state, currency, state.active.fiat)}</small>}
                                        secondary={<small className='opacity-80'>{numeral(state.user.wallet[currency] ? state.user.wallet[currency].balance : 0).format(currencyDetail[currency].format)} <small className='small'>{currency}</small></small>} />}
                                    <ListItemAvatar className='position-relative' style={{ minWidth: '0.6rem', fontSize: '0.6rem', display: 'flex', alignItems: 'end' }}>
                                        <MDBIcon
                                            onClick={() => isUpdating ? {} : updating(currency)}
                                            spin={isUpdating == currency}
                                            className={`cursor-pointer ${isUpdating ? 'opacity-50' : ''}`} icon='sync'></MDBIcon>
                                    </ListItemAvatar>
                                </ListItem>)
                            }
                        </List>
                        <Divider></Divider>
                        <MDBRow className='m-0 text-center justify-content-center'>
                            {
                                state.config.fiatList.map((fiat, i) =>
                                    <MDBCol key={i} size='2' className='pt-2 pb-1 px-1 position-relative' style={{ height: '2.5rem' }}>
                                        <img className='position-absolute cursor-pointer p-0 m-auto'
                                            onClick={() => setActive(state, dispatch, { fiat: fiat })}
                                            style={{
                                                left: 0,
                                                right: 0,
                                                borderRadius: '50rem',
                                                width: '1.4rem',
                                                height: '1.4rem',
                                            }} src={currencyIcon(fiat)} />
                                        <span className='position-absolute cursor-pointer p-0 m-auto'
                                            onClick={() => setActive(state, dispatch, { fiat: fiat })}
                                            style={{
                                                position: 'absolute',
                                                display: 'inline-block',
                                                left: 0,
                                                right: 0,
                                                borderRadius: '50rem',
                                                border: state.active.fiat == fiat ? '2px solid #26ddff' : '1px solid transparent',
                                                boxShadow: state.active.fiat == fiat ? '0px 0px 10px #26ddff' : '',
                                                width: '1.4rem',
                                                height: '1.4rem',
                                                zIndex: 999
                                            }}>
                                        </span>
                                    </MDBCol>
                                )
                            }
                        </MDBRow>
                        <Divider></Divider>
                        <MDBRow className='m-0'>
                            <MDBCol size='6' className='px-0 py-2'>
                                <IOSSwitch
                                    checked={state.active.showFiat}
                                    onChange={handleShowFiat} sx={{ m: 1 }} />
                            </MDBCol>
                        </MDBRow>

                        <Divider></Divider>
                        <MDBRow className='m-0 text-center'>
                            <MDBCol size='12' className='py-2'>
                                <Button onClick={() => { setOpenRight(true); setOpen(false) }} className='bg-success text-white d-block w-100' color='success'>Deposit Now</Button>
                            </MDBCol>
                        </MDBRow>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
}

export default BalanceSection



