import React, { useContext, useEffect } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Context } from '../../../store';
import { getBearer, setActive } from '../../../helpers/publicHelper';
import _ from 'lodash';
import ThemeInput from '../../components/ThemeInput';
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

// Submit Address
const callVerifyAddress = (state, handleSetActive, enqueueSnackbar) => {
    handleSetActive({ waiting: true });
    const postData = {
        line1: state.active.kyc.address.line1,
        city: state.active.kyc.address.city,
        country: state.active.kyc.address.country,
    };
    axios.post(process.env.REACT_APP_API_HOST + '/api/users/kyc/address', postData,
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                handleSetActive({
                    waiting: false
                })
                enqueueSnackbar(res.message, {
                    variant: 'success', autoHideDuration: 3000
                })
            } else {
                if (res.code == 429) {
                    handleSetActive({
                        waiting: false
                    })

                    enqueueSnackbar(res.message, {
                        variant: 'warning', autoHideDuration: 3000
                    })
                } else {
                    handleSetActive({
                        waiting: false
                    })
                    enqueueSnackbar(res.message, {
                        variant: 'error', autoHideDuration: 3000
                    })
                }

            }
        })
        .catch((err) => {

        })
}


const KYCAddress = (props) => {
    const [state, dispatch] = useContext(Context);
    const stateKYC = state.user ? state.user.kyc : null;
    const addressVerified = _.has(stateKYC, 'address.line1') ? stateKYC.address.line1 : '';
    const { mode, line1 } = state.active.kyc.address;
    const { enqueueSnackbar } = useSnackbar();

    const handleSetActive = (activeObj) => {
        setActive(state, dispatch, {
            kyc: {
                ...state.active.kyc,
                address: {
                    ...state.active.kyc.address,
                    ...activeObj
                }
            }
        });
    }

    const validateAddress = (line1) => {
        if (line1 != '') {
            callVerifyAddress(state, handleSetActive, enqueueSnackbar)
        }
        else {
            enqueueSnackbar('Invalid Address', {
                variant: 'error', autoHideDuration: 3000
            })
        }
    }

    useEffect(() => {
        let autocomplete;
        function initMap() {
            autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
                type: ['establishment'],
                componentRestrictions: { country: ['AU'] },
                fields: ['place_id', 'geometry', 'name']
            });
        }
    }, [])

    return (<>
        {
            addressVerified ?
                /* VERIFIED Email */
                <ListItem
                    secondaryAction={<Avatar className={`bg-transparent box-shadow-success border border-success`}><MDBIcon icon='check' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                    <ListItemText
                        primary={<>
                            <small className='d-block'>{stateKYC.address.line1}</small>
                            <small className='d-block'>{stateKYC.address.city} - {stateKYC.address.country}</small>
                        </>}
                        className='pe-3'
                        secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                        secondary={<small className='text-success'>Verified</small>}
                    />
                </ListItem>
                :
                /* NOT VERIFIED */
                <>
                    {/* <GooglePlacesAutocomplete apiKey="AIzaSyDhC1sEdxwQlJE6zxe7THi16Rhy3zu-YvQ" /> */}
                    {mode == '' ?
                        <ListItem
                            secondaryAction={<Avatar onClick={() => handleSetActive({ mode: 'add' })} className={`bg-transparent cursor-pointer box-shadow-neon border border-neon`}><MDBIcon icon='plus' color='neon' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                            <ListItemText
                                primary={<small>{'No Address'}</small>}
                                className='pe-3'
                                secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                                secondary={<small className='text-neon text-shadow-neon'>Add your address</small>} />
                        </ListItem> : <></>
                    }
                    {mode == 'add' ?
                        <ListItem
                            secondaryAction={<Avatar onClick={() => validateAddress(line1)} className={`bg-transparent cursor-pointer box-shadow-neon border border-neon`}><MDBIcon icon='map-marker-alt' color='white' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                            <ListItemText
                                primary={<>
                                    <ThemeInput
                                        label='Address'
                                        value={state.active.kyc.address.line1}
                                        type='text'
                                        onChange={e => handleSetActive({ line1: e.target.value })}></ThemeInput>
                                    <ThemeInput
                                        label='City'
                                        value={state.active.kyc.address.city}
                                        type='text'
                                        onChange={e => handleSetActive({ city: e.target.value })}></ThemeInput>
                                    <ThemeInput
                                        label='Country'
                                        value={state.active.kyc.address.country}
                                        type='text'
                                        onChange={e => handleSetActive({ country: e.target.value })}></ThemeInput>
                                </>
                                }
                                className='pe-3 me-1' />
                        </ListItem> : <></>
                    }
                </>
        }
    </>)
}

export default KYCAddress
