import React from 'react';
import ReactDOM from 'react-dom';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import './styles/index.scss';
import App from './App';
import './i18n';
import { SocketContext, chatSocket } from './context/socket';
import { CookiesProvider } from 'react-cookie';
import Store from './store';

ReactDOM.render(
  <Store>
    <CookiesProvider>
      <SocketContext.Provider value={chatSocket}>
      {/* <SocketContext.Provider value={imSocket}> */}
        <App />
      {/* </SocketContext.Provider> */}
      </SocketContext.Provider>
    </CookiesProvider>
  </Store>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
