import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { currencyIcon } from '../../helpers/currency.js';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';


const TransactionItem = (props) => {
    const { createdAt, meta, dayChange, type, index } = props;

    return <Box sx={{
        borderBottom: '1px solid #26ddff55'
    }}>
        {dayChange && <Box className='w-100' sx={{
            bgcolor: '#000'
        }}>
            <Box className='d-flex justify-content-between w-100 py-2 px-1 text-warning' sx={{
                boxShadow: '0 -2px 10px #26ddff99'
            }}>
                <Typography variant='body2'>
                    {moment(createdAt).format('YYYY/MM/DD')}
                </Typography>
                <Typography variant='body2'>
                    {moment(createdAt).format('dddd')}
                </Typography>
            </Box>
            <Box className='d-flex justify-content-between w-100 '>
                <Grid container className='d-flex justify-content-between align-items-center py-1 px-1' columns={15}
                    sx={{
                        fontSize: '0.6rem',
                        borderBottom:'1px solid #333'
                    }}>
                    <Grid item xs={2}>Time</Grid>
                    <Grid item xs={6}>{type == 'bet' ? 'Bet Amount' : 'Amount'}</Grid>
                    <Grid item xs={4}>{type == 'bet' ? 'Provider/Bet Id' : 'Type'}</Grid>
                    <Grid item xs={3} className='text-end'>{type == 'bet' ? 'Result' : 'Details'}</Grid>
                </Grid>
            </Box>
        </Box>}
        <Grid container className='d-flex justify-content-between align-items-center py-2 px-1' columns={15} sx={{
            background: index % 2 ? '#000' : '#011'
        }}>
            <RenderTime {...props} />
            <RenderAmount {...props} />
            <RenderTypeSubtype {...props} />
            <RenderDetails {...props} />
        </Grid>
    </Box>
}

const RenderTime = (props) => {
    const { createdAt } = props;
    return <Grid item xs={2}>
        <Typography variant='caption' className='text-start fw-bold opacity-60' >
            <small>
                {moment(createdAt).format('HH:mm')}
            </small>
        </Typography>
    </Grid>
}

const RenderAmount = (props) => {
    const { type, subtype, status, meta, currency } = props;
    const amountInUSD = type == 'bet' ? meta.bet.betAmountInUSD : meta.amountInUSD;
    const color1 = type == 'deposit' ? 'success' : type == 'withdraw' ? 'danger' : 'neon';
    return <Grid item xs={6}>
        <Typography variant='subtitle2' className={`text-start text-${color1}`}>
            <small title={type == 'bet' ? 'Bet Amount' : 'Amount'}>
                <img className='me-1'
                    style={{
                        borderRadius: '50rem',
                        width: '1rem',
                        height: '1rem',
                    }} src={currencyIcon(currency)} />
                {numeral(amountInUSD).format('$0,0.00')}
            </small>
        </Typography>
    </Grid>
}

const RenderTypeSubtype = (props) => {
    const { type, subtype, status, service } = props;
    const color1 = type == 'deposit' ? 'success' : type == 'withdraw' ? 'danger' : 'neon';
    const color2 = status == 'verified' ? 'success' : 'danger';
    let text = type == 'topup' ? `${type} ${subtype}` : type;
    text = (subtype.indexOf('Discount') > -1) ? `${subtype.replace('Discount', ' Discount')}` : text;
    let serviceName = service;
    if (service == 'Crash') serviceName = 'imoon';
    return <Grid item xs={4}>
        <Typography variant='caption' className={`text-capitalize text-${color1}`} >
            <span className='d-flex justify-content-start'>
                {
                    type == 'bet' ?
                        <>
                            <img className={`img-fluid -m-auto ${''}`}
                                style={{ height: '1rem', width: '1rem' }}
                                // src={`https://cdn.imoon.com/lobby/providerslogos/lobby_${'evolution'}.svg?8`}
                                src={`https://cdn.imoon.com/lobby/sliderlogos/${serviceName?.toLowerCase()}.svg`}
                            />
                            <RenderBetId {...props} />
                        </>
                        :
                        text
                }
            </span>
        </Typography>
    </Grid>

}

const RenderDetails = (props) => {
    const { type, subtype, status } = props;
    let text = type == 'topup' ? `${type} ${subtype}` : type;
    text = (subtype.indexOf('Discount') > -1) ? `${subtype.replace('Discount', ' Discount')}` : text;
    return <Grid item xs={3} className='text-end'>
        {type == 'bet' ? <RenderBetStatus {...props} />
            :
            <Typography variant='caption' className={`text-capitalize`}>
                {
                    status == 'verified' ?
                        <ViewOnBlockchain {...props} />
                        :
                        <Typography variant='caption' className={`text-danger rounded-2 border border-danger px-2 py-1 text-center`} >
                            <small>
                                {status}
                            </small>
                        </Typography>
                }
            </Typography>
        }
    </Grid>
}

const RenderBetStatus = (props) => {
    const { type, subtype, status } = props;
    const color = subtype == 'won' ? 'success' : subtype == 'lost' ? 'danger' : subtype == 'open' ? 'warning' : 'warning';
    return <Box>
        <Typography variant='caption' className={`text-${color} text-capitalize rounded-2 border border-${color} px-2 py-1 text-center`} >
            <small>
                {subtype}
            </small>
        </Typography>
    </Box>
}

const ViewOnBlockchain = (props) => {
    const { currency, meta } = props;
    const txId = meta.transactionId;
    let href = '';
    switch (currency) {
        case 'BTC':
            href = `https://blockchair.com/bitcoin/transaction/${txId}`
            break;
        case 'ETH':
            href = `https://etherscan.io/tx/${txId}`
            break;
        case 'TRX':
            href = `https://tronscan.org/#/transaction/${txId}`
            break;
        case 'DOGE':
            href = `https://blockchair.com/dogecoin/transaction/${txId}`
            break;
        default:
            break;
    }
    return <a target='_blank' href={href}>
        <Typography variant='caption' className={`text-neon text-shadow-neon rounded-2 border border-neon px-2 py-1 box-shadow-neon text-center`} >
            View
        </Typography>
    </a>
}

const RenderBetId = (props) => {
    const { bet } = props.meta;
    const { id } = bet;
    const { enqueueSnackbar } = useSnackbar();

    return <span className='text-yellow opacity-90 ps-2 small'>
        <CopyToClipboard text={id}
            className='cursor-pointer'
            onCopy={() => {
                enqueueSnackbar('Copied', {
                    variant: 'success', autoHideDuration: 1500
                })
            }}>
            <span className='border d-inline-block rounded-circle px-2 py-1'>
                <MDBIcon icon='copy' /> BetID
            </span>
        </CopyToClipboard>
        {/* {id} */}
    </span>
}

export default TransactionItem