import React, { useContext, useEffect, useState } from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Context } from '../../store.js';
import numeral from 'numeral';
import moment from 'moment';
import { currencyFormat, currencyIcon } from '../../helpers/currency.js';
import { Chip, Divider, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { getBearer } from '../../helpers/publicHelper.js';
import { Box } from '@mui/system';
import TransactionItem from './TransactionItem.js';

const Transactions = (props) => {
    const { type } = props;
    const [state, dispatch] = useContext(Context);
    const [transactionsList, setTransactionsList] = useState([]);
    const [isUpdating, setIsUpdating] = useState(true)
    const getTransactions = () => {
        axios.post(state.active.host + '/api/users/transactions', {
            type: type || ['deposit', 'withdraw', 'topup'],
            // from, // default: 1month ago
            // to, // default: tomorrow
            count: 99// default: 10
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then((res) => {
                setIsUpdating(false)
                res = res.data;
                if (res.success) {
                    setTransactionsList(res.result)
                }
            })
            .catch((err) => {
                try {
                    console.log('err', err.response.data.message);
                } catch (error) {
                    console.log('error');
                }

            })
    }

    useEffect(() => {
        getTransactions()
    }, [])

    return (
        <Box className='py-1'>
            {transactionsList && transactionsList.length ?
                transactionsList.map((transaction, i) => {
                    const currentTXDate = moment(transaction.createdAt).format('YYYY-MM-DD');
                    const previousTXDate = moment(transactionsList[i - 1]?.createdAt).format('YYYY-MM-DD');
                    const dayChange = (i == 0) || (transactionsList[i - 1] && (currentTXDate != previousTXDate));
                    return <TransactionItem key={i} dayChange={dayChange} {...transaction} index={i} />
                }
                )
                :
                <>
                    {
                        isUpdating ?
                            <Box className='py-5'>
                                <div style={{ left: 0, right: 0, marginBottom: '0rem' }} className='neonProgress mx-auto'></div>
                            </Box>
                            :
                            <Box className='py-5 text-center'>
                                <Typography variant='caption' className='text-warning text-center py-3'>
                                    No transaction yet
                                </Typography>
                            </Box>
                    }
                </>
            }
        </Box>
    )
}


export default Transactions
