import React, { useContext } from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Alert, Avatar, Badge, CircularProgress, styled, Typography } from '@mui/material';
import { Context } from '../../store.js';
import { changeActiveCurrency, setActive } from '../../helpers/publicHelper';
import QRCode from 'react-qr-code';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import ThemeButton from '../components/ThemeButton.js';
import numeral from 'numeral';
import { currencyDetail, currencyFormat, currencyIcon } from '../../helpers/currency.js';
import SelectCurrency from './SelectCurrency.js';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const Deposit = () => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const handleSetActive = (activeObj) => {
        setActive(state, dispatch, activeObj)
    }

    const handleSetActiveCurrency = (currency) => {
        changeActiveCurrency(state, dispatch, currency)
        // updateWallet(state, currency, enqueueSnackbar)
    }

    return <>
        <SelectCurrency />
        <MDBRow className='m-0 mb-2 pb-2 pt-3 px-3'>

            {
                state.active.currency == 'USDT' ? <MDBCol size='12'>
                    <MDBRow className='justify-content-center'>
                        <MDBCol size='4' className={'text-center cursor-pointer text-hover-neon ' + (state.active.blockchain !== 'TRX' ? 'opacity-50' : '')}
                            onClick={() => {
                                handleSetActive({ blockchain: 'TRX' });
                            }}>
                            <Badge
                                className='mb-2'
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <SmallAvatar alt='TRX' src={currencyIcon('TRX')} />
                                }>
                                <Avatar
                                    sx={{
                                        width: 24, height: 24,
                                    }}
                                    alt='USDT' src={currencyIcon('USDT')} />
                            </Badge>
                            <br />
                            <small className={state.active.blockchain == 'TRX' ? 'text-neon text-shadow-neon' : ''} style={{ fontSize: '0.7rem' }}>TRC-20</small>
                        </MDBCol>
                        <MDBCol size='4' className={'text-center cursor-pointer text-hover-neon ' + (state.active.blockchain !== 'ETH' ? 'opacity-50' : '')}
                            onClick={() => {
                                handleSetActive({ blockchain: 'ETH' });
                            }}>
                            <Badge
                                className='mb-2'
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <SmallAvatar alt="ETH" src={currencyIcon('ETH')} />
                                }>
                                <Avatar
                                    sx={{ width: 24, height: 24 }}
                                    alt="USDT" src={currencyIcon('USDT')} />
                            </Badge>
                            <br />
                            <small className={state.active.blockchain == 'ETH' ? 'text-neon text-shadow-neon' : ''} style={{ fontSize: '0.7rem' }}>ERC-20</small>
                        </MDBCol>
                        {
                            state.user.wallet[state.active.blockchain] ?
                                <CryptoQR state={state} value={typeof (state.user.wallet[state.active.blockchain].address) == 'string' ? state.user.wallet[state.active.blockchain].address : ''}></CryptoQR>
                                :
                                <div className='text-center pt-4' style={{ height: '150px' }}>
                                    <CircularProgress color='info'></CircularProgress>
                                </div>
                        }
                    </MDBRow>
                </MDBCol>
                    :
                    state.user.wallet && state.user.wallet[state.active.currency] ?
                        state.active.currency !== 'USD' ?
                            state.user.wallet[state.active.currency].address ?
                                state.user.wallet[state.active.currency].address ?
                                    <CryptoQR state={state} value={typeof (state.user.wallet[state.active.currency].address) == 'string' ? state.user.wallet[state.active.currency].address : ''}></CryptoQR>
                                    : <Alert sx={{ fontSize: '0.7rem' }} variant="outlined" severity="warning">No Address!!</Alert>
                                : <Alert sx={{ fontSize: '0.7rem' }} variant="outlined" severity="warning">No Address!</Alert>
                            : <Alert sx={{ fontSize: ['0.6rem', '0.6rem'] }} variant="outlined" severity="info">USD deposit not available.</Alert>
                        : <div className='text-center pt-4' style={{ height: '150px' }}>
                            <CircularProgress color='success'></CircularProgress>
                        </div>
            }

        </MDBRow>
    </>

}


const CryptoQR = (props) => {
    const { value, state } = props;
    const { currency } = state.active;
    const { enqueueSnackbar } = useSnackbar();

    return <>
        <MDBCol size='12' className='text-center mt-1'>
            <span className='border border-white rounded p-1 d-inline-block bg-white'>
                <QRCode size={150} level='L' value={value} />
            </span>
        </MDBCol>
        <MDBCol size='12' className='text-center px-0 pt-2 mt-1'>
            <Typography
                sx={{
                    fontSize: ['0.6rem', '0.5rem']
                }}>
                {value}
            </Typography>
        </MDBCol>
        <MDBCol size='12' className='text-center'>
            <MDBRow>
                <MDBCol size='6' className='p-1 text-end'>
                    <CopyToClipboard
                        text={value}
                        onCopy={() => {
                            enqueueSnackbar('Copied', {
                                variant: 'success', autoHideDuration: 1500
                            })
                        }}>
                        <ThemeButton variant='outlined' size='small' className='my-2 px-3 py-1'>Copy Address</ThemeButton>
                    </CopyToClipboard>
                </MDBCol>
                <MDBCol size='6' className='p-1 text-start'>
                    <a href={(currencyDetail[currency] ? (currency == 'USDT' ? (currencyDetail[state.active.blockchain].title) : (currencyDetail[currency].title)) + ':' : '') + value} target='_blank'>
                        <ThemeButton variant='outlined' size='small' className='my-2 px-3 py-1'>Open Wallet</ThemeButton>
                    </a>
                </MDBCol>
            </MDBRow>

        </MDBCol>
        <MDBCol size='12' className='text-center'>
            <img className='me-1'
                style={{
                    borderRadius: '50rem',
                    width: '1rem',
                    height: '1rem',
                }} src={currencyIcon(currency)} />
            <small>
                {numeral(state.user.wallet[currency] ? state.user.wallet[currency].balance : 0).format(currencyFormat(currency))}
            </small>
            <small className='ps-1'>
                {currency.toUpperCase()}
            </small>
        </MDBCol>
    </>
}


export default Deposit
