
import React, { useContext } from 'react';
import { Context } from '../store';
import { countries } from '../helpers/publicHelper';
import { Typography } from '@mui/material';
import ThemeButton from '../layout/components/ThemeButton';
import { MDBIcon } from 'mdb-react-ui-kit';

const getCountryNameByCode = (code) => {
    const country = countries.filter(c => c.code == code);
    if (!country) return 'your region';
    return country[0].label;
}

const GeoBlock = (props) => {
    const { mode } = props;
    const [state, dispatch] = useContext(Context);

    return (
        <>
            {
                (window.location.origin.indexOf(':300') == -1) && state.config.geoAccess && (state.config.geoAccess[mode] === false) &&
                <div className='position-fixed d-flex justify-content-center align-items-center' style={{
                    width: '100vw',
                    height: '100vh',
                    zIndex: mode == 'imoon' ? '1300' : '100',
                    top: 0,
                    left: 0,
                    backgroundColor: '#000000aa',
                }}>
                    <div className='text-center bg-black py-4 px-2 p-md-4 rounded-6 border border-neon box-shadow-neon mx-3'>
                        <img src={`/logo/logo-neon-${state.active.env == 'stage' ? 'gold' : 'gradient'}.svg`} alt='iMoon Logo' className='w-25 pb-4' />
                        <h6 className='text-danger'>Sorry,&nbsp;
                            {mode == 'imoon' && 'iMoon is'}
                            {mode == 'evolution' && 'Evolution games are'}
                            {mode == 'netent' && 'Netent games are'}
                            {mode == 'redTiger' && 'Red Tiger games are'}
                            {mode == 'digitain' && 'Digitain games are'}
                            {mode == 'everyMatrix' && 'EveryMatrix games are'}
                            {mode == 'pragmatic' && 'Pragmatic games are'}
                            &nbsp;not available in your region.</h6>
                        {mode == 'imoon' ?
                            <Typography variant='body2'>
                                Due to our gaming license, we cannot accept players from {getCountryNameByCode(state.countryCode)}.
                                <br />
                                Contact us via support@imoon.com if you require any further assistance.
                            </Typography>
                            : <div>
                                <ThemeButton onClick={() => window.history.back()} variant='outlined' color='neon' className='px-3 mt-3'>
                                    <MDBIcon className='pe-1' icon='reply'></MDBIcon>
                                    Back to the Games List
                                </ThemeButton>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default GeoBlock
