import React, { Fragment, useContext, useEffect, useState } from 'react'
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Context } from '../../store.js';
import { convertByRates, getBearer, setActive } from '../../helpers/publicHelper';
import ThemeButton from '../components/ThemeButton.js';
import ThemeInput from '../components/ThemeInput.js';
import { currencyFormat, currencyIcon } from '../../helpers/currency.js';
import { Alert, AppBar, Avatar, Badge, Collapse, Divider, Grid, Modal, Table, Toolbar, Typography } from '@mui/material';
import numeral from 'numeral';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import WAValidator from 'multicoin-address-validator';
import WithdrawLimits from './WithdrawLimits.js';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SelectCurrency from './SelectCurrency.js';
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const Withdraw = () => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [validAddress, setValidAddress] = useState(false);
    const [overdraft, setOverdraft] = useState(false)

    const [values, setValues] = useState({
        currency: state.active.currency,
        balance: 0,
        balanceInUSD: 0,
        fee: 0,
        feeInUSD: 0,
        lockedBalance: 0,
        totalUserBalanceInUSD: 0,
        withdrawableAmount: 0
    })
    const handleSetActive = (activeObj) => {
        setActive(state, dispatch, activeObj);

    }
    const [openHelp, setOpenHelp] = useState(false)

    const handleWithdrawChange = (e) => {
        let value = e.target.value;
        console.log('withdraw_value::', value);
        if (value === '') return handleSetActive({ withdrawValue: value })
        if (value == '00') return handleSetActive({ withdrawValue: '0' })
        value = parseFloat(e.target.value || 0);
        if (value > values.withdrawableAmount) {
            handleSetActive({ withdrawValue: values.withdrawableAmount });
            setOverdraft(true);
            setOpenHelp(true);
            setTimeout(() => {
                setOverdraft(false)
            }, 1000);
        } else {
            handleSetActive({ withdrawValue: value })
        }
    }

    const validateAddress = (value, currency) => {
        currency = state.active.currency !== 'USDT' ? state.active.currency : state.active.blockchain;
        if (['TRX', 'BTC', 'ETH', 'DOGE'].indexOf(currency) == -1) return
        var valid = WAValidator.validate(value, currency);
        console.log(currency)
        if (valid)
            setValidAddress(true)
        else
            setValidAddress(false)

    }

    const handlewithdrawAddressChange = (e) => {
        const value = e.target.value;
        validateAddress(value, state.active.currency)
        handleSetActive({ withdrawAddress: value })
    }

    const callWithdraw = () => {
        if (!validAddress) return enqueueSnackbar('Invalid destination wallet address.', { variant: 'error', autoHideDuration: 3000 });
        if (state.active.withdrawValue <= 0) return enqueueSnackbar('Invalid withdraw amount.', { variant: 'error', autoHideDuration: 3000 });
        axios.post(process.env.REACT_APP_API_HOST + '/api/users/wallet/withdraw',
            {
                currency: state.active.currency,
                blockchain: state.active.blockchain,
                amount: state.active.withdrawValue,
                destinationWalletAddress: state.active.withdrawAddress
            }
            , {
                headers: { 'x-auth-token': getBearer() }
            })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 2000 });

                } else {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 4000 });

                }
            })
            .catch((err) => {
                enqueueSnackbar('Error', { variant: 'error', autoHideDuration: 4000 });
            })
    }

    // const calcWithdrawableAmount = (totalBalanceInUSD, balanceInCrypto, balanceInUSD, currency, feeInCrypto, feeInUSD, lockedBalanceInUSD, lockedBalanceInCrypto) => {
    //     let result = 0;
    //     // Player can not pay fee.
    //     if (balanceInCrypto < feeInCrypto) {
    //         console.log('Player can not pay fee.')
    //         return 0;
    //     }
    //     if (totalBalanceInUSD < (feeInUSD + lockedBalanceInUSD)) {
    //         // Player can not pay withdraw fee and locked balance.
    //         console.log('Player can not pay withdraw fee and locked balance.')
    //         return 0;
    //     }
    //     // Player has more than locked balance in other wallets. 
    //     if ((totalBalanceInUSD - balanceInUSD) > lockedBalanceInUSD) {
    //         console.log('Player has more than locked balance in other wallets. ')
    //         result = balanceInCrypto - feeInCrypto;
    //     } else {
    //         const x = lockedBalanceInUSD - (totalBalanceInUSD - balanceInUSD);
    //         result = balanceInCrypto - feeInCrypto - convertByRates(state, x, 'USD', currency)//lockedBalanceInCrypto;
    //     }
    //     if (result < 0) return 0

    //     result = parseFloat(numeral(result).format('0.00[000000]'))
    //     return result
    // }

    useEffect(() => {
        const currency = state.active.currency;
        const balance = state.user.wallet[currency].balance;
        const balanceInUSD = convertByRates(state, balance, currency, 'USD');
        const fee = currency == 'USDT' ? state.config.withdrawFees['USDT-' + state.active.blockchain] : state.config.withdrawFees[currency];
        const lockedBalance = (state.user.wallet.inUSD && state.user.wallet.inUSD.lockedBalance) ? state.user.wallet.inUSD.lockedBalance : 0;
        const lockedBalanceInCrypto = convertByRates(state, lockedBalance, 'USD', currency);
        const totalUserBalanceInUSD = state.user.wallet.inUSD.balance;
        const feeInUSD = convertByRates(state, fee, currency, 'USD')
        setValues({
            currency,
            balance,
            balanceInUSD,
            fee,
            feeInUSD,
            lockedBalance,
            totalUserBalanceInUSD,
            withdrawableAmount: state?.user?.wallet?.inUSD?.withdraw?.byCurrency?.[state.active.currency]?.availableWithdrawAmount || 0,
            //  calcWithdrawableAmount(
            //     totalUserBalanceInUSD,
            //     balance,
            //     balanceInUSD,
            //     currency,
            //     fee,
            //     feeInUSD,
            //     lockedBalance,
            //     lockedBalanceInCrypto
            // )
        })
        validateAddress(state.active.withdrawAddress, currency)
    }, [state.active.currency, state.active.blockchain, state.user.wallet.inUSD.balance])
    const withdrawData = state?.user?.wallet?.inUSD?.withdraw || {};

    return <>
        <SelectCurrency />
        {/* {state?.user?.wallet?.inUSD?.withdraw?.byCurrency?.[state.active.currency]?.availableWithdrawAmount} */}
        <MDBRow className='mx-0 mt-1'>
            <MDBCol size='12' className='text-center'>
                <Typography variant='body1'>{numeral(values.balance > 0.000001 ? values.balance : 0).format(currencyFormat(state.active.currency))} {values.currency}</Typography>
                <Typography variant='subtitle1' className='opacity-40'>~ ${numeral(values.balanceInUSD > 0.000001 ? values.balanceInUSD : 0).format(currencyFormat('USD'))}</Typography>
            </MDBCol>
            {
                state.active.currency !== 'USD' ?
                    <MDBCol size='12'>
                        <ThemeInput
                            // value={state.active.withdrawValue}
                            value={state.active.withdrawValue}
                            type='number'
                            onChange={handleWithdrawChange}
                            startClassName='px-2'
                            start={<img style={{ height: '1.4rem' }} src={currencyIcon(state.active.currency)} />}
                            end={<small className='cursor-pointer text-neon' onClick={() => handleSetActive({ withdrawValue: values.withdrawableAmount })}>Max</small>}
                        ></ThemeInput>
                        <Typography variant='caption' className={`mt-1 d-block text-${state.active.withdrawValue && (state.active.withdrawValue < (withdrawData?.byCurrency[state.active.currency]?.minWithdrawAmount || 0)) ? 'danger' : ' d-none'}`}>
                            Min withdraw amount is $100 ({withdrawData?.byCurrency[state.active.currency]?.minWithdrawAmount} {state.active.currency})
                            <Divider className='mt-1' />
                        </Typography>
                        <Box className='pt-1 d-flex justify-content-between'>
                            <Typography onClick={() => setOpenHelp(!openHelp)} variant='caption' className={` cursor-pointer text-gray d-inline`}>
                                <span>
                                    Max Withdrawable Amount:
                                </span>
                                <span className={`ps-2 ${overdraft ? 'text-danger' : 'text-white '}`}>{numeral(values.withdrawableAmount).format(currencyFormat(state.active.currency))} {state.active.currency}</span>
                            </Typography>
                            <Typography onClick={() => setOpenHelp(!openHelp)} variant='caption' className={`d-inline ms-1 cursor-pointer text-${overdraft ? 'danger' : 'neon'}`}><MDBIcon icon={openHelp ? 'chevron-up' : 'question-circle'}></MDBIcon></Typography>
                        </Box>

                        <WithrawInfo state={state} openHelp={openHelp} values={values} />
                    </MDBCol> :
                    <MDBCol size='12'>
                        <Alert sx={{ fontSize: '0.6rem', alignItems: 'center' }} variant="outlined" severity="error">USD withdraw not available.</Alert>
                    </MDBCol>

            }

            {
                state.active.currency == 'USDT' ?
                    <MDBCol size='12'>
                        <MDBRow className='justify-content-center pt-4'>
                            <MDBCol size='4' className={'text-center cursor-pointer text-hover-neon ' + (state.active.blockchain !== 'TRX' ? 'opacity-50' : '')}
                                onClick={() => { handleSetActive({ blockchain: 'TRX' }); }}>
                                <Badge
                                    className='mb-2'
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar alt='TRX' src={currencyIcon('TRX')} />
                                    }>
                                    <Avatar
                                        sx={{
                                            width: 24, height: 24,
                                        }}
                                        alt='USDT' src={currencyIcon('USDT')} />
                                </Badge>
                                <br />
                                <small className={state.active.blockchain == 'TRX' ? 'text-neon text-shadow-neon' : ''} style={{ fontSize: '0.7rem' }}>TRC-20</small>
                            </MDBCol>
                            <MDBCol size='4' className={'text-center cursor-pointer text-hover-neon ' + (state.active.blockchain !== 'ETH' ? 'opacity-50' : '')}
                                onClick={() => {
                                    handleSetActive({ blockchain: 'ETH' });
                                }}>
                                <Badge
                                    className='mb-2'
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar alt="ETH" src={currencyIcon('ETH')} />
                                    }>
                                    <Avatar
                                        sx={{ width: 24, height: 24 }}
                                        alt="USDT" src={currencyIcon('USDT')} />
                                </Badge>
                                <br />
                                <small className={state.active.blockchain == 'ETH' ? 'text-neon text-shadow-neon' : ''} style={{ fontSize: '0.7rem' }}>ERC-20</small>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol> : <></>
            }

            {
                state.active.currency !== 'USD' && <>
                    <MDBCol size='12 my-1'>
                        <ThemeInput
                            label={<span>Destination Wallet Address {!state.active.withdrawAddress ? '' : validAddress ? <MDBIcon icon='check-circle'></MDBIcon> : <MDBIcon icon='exclamation-circle'></MDBIcon>}</span>}
                            value={state.active.withdrawAddress}
                            type='text'
                            noFocusEffect={true}
                            inputClassName={`border box-shadow-${!state.active.withdrawAddress ? ' border-light' : validAddress ? 'success border-success' : 'danger border-danger'}`}
                            labelClass={`text-${!state.active.withdrawAddress ? 'white' : validAddress ? 'success' : 'danger'}`}
                            onChange={handlewithdrawAddressChange}
                        // startClassName='px-2'
                        // start={<small className='text-neon'>Wallet</small>}
                        ></ThemeInput>
                    </MDBCol>
                    <MDBCol size='12' className='text-center my-2'>
                        <ThemeButton onClick={() => callWithdraw()} variant='outlined' className='my-2 px-3 py-1'>Withdraw</ThemeButton>
                    </MDBCol>
                    <MDBCol size='12' className='text-center my-1'>
                        <Alert icon={false} variant="outlined" severity="warning" className='py-0 px-1'>
                            <Typography variant='caption'>
                                <small>
                                    Check your destination address before every operation. Sending cryptocurrencies to a wrong wallet address may result in permanent loss of your funds.
                                </small>
                            </Typography>
                        </Alert>
                    </MDBCol>
                </>
            }
        </MDBRow>
    </>
}


const WithrawInfo = (props) => {
    const { state, openHelp, values } = props;
    const { lockedBalance, wager } = state?.user?.wallet?.inUSD || {};

    const withdrawData = state?.user?.wallet?.inUSD?.withdraw || {};

    const { tier } = state.user.profile;
    return <Box>
        <Collapse in={openHelp} timeout="auto" unmountOnExit>
            <Box>
                <Alert sx={{
                    p: 0,
                    '& .MuiAlert-message': {
                        width: '100%',
                        p: 0,
                        fontSize: ['0.6rem', '0.7rem']
                    }
                }} icon={false} variant="outlined" severity="info">
                    <Grid container padding={1} className='py-2 m-0 border-bottom border-neon'>
                        <Grid xs={3} item>
                            Tier {tier}
                            <TiersInfo tier={tier} wager={wager} />
                        </Grid>
                        <Grid xs={6} item>
                            Withdrawn / Limit
                        </Grid>
                        <Grid xs={3} item>
                            Available
                        </Grid>
                    </Grid>
                    {
                        ['Daily', 'Weekly', 'Monthly'].map(item => {
                            const title = item;
                            const available = withdrawData?.[item.toLocaleLowerCase() + 'AvailableWithdrawAmountInUSD'] || 0;
                            const limit = withdrawData?.[item.toLocaleLowerCase() + 'WithdrawAmountLimit'] || 0;
                            return <Grid key={item} container padding={1} sx={{ borderTop: 'none', alignItems: 'center' }}>
                                <Grid xs={3} item>
                                    {title}
                                </Grid>
                                <Grid xs={6} item>
                                    {numeral(limit - available).format('$0,0.00')} / {numeral(limit).format('$0,0')}
                                </Grid>
                                <Grid xs={3} item>
                                    {numeral(available).format('$0,0.00')}
                                </Grid>
                            </Grid>
                        })
                    }
                    <Divider className='mb-2' />
                    <Typography variant='caption' className={`d-block px-2 pb-2 text-${lockedBalance > 0 ? 'danger' : 'gray'}`}>
                        Locked Balance: ${numeral(lockedBalance).format(currencyFormat('USD'))}
                    </Typography>
                </Alert>
            </Box>
        </Collapse >
    </Box >

}

const TiersInfo = (props) => {
    const { tier, wager } = props;
    const [open, setOpen] = useState(false)
    return <>
        <MDBIcon onClick={() => setOpen(true)} className='ps-1 cursor-pointer text-neon' icon='exclamation-circle' />
        <Modal
            open={open}
            disableScrollLock={true}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: ['350px', '500px'],
                minHeight: '100px',
                outline: 'none'
            }}>
                <AppBar position="static" color="transparent">
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Typography variant='body2'>
                            Withdraw Limits
                        </Typography>
                        <HighlightOffIcon className='cursor-pointer' color='danger' fontSize='small' onClick={() => setOpen(false)} />
                    </Toolbar>
                </AppBar>
                <WithdrawLimitTable tier={tier} />
                <AppBar position="static" color="transparent">
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Typography variant='body2'>
                            Tier Levels
                        </Typography>
                        <Typography variant='caption' className='text-neon'>
                            <span className='me-1'>
                                Your Wager:
                            </span>
                            {numeral(wager).format('$0,0')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <TierLevels tier={tier} />
            </Box>
        </Modal>
    </>

}

const withdrawLimitTableData = [
    [2, 5, 10],
    [10, 25, 50],
    [20, 50, 100],
    [50, 100, 250],
    [100, 250, 500],
    [200, 500, 1000],
]

const WithdrawLimitTable = (props) => {
    const { tier } = props;
    return <Grid container border={1} sx={{
        alignItems: 'center', borderColor: '#26ddff55', fontSize: '0.7rem', '& .MuiGrid-root': {
            py: 1,
            pl: '5px',
            borderLeft: '1px solid #fff2',
            borderBottom: '1px solid #fff2',
            typography: ['subtitle2', 'caption']
        }
    }}>
        {['-', 'Daily', 'Weekly', 'Monthly'].map((text, i) =>
            <Grid key={text} xs={3} item>{text}</Grid>
        )}
        {withdrawLimitTableData.map((row, i) => {
            return <Fragment key={i}>
                <Grid className={tier == i ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} item>{i ? ['Tier ' + i] : 'Standard'}</Grid>
                <Grid className={tier == i ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} item>{numeral(1000 * row[0]).format('$0,0')}</Grid>
                <Grid className={tier == i ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} item>{numeral(1000 * row[1]).format('$0,0')}</Grid>
                <Grid className={tier == i ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} item>{numeral(1000 * row[2]).format('$0,0')}</Grid>
            </Fragment>
        }
        )}
    </Grid>
}

const TierLevels = (props) => {
    const { tier } = props;
    return <Grid container columns={18} border={1} sx={{
        alignItems: 'center', borderColor: '#26ddff55', fontSize: '0.7rem', '& .MuiGrid-root': {
            py: 1,
            pl: '5px',
            borderLeft: '1px solid #fff2',
            borderBottom: '1px solid #fff2',
            typography: ['subtitle2', 'caption']
        }
    }}>
        <Grid xs={3} item>Tier</Grid>
        {[0, 1, 2, 3, 4].map((i) =>
            <Grid className={tier == i + 1 ? 'text-neon fw-bold bg-dark border-0' : ''} key={i} xs={3} item>Tier {i + 1}</Grid>
        )}
        <Grid item xs={3} >Wager</Grid>
        <Grid item className={tier == 1 ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} >$50K</Grid>
        <Grid item className={tier == 2 ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} >$500K</Grid>
        <Grid item className={tier == 3 ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} >$2.5M</Grid>
        <Grid item className={tier == 4 ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} >$10M</Grid>
        <Grid item className={tier == 5 ? 'text-neon fw-bold bg-dark border-0' : ''} xs={3} >$25M</Grid>
    </Grid>
}

export default Withdraw
