import React, { useContext } from 'react';
import { MDBCard, MDBCardHeader, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Context } from '../../store';
import { Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import ThemeButton from '../components/ThemeButton';

const Referral = () => {
    const [state, dispatch] = useContext(Context);

    const { affiliate } = state.user.profile;
    return (<>

        <MDBRow className='mx-0 py-3'>
            {
                Object.keys(affiliate).map((affId, i) => <AffIdCard key={i} aff={affiliate[affId]} affId={affId}></AffIdCard>)
            }

            {/* <MDBCol size='12' className='bg-black py-2 border-bottom border-neon'>
                <Typography variant='body2'>
                     URLs (Add, Remove!, Edit!, reports) 
                </Typography>
            </MDBCol> */}
        </MDBRow>
    </>
    )
}

const AffIdCard = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const { aff, affId } = props;
    return <MDBCol size='12' className='bg-black py-2 '>
        <MDBCard className='box-shadow-neon bg-black overflow-hidden'>
            <Typography variant='body2' component='div'>
                <div className='box-shadow-neon m-0 p-2 font-weight-normal text-neon text-shadow-neon d-flex justify-content-between'>
                    <span>
                        #{affId}
                    </span>
                    <div>{aff.title}</div>
                </div>
                <MDBRow className='m-0 py-2'>
                    <MDBCol>
                        <div>Total Users: {aff.count}</div>
                        <div>Active Users: {aff.activeUsersCount}</div>
                    </MDBCol>
                </MDBRow>
                <div className='box-shadow-neon m-0 p-2 font-weight-normal text-warning text-shadow-warning d-flex justify-content-end'>
                    <CopyToClipboard text={`https://www.imoon.com/?refId=${affId}`}
                        className='cursor-pointer px-2' style={{ padding: '0.1rem 0 0 0' }}
                        onCopy={() => { enqueueSnackbar('Copied', { variant: 'success', autoHideDuration: 1500 }) }}>
                        <ThemeButton size='small' variant='outlined' color='warning'>
                            <MDBIcon className='pe-1' icon='copy'></MDBIcon> Copy Link
                        </ThemeButton>
                    </CopyToClipboard>
                </div>
            </Typography>
        </MDBCard>
    </MDBCol>
}


export default Referral
