import { MDBCol, MDBIcon, MDBRow, MDBCard, MDBCardBody } from 'mdb-react-ui-kit'
import React from 'react'

const Termsofuse = () => {

    return (
        <MDBCard className='mx-2' style={{ backgroundColor: '#24232999', minHeight: 'calc(100vh - 60px)' }}>
            <MDBCardBody className='p-2 text-gray'>
                <MDBRow className='text-gray'>
                    <MDBCol>
                        <div>
                            <h6 className='text-white mt-5'>GENERAL TERMS</h6>
                            <p>
                                Please read these Terms of Use carefully. These Terms of Use ( the "Agreement" ) should be read by you (the "user", "player’ or "you") in its entirety prior to your use of iMoon (the website and the game together referred to as "Service"). Please note that the Agreement constitutes a legally binding agreement between you and iMoon.com (http://iMoon.com/) (referred to herein as "iMoon", "casino", "us", "iMoon or "we") which owns and operates the website and the game found at iMoon.com (http://iMoon.com/) ("iMoon website", "iMoon website" or "website"). By playing iMoon game with registering an account you agree to be bound by these Terms of Use and all terms incorporated by reference.
                            </p>

                            <h6 className='text-white mt-5'>GRANT OF LICENSE</h6>
                            <p>
                                Subject to Terms of Use contained herein, iMoon grants the User a non-exclusive, personal, non-transferable right to play the game on his/her personal computer or other device (including Mobile) that has Internet in order to access the game available and described on BitKong website.
                            </p>

                            <h6 className='text-white mt-5'>THE SERVICE IS FORBIDDEN FOR USE BY:</h6>
                            <ol>
                                <li>individuals under 18 years of age.</li>
                                <li>individuals under the legal age of majority in their jurisdiction.</li>
                                <li>individuals accessing the Service from jurisdictions from which it is illegal to do so.</li>
                            </ol>
                            <p>
                                Online gambling may be illegal in the jurisdiction in which you are located. iMoon is not able to verify the legality of the Service in each jurisdiction and it is the User's responsibility to ensure that their use of the Service is lawful.
                            </p>

                            <h6 className='text-white mt-5'>OWNERSHIP OF INTELLECTUAL PROPERTY RIGHTS</h6>
                            <p>
                                The term "iMoon"Softwares, its domain names and any other intellectual property rights (including but not limited to our title, trademarks, service marks, designs, logos, URLs, and trade names that are displayed on our Service, which we refer to in these Terms) used by iMoon as part of the Service (the "Trade Marks") are solely owned by iMoon. In addition, all content on the website, including, but not limited to, the images, pictures, graphics, photographs, animations, videos, music, audio and text (the "Website Content") belongs to iMoon and is protected by copyright and/or other intellectual property or other rights. You hereby acknowledge that by using the Service, you obtain no rights in the Website Content and/or the Trade Marks, or any part thereof. Under no circumstances may you use the Trade Marks and the Site Content without iMoon’s prior written consent. Additionally, you agree not to do anything that will harm or potentially harm the rights, including the intellectual property rights of iMoon.
                            </p>
                            <p>
                                iMoon Softwares N.V. Is the sole holder of all rights in and to the Service and code, structure and organization, including copyright, trade secrets, intellectual property and other rights. You may not, within the limits prescribed by applicable laws: Copy, distribute, publish, reverse engineer, decompile, disassemble, modify, or translate the website or Use the Service in a manner prohibited by applicable laws or regulations (each of the above is an "Unauthorized Use"). iMoon reserves any and all rights implied or otherwise, which are not expressly granted to the User hereunder and retain all rights, title and interest in and to the Service. You agree that you will be solely liable for any damage, costs or expenses arising out of or in connection with the commission by you of any Unauthorized Use. You shall notify iMoon immediately upon becoming aware of the commission by any person of any Unauthorized Use and shall provide iMoon with reasonable assistance with any investigations it conducts in light of the information provided by you in this respect.
                            </p>

                            <h6 className='text-white mt-5'>USER'S ACCOUNT</h6>
                            <p>
                                Every user has an account within the Website. User must Sign In at website to play. E-mail is used to get a security code for account verification.
                            </p>
                            <p>
                                If you wish to sign up with one click option, you may use your mobile phone to verify your account.
                            </p>
                            <p>
                                If you wish to start making bets, you need to make deposit into your account from a source of which you are the only legitimate owner.
                            </p>

                            <h6 className='text-white mt-5'>JACKPOT</h6>

                            <p>
                                Jackpot amount is 2% out of our RTP which goes to our jackpot and every week we devide the prize to our top 10 players.
                            </p>
                            <p>
                                To receive our Jackpot prize you have to be on top 10 of our weekly top players. Prize are dividing as majority to our first 3 players and the rest to other 7 players. as first place player will receive %30 of the whole pot, second place receives 20% of the pot and third player is receiving 10% of the pot every week.
                            </p>
                            <p>
                                Remaining of 40% will divide equally to other 7 players.
                            </p>

                            <h6 className='text-white mt-5'>BET BEHIND PLAYERS</h6>
                            <p>
                                Bet behind players is a service that allows other users to bet behind top players or any of their favorite players, please note that if any other player bet behind you it means you will have revenue share with iMoon on their bets %2 of players bet revenue share will be added on your bet behind wallet and you are able to withdraw it every month.
                            </p>
                            <p>
                                Please note that in case you become minus on your bet behind wallet, iMoon will reset your wallet to 0 at the end of the month and you won’t receive any damage.
                            </p>

                            <h6 className='text-white mt-5'>REFERRALS</h6>

                            <p>
                                iMoon rewards users for sharing referrer links and attracting users to website. iMoon has one of the highest referral commission rates  which you can find below
                            </p>

                            <ul>
                                <li>0-10 active players : 2%</li>
                                <li>10-25 active players : 3%</li>
                                <li>25-50 active players : 4%</li>
                                <li>50-100 active players : 5%</li>
                                <li>100-250 active players : 6%</li>
                                <li>250-500 active players : 7%</li>
                                <li>500-1,000 active players : 8%</li>
                                <li>1,000-2,500 active players : 9%</li>
                                <li>2,500-5,000 active players : 10%</li>
                                <li>5,000-10,000 active players : 15%</li>
                                <li>10,000-25,000 active players : 20%</li>
                                <li>25,000-50,000 active players : 25%</li>
                                <li>50,000-100,000 active players : 30%</li>
                                <li>100,000-250,000 active players : 35%</li>
                                <li>250,000 &gt; active players : 40%</li>
                            </ul>

                            <p>
                                Please note that every month your balance will move to your actual wallet and you are able to withdraw or play with your balance. Please note that in case you become minus with your referrers iMoon will reset your account by end of each month and you won’t receive any damage from your referrals but iMoon will move the damage users under it’s own players as high risk players so you won’t receive any more profit/lost from that user.
                            </p>
                            <p>
                                User can see number of referrals and total commission received from them at user’s referrer dashboard. Emails of referred users can not be shown due to privacy reasons but all other informations such as user ID, User Deposits, Profits/Lost will be shown in overall reports.
                            </p>

                            <h6 className='text-white mt-5'>BET RESULTS AFTER BROWSER ISSUES</h6>

                            <p>
                                iMoon shall not have any responsibilities for any delays, game being stuck or frozen and losses that may occur because of that in the game. This can only happen because of your computer/device specific reasons, CPU or internet connection issues. When you press button to play, your bet is instantly being processed on server. And the result is generated and fixed on server. If after the game is stuck you refresh website and see you lost the bet, it means that the bet was a losing bet with no regard to the freezing. But it is also true if you win, with same probability.
                            </p>

                            <h6 className='text-white mt-5'>CANCELATION POLICY</h6>

                            <p>
                                We reserve the right to cancel or void any bets in our absolute discretion, where in our reasonable opinion, there is an error in the bet in question or there is technological failure. Unless a specific reference to the contrary is made in the description of the bet, a bet will be deemed not to have occurred in an event of technological failure. In such case, the bet will be deemed to be void and all related funds deposited in respect of that bet may be returned to member’s account. However, bets that have already been resolved at the time of cancellation will stand.
                                And player is able to cancel the bets before the game started.
                            </p>

                            <h6 className='text-white mt-5'>PAYOUT POLICY</h6>

                            <p>
                                The website allows playing for Bitcoin, Etherium, Litecoin, Dogecoin, Dash, Bitcoin Cash, Ada, Ripple, Algo, USDT TRC20 ERC20,
                            </p>
                            <p>
                                A minimum withdrawal amount is indicated at Withdraw page in Cashier of the website. Maximum bet is a maximum sum allowed for single bet (May be different at various levels of game and differ in different currencies) and is indicated in the ticket. iMoon reserves the right
                            </p>

                            <p>
                                to increase or decrease Maximum Bet at any time with no previous warning. In case of large withdrawals manual processing may be implemented. Manual withdrawals are processed on business days from 09:00 to 18:00 GMT.
                            </p>
                            <p>
                                The player understands that they deposit and withdraw on the same currency and there is no exchanging due to any other cryptocurrency.
                            </p>

                            <h6 className='text-white mt-5'>ANTI FRAUD</h6>

                            <p>
                                We have a strict anti-fraud policy. We reserve the right to terminate the user account and suspend all payouts to the player If the player is suspected of fraudulent actions including, but not limited to: - participating in any type of collusion with other users - development of strategies aimed at gaining of unfair winnings - fraudulent actions against other online casinos - creating more than one account in order to get advantage from casino promotions - other types of cheating or any other actions which may damage the Casino.
                            </p>
                            <p>
                                This decision is at our sole discretion and the player will not be notified or informed about the reasons of such actions.
                            </p>
                            <p>
                                We have zero tolerance to advantage play. Any player who will try to gain advantage of casino bonus offers or other promotions agrees that casino reserves the right to void bonuses and any winnings from such bonuses.
                            </p>
                            <p>
                                The Casino reserves the right to retain payments, if suspicion or evidence exists of manipulation of the casino system. The Casino reserves the right to terminate and/or change any events being offered on Website. Should you become aware of any possible errors or incompleteness in the software, you agree to refrain from taking advantage of them. Moreover, you agree to report to Casino any error or incompleteness immediately. Should you fail to fulfill such obligations, Casino has a right to full compensation for all costs related to the error or incompleteness, including any costs incurred in association with the respective error/incompleteness and the failed notification.
                            </p>

                            <h6 className='text-white mt-5'>iMoon RIGHTS</h6>

                            <p>
                                iMoon reserves the right to assign this Terms of Use, in whole or in part, at any time without notice. The User may not assign any of his/her rights or obligations under this Terms of Use.
                            </p>
                            <p>
                                iMoon reserves the right to cancel or reject any bet. iMoon MAY SUSPEND, TERMINATE, MODIFY OR DELETE ACCOUNTS AT ANY TIME FOR ANY SUSPICIOUS REASON, WITH OR WITHOUT NOTICE TO YOU. Accounts terminated by iMoon for any type of abuse, including without limitation a violation of the Terms of Service, game-specific rules will not be reactivated for any reason. For purposes of explanation and not limitation, most account suspensions, terminations and/or deletions are the result of violations of these Terms of Use or game-specific rules.
                            </p>
                            <p>
                                iMoon does NOT charges a House Edge from the winning bet.
                            </p>

                            <h6 className='text-white mt-5'>WARRANTIES</h6>

                            <p>
                                iMoon disclaims any and all warranties, expressed or implied, in connection with the service which is provided to you "as is". We provide you with no warranty or representation whatsoever regarding its quality, completeness or accuracy. In no case shall iMoon be liable for any special, incidental, indirect, punitive or consequential damages arising from your use of Service. Regardless of our efforts, we make no warranty that the service will be uninterrupted, timely or error-free, or that defects will be corrected. You agree to the game rules described on the iMoon.com (http://iMoon.com/) website. iMoon retains authority over the issuing, maintenance and closing of the Service. You acknowledge and agree that your sole and exclusive remedy for any dispute with iMoon is to stop using the service and to cancel all accounts registered.
                            </p>

                            <h6 className='text-white mt-5'>YOUR REPRESENTATIONS AND WARRANTIES</h6>

                            <p>
                                Prior to your use of the Service and on an ongoing basis you represent, warrant, covenant and agree that: there is a risk of losing money when using the Service and that iMoon has no responsibility to you for any such loss. Your use of the Service is at your sole option, discretion and risk. You are solely responsible for any applicable taxes which may be payable on winnings awarded to you through your using the Service. The telecommunications networks and Internet
                                access services required for you to access and use the Service are entirely beyond the control of iMoon and we shall have no liability whatsoever for any outages, slowness, freezing, capacity constraints or other deficiencies affecting the same.
                            </p>
                            You are not currently self-excluded from any gambling/online gambling site or gambling premises and that you will inform us immediately if you enter into a self-exclusion agreement with any gambling provider.

                            <h6 className='text-white mt-5'>PERSONAL USE</h6>

                            <p>
                                The Service is intended solely for the User's personal use. The User is only allowed to bet for his/her personal entertainment and may not create multiple accounts for the purpose of collusion and/or abuse of Website. All bets should be processed using iMoon softwares. It is prohibited to use any other or additional software to use iMoon service. It is prohibited to exploit any vulnerability of BTC or iMoon softwares for personal gain. It is prohibited to use double-spend transactions for BTC and other crypto transactions. It is prohibited to deposit and withdraw without making actual bets. It is prohibited to spam, write in capital letters, use offensive words and post advertising in username to administration and/or customer service team of iMoon. It is prohibited to post false and/or offensive information about iMoon on external resources (forums, blogs, social networks and other). It is prohibited to make deposits on your account for the purpose of withdrawing amounts, which were not allowed to be withdrawn due to minimum withdrawal limit restrictions. iMoon prohibits player collusion and do not allow any kind of robots and programmed devices to participate in game play. iMoon reserves the right to void any game play which results from the use of robots and/or programmed devices. iMoon strictly prohibits self-referral activities like creating second and other accounts to fake a real referral activity or any other activities, that abuse referrals. iMoon reserves the right to block any account without any notice due to any suspicious or malicious activity or if it was inactive for the last 1 (one) year.
                            </p>
                            <p>
                                Should the user become aware of possible errors or incompleteness in the software, he/she agrees to refrain from taking advantage of them. Moreover, the user agrees to report any error or incompleteness immediately to iMoon. Should the user fail to fulfil the obligations stated in this clause, iMoon has a right to full compensation for all costs related to the error or incompleteness, including any costs incurred in association with the respective error/incompleteness and the failed notification by the user.
                            </p>

                            <h6 className='text-white mt-5'>SHARED WALLET</h6>

                            <p>
                                The User should not withdraw cryptocurrencies to a shared wallet not solely controlled by the user or any other address not solely controlled by the user as any amounts sent to such address may not be properly credited to the user.
                            </p>
                            <p>
                                It is prohibited to share your account access details to third-parties.
                            </p>

                            <h6 className='text-white mt-5'>JURISDICTIONS</h6>

                            <p>
                                Persons located in or residents of the United States and the United States Territories, or residents of the states(countries) where online gambling is prohibited (the "Prohibited Jurisdictions") are not permitted make use of the Service. For the avoidance of doubt, the foregoing restrictions on engaging in betting from Prohibited Jurisdictions applies equally to residents and citizens of other nations while located in a Prohibited Jurisdiction. Any attempt to circumvent the restrictions on play by any persons located in a Prohibited Jurisdiction or Restricted Jurisdiction is a breach of this Agreement. An attempt at circumvention includes, but is not limited to, manipulating the information used by iMoon to identify your location and providing iMoon with false or misleading information regarding your location or place of residence.
                            </p>
                            <p>
                                It is your responsibility to determine the law that applies in the location in which you are present. You should ensure that you will be acting legally in your jurisdiction in using the Service, and you represent, warrant and agree that you will do so.
                            </p>

                            <h6 className='text-white mt-5'>TERMS OF USE TERMINATION</h6>

                            <p>
                                With out prejudice to any other rights, if the User breaches in whole or in part any provision set out herein, iMoon reserves the right to take such action as it sees fit, including terminating this Agreement or any other agreement in place with the User and/or taking legal action against such User.
                            </p>
                            <p>
                                You agree to fully indemnify, defend and hold harmless iMoon and its shareholders, directors, agents and employees from and against all claims, demands, liabilities, damages, losses, costs and expenses, including legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of:
                            </p>

                            <ul>
                                <li>Your breach of this Agreement in whole or in part</li>
                                <li>Violation by you of any law or any third party rights, and</li>
                                <li>Use by you of the Service</li>
                            </ul>

                            <h6 className='text-white mt-5'>SEVERABILITY</h6>

                            <p>
                                If any provisions of this Agreement is or becomes illegal, invalid or unenforceable, that shall not affect the validity or enforceability of any other provision of the Agreement, which shall remain in full force and effect.
                            </p>
                            <p>
                                If any provisions of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect the validity or enforceability of that or any other provision hereof in that jurisdiction or any other jurisdiction, which shall remain in full force and effect.
                            </p>

                            <h6 className='text-white mt-5'>DISPUTES</h6>
                            <p>
                                If User wants to make a complaint, he should contact our customer service team at info<MDBIcon icon='at'></MDBIcon>iMoon.com
                            </p>

                            <h6 className='text-white mt-5'>AMENDMENT</h6>
                            <p>
                                iMoon reserves the right to update or modify this Agreement or any part thereof at any time or otherwise change the Service without notice and you will be bound by such amended Agreement upon posting. Therefore, we encourage you check the terms and conditions contained in the version of the Agreement in force at such time. Your continued use of the Service shall be deemed to attest to your agreement to any amendments to the Agreement.
                            </p>

                            <h6 className='text-white mt-5'>MISCELLANEOUS</h6>

                            <p>
                                Nothing in this Agreement shall create or confer any rights or other benefits in favours of any third parties not a party to this Agreement other than with an affiliate of <strong>"iMoon"</strong>. Nothing in this Agreement shall create or be deemed to create a partnership, agency, trust arrangement, fiduciary relationship or joint venture between you and iMoon. This Agreement constitutes the entire understanding and agreement between you and iMoon regarding the Service and supersedes any prior agreement, understanding or arrangement between you and iMoon.
                            </p>
                            <p>
                                In case you have found a vulnerability in iMoon Website, please report it to the iMoon administration by email: info<MDBIcon icon='at'></MDBIcon>iMoon.com
                            </p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
}

export default Termsofuse
