import React from 'react';
import theme from './DefaultTheme';
import { SnackbarProvider } from 'notistack';
import { Slide, ThemeProvider } from '@mui/material';
import Navbar from './navbar/Navbar';

const Layout = (props) => {

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={10500}
        TransitionComponent={Slide}
        maxSnack={2}>
        <Navbar>
          {props.children}
        </Navbar>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Layout
