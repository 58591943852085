import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    // en: {
    //     translation: {
    //         "Place a Bet": "Welcome to React and react-i18next"
    //     }
    // },
    fa: {
        translation: {
            "Place a Bet": "بت",
            'Amount': 'مبلغ',
            'Pay': 'پرداخت',
            'Edit': 'ویرایش',
            'Return': 'بازگشت',
            'Payment Menu': 'منوی پرداخت',
            'Payment Failed': 'پرداخت ناموفق بود.',
            'Successful Payment': 'پرداخت موفق بود.',
            'Loading, Please Wait ...': 'صبر کنید ...',
            // ****************************************
            'Crash': 'کرش',
            'Poker': 'پوکر',
            'Profile': 'پروفایل',
            'Deposit': 'افزایش موجودی',
            'Withdraw': 'برداشت',
            'Transactions': 'تراکنش ها',
            'Invite Friends': 'دعوت از دوستان',
            'Terms of Use': 'قوانین استفاده',
            'Game Rules': 'قوانین بازی',
            'Fairness': 'بازی منصفانه',
            'Privacy Policy': 'حریم شخصی',
            'Responsible Gambling': 'مسئولیت پذیری',
            'Seed': 'مشارکت',
            'Support': 'پشتیبانی',
            'Settings': 'تنظیمات',
            // ***********************************
            'Sign Up': 'ثبت نام',
            'Sign In': 'ورود',
            'Login': 'ورود',
            'Logout': 'خروج',
            'Password': 'کلمه عبور',
            'Change Password': 'کرش',
            'Sign Up error, try again later or contact support.': 'خطا در ثبت نام، لطفا بعدا تلاش کنید یا با پشتیبانی تماس بگیرید.',
            // **********************************
            'Classic': 'کلاسیک',
            'Over/Under': 'بالا/پایین',
            'Range': 'رنج',
            'Bet Behind': 'پشت دست',
            'From': 'از',
            'To': 'تا',
            'Bet Range': 'ثبت شرط',
            'Over': 'بیشتر از',
            'Under': 'کمتر از',
            'Lucky Number': 'عدد شانس',
            // **********************************
            'Playing': 'بازیکنان',
            'History': 'سابقه',
            'Graph': 'نمودار',
            'Top Player': 'برترین ها',
            'No Bet Yet': 'شرطی وجود ندارد',
            'Rounds History': 'سابقه بازی',
            'Your Bets': 'شرط های شما',
            'Details': 'جزئیات',
            'Min': 'حداقل',
            'Max': 'حداکثر',
            'Avg': 'میانگین',
            'Year': 'سال',
            'Month': 'ماه',
            'Week': 'هفته',
            'Day': 'امروز',
            'Active': 'فعال',
            // **********************************
            'Odds': 'ضریب',
            'User': 'بازیکن',
            'Bet': 'شرط',
            // **********************************
            'Single Bet': 'تک شرطی',
            'Multi Bet': 'چند شرطی',
            'Auto Cashout': 'برداشت خودکار',
            'Bet Amount': 'مبلغ شرط',
            'Playing ...': 'در حال بازی',
            'Waiting to Start (cancel)': 'منتظر شروع (لغو)',
            // **********************************
            'My Bets': 'شرط های من',
            'My Followers': 'پشت دست ها',
            'Bet Behind a Player': 'بازی پشت دست دیگران',
            // **********************************
            'Send': 'ارسال',
            'write your message ...': 'پیام خود را بنویسید',

        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
export default i18n;