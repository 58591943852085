import { Avatar, Box, Collapse, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import axios from 'axios';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react'
import FileUpload from '../../components/FileUpload';
import { Context } from '../../store';
import { getBearer } from '../../helpers/publicHelper';
import ChangePassword from '../auth/ChangePassword';
import ThemeInput from '../components/ThemeInput';
import ThemeButton from '../components/ThemeButton';
import moment from 'moment';
import PlayerTier from '../../user/PlayerTier';
import numeral from 'numeral';

const Profile = () => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState({
        // email: (state.user && state.user.kyc.emails.default) ? state.user.kyc.emails.default.address : '',
        // emailVerified: state.user && state.user.kyc.emails.default && state.user.kyc.emails.default.verified,
        // mobile: (state.user && state.user.kyc.mobiles.default) ? `+(${state.user.kyc.mobiles.default.prefix}) - ${state.user.kyc.mobiles.default.number}` : '',
        // mobileVerified: state.user && state.user.kyc.mobiles.default && state.user.kyc.mobiles.default.verified,
        tier: (state.user && state.user.profile) ? state.user.profile.tier : '',
        username: (state.user && state.user.profile) ? state.user.profile.username : '',
        nickName: (state.user && state.user.profile) ? state.user.profile.nickName : '',
        firstName: (state.user && state.user.profile) ? state.user.profile.firstName : '',
        lastName: (state.user && state.user.profile) ? state.user.profile.lastName : '',
        createdAt: (state.user && state.user.profile) ? state.user.profile.createdAt : '',
        showChangePassword: false,
        waiting: false
    })

    const submit = () => {
        const { username, nickName, firstName, lastName, email, mobile } = values;
        if (!username) return enqueueSnackbar('"Username" is required.', {
            variant: 'error',
            autoHideDuration: 3000
        });
        if (!username) return enqueueSnackbar('"Username" is required.', {
            variant: 'error',
            autoHideDuration: 3000
        });
        const firstLetter = username.charAt(0);
        if (!(firstLetter.toUpperCase() != firstLetter.toLowerCase())) return enqueueSnackbar('"Username" must starts with a letter.', {
            variant: 'error',
            autoHideDuration: 3000
        });

        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        setValues({ ...values, waiting: true })
                        axios.post(process.env.REACT_APP_API_HOST + '/api/users/setProfile', {
                            username, nickName, firstName, lastName
                        }, {
                            headers: {
                                captcha: captcha,
                                'x-auth-token': getBearer(),
                            }
                        })
                            .then(res => {
                                res = res.data;
                                if (res.success) {
                                    enqueueSnackbar(res.message, {
                                        variant: 'success',
                                        autoHideDuration: 3000
                                    });
                                } else {
                                    return enqueueSnackbar(res.message, {
                                        variant: 'warning',
                                        autoHideDuration: 3000
                                    });
                                }
                            })
                            .catch((err) => {
                                return enqueueSnackbar(err.message, {
                                    variant: 'error',
                                    autoHideDuration: 3000
                                });
                            })
                            .then(() => {
                                setValues({ ...values, waiting: false })
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }
    }

    const uploadSuccess = (file) => {
        let params = {};
        enqueueSnackbar(`${file ? 'Uploading' : 'Removing'} avatar ...`, {
            variant: 'warning', autoHideDuration: 1500
        })
        if (file) params.image = file[0]._id;
        axios.post(state.active.host + '/api/users/setAvatar/', params, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                enqueueSnackbar(res.message, {
                    variant: 'success', autoHideDuration: 3000
                })
            })
            .catch((err) => {
                enqueueSnackbar('Error!', {
                    variant: 'error', autoHideDuration: 3000
                })
            })
    }

    const uploadError = (message) => {
        enqueueSnackbar(message, {
            variant: 'error', autoHideDuration: 3000
        })
    }


    return (
        <MDBRow className='m-0 pt-2'>
            <MDBCol size='12' >
                <List dense className='p-0'>
                    <ListItem className='p-0 py-2 position-relative'>
                        {state.user.profile.avatar ? <MDBIcon onClick={() => uploadSuccess()} style={{ top: 7, left: -5, zIndex: 9999 }} className=' cursor-pointer text-danger position-absolute m-auto bg-white rounded-circle' icon='times-circle'></MDBIcon> : <></>}
                        <Avatar className={`bg-transparent box-shadow-neon m-auto`}>
                            {state.user.profile.avatar ?
                                <span className='position-relative text-center'>
                                    <img src={state.user.profile.avatar} className='img-fluid m-auto' style={{ width: '100%', height: 'auto' }} />
                                </span>
                                :
                                <>
                                    <MDBIcon icon='upload' className='text-neon' style={{ fontSize: '1rem' }}></MDBIcon>
                                    <FileUpload
                                        style={{ top: 0, left: 0 }}
                                        className='opacity-0 py-5 cursor-pointer position-absolute'
                                        name={''}
                                        onUploaded={(file) => uploadSuccess(file)}
                                        onError={uploadError}
                                    ></FileUpload>
                                </>
                            }
                        </Avatar>
                        <ListItemText
                            className='ps-3'
                            primary={values.username}
                            secondary={
                                <small style={{ lineHeight: '1rem' }} className='text-capitalize text-gray'>Register: {moment(values.createdAt).format('YYYY/MM/DD  HH:mm')}</small>
                            } />
                    </ListItem>
                </List>
            </MDBCol>
            <MDBCol size='12' className='pb-2'>
                <form autoComplete="off" id='ChangePassword' noValidate='noValidate' className='text-start' onSubmit={e => { e.preventDefault(); submit() }}>
                    {/* <ThemeInput className='mb-3' onChange={e => setValues({ ...values, email: e.target.value })} inputClassName={values.emailVerified && 'opacity-40'} type='text' disabled={values.emailVerified} label='Email' value={values.email}></ThemeInput>
                    <ThemeInput className='mb-3' onChange={e => setValues({ ...values, mobile: e.target.value })} inputClassName={values.mobileVerified && 'opacity-40'} type='text' disabled={values.mobileVerified} label='Phone' value={values.mobile}></ThemeInput> */}
                    <Typography variant='caption'>
                        <span className='me-1'>
                            Your Tier
                        </span>
                        <PlayerTier tier={values.tier} />
                    </Typography>
                    <Divider className='my-2' />
                    <Typography variant='caption'>
                        <Box className='d-flex justify-content-between opacity-50'>
                            <span>
                                Required wager to unlock tier {values.tier + 1}:
                            </span>
                            <span>
                                {numeral(state?.user?.wallet?.inUSD?.wagerToNextTier).format('$0,0.00')}
                            </span>
                        </Box>
                    </Typography>
                    <ThemeInput className='mb-3' type='text' onChange={e => setValues({ ...values, username: e.target.value })} label='Username' value={values.username}></ThemeInput>
                    <ThemeInput className='mb-3' type='text' onChange={e => setValues({ ...values, nickName: e.target.value })} label='Nickname' value={values.nickName}></ThemeInput>
                    <ThemeInput className='mb-3' type='text' onChange={e => setValues({ ...values, firstName: e.target.value })} label='FirstName' value={values.firstName}></ThemeInput>
                    <ThemeInput className='mb-3' type='text' onChange={e => setValues({ ...values, lastName: e.target.value })} label='LastName' value={values.lastName}></ThemeInput>
                    <MDBCol size='12' className='text-center'>
                        <ThemeButton waiting={values.waiting} type='submit' className='px-3' variant='outlined'>Save</ThemeButton>
                    </MDBCol>
                </form>
            </MDBCol>
            <MDBCol size='12' className='py-3 text-center'>
                <Typography variant='body2' className='text-warning cursor-pointer' onClick={() => setValues({ ...values, showChangePassword: !values.showChangePassword })}>
                    <MDBIcon icon='lock' className='pe-1'></MDBIcon>
                    Change Password
                </Typography>
            </MDBCol>
            <Collapse in={values.showChangePassword} timeout="auto" unmountOnExit>
                <ChangePassword></ChangePassword>
            </Collapse>

        </MDBRow >
    )
}


export default Profile
