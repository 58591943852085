import { makeStyles } from '@mui/styles';
import React from 'react'
import ReactCodeInput from 'react-code-input';

const useStyles = makeStyles((theme) => ({
    code: {
        '& input': {
            fontSize: '1.0rem',
            textAlign: 'center',
            backgroundColor: 'transparent',
            color: '#fff',
            border: '0',
            padding: '3px 1px',
            maxWidth: '1.2rem',
            margin: '0px 5px 0 0 !important',
            border: '1px solid #26ddff',
            borderRadius: 3,
            outline: 'none',
            boxShadow: '0px 0px 3px #26ddff'
        },
        '& input::-moz-selection': {
            color: '#26ddff',
            background: 'transparent'
        },
        '& input::selection': {
            color: '#26ddff',
            background: 'transparent'
        },
        '& input:focus': {
            boxShadow: '0px 0px 8px #26ddff',
            border: '1px solid #fff',
        }
    }
}));



const PIN = (props) => {
    const classes = useStyles();

    return (
        <div>
            <ReactCodeInput {...props} className={classes.code + ' noArrow ' + props.classes} onChange={(v) => props.handlechange({ id: props.name, value: v })} />
        </div>
    )
}

export default PIN
