import { createContext } from 'react';
// client-side
const io = require("socket.io-client");

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const getBearer = () => {
    let token = getCookie("bearer");
    token = token ? token : '';
    return token
}

export const chatSocket = io.connect(process.env.REACT_APP_CHAT_HOST, {
    transports: ["websocket"],
    query: {
        "bearer": getBearer()
    }
});

export const imSocket = io.connect(process.env.REACT_APP_API_HOST, {
    transports: ["websocket"],
    query: {
        "bearer": getBearer()
    }
});

export const SocketContext = createContext();
