import React, { useEffect, useContext, useRef, useState } from 'react';
import { Context, GameListContext } from '../store';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Alert, AppBar, Box, Collapse, Container, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material';
import ThemeButton from '../layout/components/ThemeButton';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import ThemeInput from '../layout/components/ThemeInput';
import GameListCard from './GameListCard';
import { ProviderLogo } from '../helpers/gamesHelper';
import { Popover } from 'react-tiny-popover'
import theme from '../layout/DefaultTheme';
import _ from 'lodash'

const GameList = (props) => {
    const { provider, mode } = props.match.params;
    const isMobile = useMediaQuery('(max-width:600px)');

    const type = props.match.params.type || 'all';
    const [limitPageSize, setLimitPageSize] = useState(48);
    const [games, setGames] = useState({
        list: [],
        count: 0
    })
    const [filter, setFilter] = useState({
        search: '',
        provider,
        type,
        live: mode == 'live',
        limit: 0
    });

    const [showSearchBar, setShowSearchBar] = useState(false);
    // 600. 900, 1200, 1535
    const xs = useMediaQuery(theme.breakpoints.down('sm'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    // useEffect(() => {
    //     let pageSize = 48;
    //     // if (xl) {
    //     //     pageSize = 30;
    //     // } else if (lg) {
    //     //     pageSize = 60;
    //     // } else if (md) {
    //     //     pageSize = 20;
    //     // } else if (sm) {
    //     //     pageSize = 12;
    //     // } else if (xs) {
    //     //     pageSize = 12;
    //     // }
    //     setLimitPageSize(pageSize)
    // }, [xs, sm, md, lg, xl])


    return (
        <>
            <AppBar>
                <Toolbar />
                <Box sx={{
                    width: '100%',
                    bgcolor: '#000',
                    paddingLeft: isMobile ? 0 : '4rem',
                    boxShadow: '0 0px 5px #26ddff',
                }}>
                    <GameListFilter
                        showSearchBar={showSearchBar}
                        setShowSearchBar={setShowSearchBar}
                        setGames={setGames}
                        filter={filter}
                        setFilter={setFilter}
                        provider={provider}
                        mode={mode}
                        type={type}
                        limitPageSize={limitPageSize}
                    ></GameListFilter>
                </Box>
            </AppBar>
            <Box sx={{
                paddingLeft: isMobile ? 0 : '4rem',
                paddingTop: isMobile ? 0 : '1rem',
            }}>
                <Toolbar />
                {/* {xs && <h1>xs</h1>}
                {sm && <h1>sm</h1>}
                {md && <h1>md</h1>}
                {lg && <h1>lg</h1>}
                {xl && <h1>xl</h1>}
                {window.innerWidth} |
                {limitPageSize} */}
                <Container
                    sx={{
                        p: 0,
                        // background: '#fff6',
                        maxWidth: xl ? '100% !important' : lg ? '1400px !important' : md ? '1100px' : sm ? '700px' : '500px'
                    }}>
                    <Box>
                        <Collapse in={(filter.search) && !Boolean(
                            (
                                (filter.provider && games.list.filter(g => g.provider == filter.provider).length)
                                ||
                                (filter.type && games.list.filter(g => g.type == filter.type).length)
                                ||
                                (filter.search && games.list.filter(g => g.title?.indexOf(filter.search) > -1).length)
                            )
                        )} timeout={1000}>
                            <Box className='text-center w-100 px-3' sx={{
                                mt: '4rem'
                            }}>
                                <Box sx={{ alignItems: 'center', width: 'auto', display: 'inline-block' }} >
                                    <Alert sx={{ alignItems: 'center', width: 'auto' }} variant="outlined" severity="info" className='box-shadow-neon my-2'>
                                        <Typography variant='caption'>
                                            {filter.search ? <strong className='pe-1 text-neon'>"{filter.search}"</strong> : <></>}
                                            {/* {filter.provider ? <strong className='pe-1 text-neon text-capitalize'>{filter.provider}</strong> : <></>} */}
                                            {/* {filter.type ? <strong className='pe-1 text-neon text-capitalize'>{filter.type}</strong> : <></>} */}
                                            not found!
                                            <br />
                                            {
                                                (games.list?.length > 0) && <span>
                                                    Do you mean these games?
                                                </span>
                                            }
                                        </Typography>
                                    </Alert>
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                    <Grid container
                        columns={[3, 4, 6, 8, 12]}
                        className={`mx-0 justify-content-center align-content-start overflow-scroll noScrollbar`}>
                        {games.list.map((game, i) => <GameListCard imageHeight='173px' key={i} {...game}></GameListCard>)}
                    </Grid>
                </Container>
                {
                    (games.list?.length > 0) && <Grid container className={`mx-0 justify-content-center align-content-start overflow-scroll noScrollbar`}>
                        <Grid item xs={12} className={`${games.count <= games.list.length ? '-d-none' : ''} text-center pb-4 pt-2`}>
                            <ThemeButton onClick={() =>
                                setFilter({ ...filter, limit: (filter.limit || limitPageSize) + limitPageSize, showMore: true })
                            } className='px-3 m-1' variant='outlined' color='neon' >
                                <span className='pe-2'><span className='border-bottom border-neon'>{games.list.length}</span> of <span className='border-bottom border-neon'>{games.count}</span></span>
                                Show More
                            </ThemeButton>
                        </Grid>
                    </Grid>
                }

            </Box>

        </>
    )
}

const GameListFilter = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const { setGames, filter, setFilter, limitPageSize, provider, mode, type, showSearchBar, setShowSearchBar } = props;
    const [state, dispatch] = useContext(Context);
    const [contextGameList, setContextGameList] = useContext(GameListContext);
    const [showFavorites, setShowFavorites] = useState(false)

    const contextGameListRef = useRef();
    contextGameListRef.current = contextGameList;

    useEffect(() => {
        setFilter({
            ...filter,
            provider: provider || 'all',
            live: mode == 'live',
            type: type || 'all',
            limit: limitPageSize,//30, // isMobile ? 30 : parseInt($('#GamesWrapper').width() / 139) * (isMobile ? 10 : 5),
            showMore: false
        });
    }, [provider, type, mode, limitPageSize])

    // Search
    useEffect(() => {
        if (!showSearchBar && (filter.search.trim() !== '')) {
            setFilter({
                ...filter,
                search: '',
                showMore: false
            })
        }
    }, [showSearchBar]);

    const filterRef = useRef();
    filterRef.current = filter;
    const handleSearch = () => {
        filterFunc({
            ...filterRef.current,
            limit: filterRef.current.limit == 0 ? (limitPageSize) : filterRef.current.limit
        })
    }

    const searchDebounsRef = useRef(_.debounce(handleSearch, 300));

    useEffect(() => {
        if (contextGameList.games && contextGameList.games.length > 0) {
            searchDebounsRef.current();

        }
    }, [filter, contextGameList.games])

    const filterFunc = (params) => {
        const { provider, type, limit, search, live } = params || {};
        const newGamesList = (contextGameListRef.current.games.filter(g => {
            if (showFavorites) {
                return (state.login && state.user.profile.favorite && state.user.profile.favorite.games && state.user.profile.favorite.games[g._id])
            }
            if (search && (g.title.toLowerCase().indexOf(search.toLowerCase()) == -1)) return false;
            if (live && !g.live) return false;
            if ((provider == 'newGames') && (!g.media || !g.media.images)) return true;
            let result = (((provider == 'all' || (search.trim() !== '')) || (g.provider == provider)) &&
                ((type == 'all') || (g.type == type)));
            return result
        }));
        let topList = [];
        if (provider) {
            topList = newGamesList.filter(g => g.provider == provider);
        }
        const slicedNewGamesList = _.union(topList, newGamesList).slice(0, limit);
        setGames({ list: slicedNewGamesList, count: newGamesList.length });
    }

    useEffect(() => {
        setFilter({
            ...filter,
            showMore: false
        })
    }, [showFavorites])


    return (
        <MDBCol size='12'>
            <MDBRow className='pb-0 pb-md-0'>
                <MDBCol size='12' className={`d-flex position-raltive align-items-baseline ${isMobile ? 'justify-content-center' : 'justify-content-between  '}`}>
                    <div className={`py-1 pt-sm-0 d-flex overflow-hidden ${isMobile ? 'align-items-bottom justify-content-between w-100' : 'align-items-end mt-2 px-3 py-2'}`}>
                        {isMobile && state.login && <span className='p-1 ps-2'><MDBIcon onClick={() => setShowFavorites(!showFavorites)} className={`cursor-pointer text-neon text-shadow-neon ${showFavorites ? '' : 'font-weight-light'}`} icon='star'></MDBIcon></span>}
                        {isMobile ? <></>
                            :
                            <div className='position-relative text-end me-1' style={{
                                width: '7rem'
                            }}>
                                <ThemeInput
                                    placeholder='Your game...'
                                    inputClassName='box-shadow-neon rounded-pill text-start pe-2 py-1'
                                    type='search'
                                    inputStyle={{
                                        fontSize: '0.76rem',
                                        padding: '0 1.5rem',
                                        minHeight: 'auto',
                                        height: 'auto',
                                        border: '1px solid #26ddff',
                                        boxShadow: '0px 0px 10px #26ddff',
                                        lineHeight: '1.25rem'

                                    }}
                                    onChange={e => setFilter({
                                        ...filter, search: e.target.value,
                                        showMore: false
                                    })}
                                    label='Search'
                                    value={filter.search}></ThemeInput>
                                <MDBIcon
                                    style={{
                                        color: '#fff6',
                                        left: '0.6rem',
                                        top: '1.2rem',
                                        fontSize: '0.6rem'
                                    }}
                                    className='position-absolute' icon='search'></MDBIcon>
                            </div>
                        }
                        <div>
                            <SelectProvider provider={provider} type={type} mode={mode}></SelectProvider>
                            <SelectGameType provider={provider} type={type} mode={mode}></SelectGameType>
                        </div>
                        {
                            isMobile ? <>
                                <span className='p-1 pe-2'>
                                    <MDBIcon onClick={() => setShowSearchBar(!showSearchBar)} className=' text-neon text-shadow-neon' icon='search'></MDBIcon>

                                </span>
                            </> : <></>
                        }
                    </div>
                    {
                        !isMobile && state.login ? <>
                            <span className='pt-2 mt-1'>
                                <MDBIcon onClick={() => setShowFavorites(!showFavorites)} className={`cursor-pointer text-neon text-shadow-neon ${showFavorites ? '' : 'font-weight-light'}`} icon='star'></MDBIcon>
                            </span>
                        </> : <></>
                    }
                </MDBCol>
                {
                    isMobile ? <Collapse in={showSearchBar} timeout="auto" unmountOnExit>
                        <div className='position-relative mb-2 mt-1 text-end d-inline-block px-3' style={{
                            width: '100%'
                        }}>
                            <ThemeInput
                                placeholder='Your game...'
                                inputClassName='box-shadow-neon rounded-pill text-start pe-2 py-1'
                                type='search'
                                inputStyle={{
                                    fontSize: '0.76rem',
                                    padding: '0 1.5rem',
                                    minHeight: 'auto',
                                    height: 'auto',
                                    border: '1px solid #26ddff',
                                    boxShadow: '0px 0px 10px #26ddff',
                                    lineHeight: '1.25rem'

                                }}
                                onChange={e => setFilter({
                                    ...filter, search: e.target.value,
                                    showMore: false
                                })}
                                label='Search'
                                value={filter.search}></ThemeInput>
                            {/* <MDBIcon
                                style={{
                                    color: '#fff6',
                                    left: '0.6rem',
                                    top: '1.2rem',
                                    fontSize: '0.6rem'
                                }}
                                className='position-absolute' icon='search'></MDBIcon> */}
                        </div>
                    </Collapse> : <></>
                }
            </MDBRow>
        </MDBCol>

    )
}

const SelectProvider = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const { provider, type, mode } = props;
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setIsPopoverOpen(false);
        }, 200);
    }, [provider, type, mode])



    return (
        <>
            <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => {
                    console.log('popover click outside')
                    setIsPopoverOpen(false)
                }}
                reposition={false}
                positions={['bottom', 'right']} // preferred positions by priority
                align={'start'}
                content={
                    <div className='bg-black overflow-hidden rounded-5 border border-neon box-shadow-neon mt-2 ms-2'>
                        <div className='border-bottom border-neon p-1 px-3 text-center'>
                            <Typography variant={isMobile ? 'caption' : 'caption'} className='opacity-60'>
                                Select Provider
                            </Typography>
                        </div>
                        <div className='p-2'>
                            {['all', 'newGames', 'pragmatic', 'evolution', 'redtiger', 'netent'].map((p, i) => <Link key={i} to={`/gamelist/casino/${p}`}>
                                <ThemeButton size='small' className='px-2 my-1 py-0 text-start w-100 box-shadow-none d-flex align-content-center justify-content-start' variant={(provider == p) ? '' : 'outlined'} color='neon'>
                                    <ProviderLogo provider={p} className='p-1'></ProviderLogo>
                                    {p == 'all' ? 'All Providers' : p == 'pragmatic' ? 'Pragmatic Play' : p == 'newGames' ? 'New Games' : p}
                                </ThemeButton>
                            </Link>
                            )}
                        </div>
                    </div>
                }>
                <span>
                    <ThemeButton
                        // ref={buttonRef}
                        size={isMobile ? 'small' : ''}
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)} className='mx-1 my-0 px-2 py-1 d-inline-block text-start' variant={'outlined'} color='neon'>
                        {provider == 'all' ? <></> :
                            <ProviderLogo
                                provider={provider == 'all' ? '' : provider}
                                className='position-absolute'
                                style={{
                                    top: isMobile ? '-1px' : '0.12rem',
                                    left: '0.1rem',
                                    padding: '0.2rem 0 0.3rem 0'
                                }}></ProviderLogo>
                        }
                        <span className={`${provider == 'all' ? 'w-100 d-inline-block text-center' : 'ps-3'}`}>
                            {provider == 'all' ? 'All Providers' : provider == 'newGames' ? 'New Games' : provider == 'pragmatic' ? 'Pragmatic Play' : provider}
                        </span>
                    </ThemeButton>
                </span>
            </Popover>
        </>
    );
}

const SelectGameType = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const { provider, type, mode } = props;
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    // const buttonRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            setIsPopoverOpen(false);
        }, 200);
    }, [provider, type, mode])

    return (<Popover
        isOpen={isPopoverOpen}
        reposition={false}
        onClickOutside={() => setIsPopoverOpen(false)}
        positions={['bottom', 'right', 'bottom']} // preferred positions by priority
        align={'start'}
        content={
            <div className='bg-black overflow-hidden rounded-5 border border-neon box-shadow-neon mt-2 ms-2'>
                <div className='border-bottom border-neon p-1 px-3 text-center'>
                    <Typography variant={isMobile ? 'caption' : 'caption'} className='opacity-60'>
                        Select Game Type
                    </Typography>
                </div>
                <div className='p-2'>
                    {['all', 'tablegames', 'slots', 'bingo', 'livecasino'].map((t, i) => <Link key={i} to={`/gamelist/casino/${provider}/${t == 'livecasino' ? 'all' : t}/${t == 'livecasino' ? 'live' : ''}`}>
                        <ThemeButton size='small' className='px-2 my-1 py-1 text-center d-block w-100 box-shadow-none' variant={(mode != 'live' && type == t) || (mode == 'live' && t == 'livecasino') ? '' : 'outlined'} color='neon'>
                            {t == 'all' ? 'All Games' : t == 'livecasino' ? 'Live Casino' : t == 'tablegames' ? 'Table Games' : t}

                        </ThemeButton>
                    </Link>
                    )}
                </div>
            </div >
        }>
        <span>

            <ThemeButton
                // ref={buttonRef}
                size={isMobile ? 'small' : ''}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)} className='mx-1 my-0 px-2 py-1 d-inline-block' variant={'outlined'} color='neon'>
                {type == 'all' && mode != 'live' ? 'All Games' : type == 'all' && mode == 'live' ? 'Live Casino' : type == 'tablegames' ? 'Table Games' : type}
            </ThemeButton>
        </span>
    </Popover>
    )
}

export default GameList

