import { MDBCard, MDBCardHeader, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import React, { useState, useContext, useRef, useEffect } from 'react'
import { AppBar } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { Context } from '../../store.js';
import { logout } from './../../helpers/publicHelper';
import { withStyles } from '@mui/styles';
import ThemeButton from '../components/ThemeButton';
import Help from './Help';
import TelegramLogin from '../auth/TelegramLogin';
import ForgotPassword from '../auth/ForgotPassword';
import Login from '../auth/Login';
import SignUp from '../auth/SignUp';
import { useHistory } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className='overflow-hidden'
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const StyledTab = withStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        padding: '1rem 0px !important',
        opacity: 1,
        // width: '33.33%',
        // maxWidth: '33.33%',
        color: 'white',
        fontSize: '0.7rem !important',
        '&:hover': {
            color: '#26ddff',
            textShadow: '0px 0px 10px #26ddff',
            opacity: 1,
        },
        '&$selected': {
            textShadow: '0px 0px 2px #26ddff88'
        },
        '&:focus': {
            color: '#26ddff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);


const LoginRegisterTabs = (props) => {
    const { mode } = props;
    const [state, dispatch] = useContext(Context);
    const history = useHistory();

    const [values, setValues] = useState({
        view: 'loginSignup',
        tabValue: mode || state.active.loginRegisterTabs
    })

    useEffect(() => {
        setValues({
            ...values,
            tabValue: mode || state.active.loginRegisterTabs
        })
    }, [state.active.loginRegisterTabs])

    // const oneClickWrapper = useRef();

    // const [showTelegramLogin, setShowTelegramLogin] = useState(false)
    // const toggleShowOneClick = () => {
    //     setShowTelegramLogin(!showTelegramLogin)
    //     $(oneClickWrapper.current).slideDown()
    // }

    return (
        <MDBRow className='mx-0'>
            <MDBCard className='bg-transparent p-0 m-0'>
                {!state.login && values.view == 'loginSignup' ?
                    <>
                        <MDBCardHeader className='p-0 m-0'>
                            <MDBRow className='m-0 d-flex border-bottom border-dark' >
                                <MDBCol size='12' className='p-0'>
                                    <AppBar position="static" color="transparent">
                                        {/* <StyledTabs > */}
                                        <Tabs
                                            value={values.tabValue} onChange={(event, newValue) => { setValues({ ...values, tabValue: newValue }) }}
                                            TabIndicatorProps={{ children: <span /> }}
                                            sx={{
                                                '& .MuiTabs-flexContainer': {
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                },
                                                '& .MuiTabs-indicator': {
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    backgroundColor: 'transparent',
                                                    '& > span': {
                                                        width: '100%',
                                                        backgroundColor: '#26ddff'
                                                    },
                                                }
                                            }}>
                                            <StyledTab label="Login" />
                                            <StyledTab label="SignUp" />
                                            <StyledTab label="One-Click" />
                                        </Tabs>
                                        {/* </StyledTabs> */}
                                    </AppBar>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCol size='12' className='pt-3 px-0'>
                            <SwipeableViews index={values.tabValue} onChangeIndex={index => { setValues({ ...values, tabValue: index }) }}>
                                <TabPanel value={values.tabValue} index={0} >
                                    <Login oneClick={e => { setValues({ ...values, tabValue: 2 }) }} setView={view => setValues({ ...values, view })}></Login>
                                </TabPanel>
                                <TabPanel value={values.tabValue} index={1} >
                                    <SignUp></SignUp>
                                </TabPanel>
                                <TabPanel value={values.tabValue} index={2} >
                                    <TelegramLogin></TelegramLogin>
                                </TabPanel>
                            </SwipeableViews>
                        </MDBCol>
                    </> : <></>
                }
                {values.view == 'help' ? <Help setView={view => setValues({ ...values, view })}></Help> : <></>}
                {values.view == 'forgotPassword' ? <ForgotPassword setView={view => setValues({ ...values, view })}></ForgotPassword> : <></>}
                {/* {!state.login && !values.tabValue ?
                    <MDBCol size='12' className='text-center border-top border-info pt-3'>
                        <ThemeButton onClick={toggleShowOneClick} type='submit' variant='outlined' className='px-3'><MDBIcon icon='paper-plane' className='pe-1'></MDBIcon>
                            <span style={{ paddingTop: '0.1rem' }}>One-Click {values.tabValue ? 'SignUp' : 'Login'}</span>
                        </ThemeButton>
                        <div ref={oneClickWrapper} style={{ display: 'none' }}>
                            {showTelegramLogin ? <TelegramLogin></TelegramLogin> : <></>}
                        </div>
                    </MDBCol> : <></>
                } */}
                {/* <Pages></Pages> */}
                {state.login ?
                    <MDBRow className='mx-0'>
                        <MDBCol size='12' className='pt-1 text-center pb-5 mb-md-5'>
                            <ThemeButton variant='outlined' color='danger' className='px-3' onClick={() => { setValues({ ...values }); logout(state, dispatch, history) }} >Logout</ThemeButton>
                        </MDBCol>
                    </MDBRow> : <></>
                }
            </MDBCard>
        </MDBRow>
    );
}

export default LoginRegisterTabs
