import React, { useContext, useEffect } from 'react'
import { getState } from '../helpers/publicHelper';
import { Context, GameListContext } from '../store';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from '@mui/material';

const GameLaunch = (props) => {
  const isMobile = useMediaQuery('(max-width:600px)');
    const [state, dispatch] = useContext(Context);
    const history = useHistory();
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token');
    const game = query.get('game');
    const [contextGameList, setContextGameList] = useContext(GameListContext);

    useEffect(() => {
        state.setCookie('bearer', token, { path: '/' });
        setTimeout(() => {
            getState(state, dispatch, setContextGameList, true, token, function () {
                switch (game) {
                    // case 'Digitain':
                    //     history.push('/Games/Digitain/Sport/MobileView')
                    //     break;
                    case 'EveryMatrix':
                        history.push('/Games/SportsBetting/Sport')
                        break;
                    // case 'Sport':
                    //     history.push('/gamelist/casino/all/slots')
                    //     break;
                    case 'LiveCasino':
                        history.push('/gamelist/casino/evolution/all/live')
                        break;
                    case 'Slots':
                        history.push('/gamelist/casino/all/slots')
                        break;
                    case 'Crash':
                        history.push('/games/crash')
                        break;
                    // case 'Poker':
                    //     history.push('/games/poker')
                    //     break;
                    default:
                        history.push('/')
                        break;
                }
            }, isMobile);
        }, 500);
    }, [])
    return (
        <div>

        </div>
    )
}

export default GameLaunch
