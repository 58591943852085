import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LeftDrawerList from './LeftDrawerList';
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';

const drawerWidth = 240;




const LeftDrawer = (props) => {
    const theme = useTheme();
    const { open, setOpen } = props;
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const isMobile = useMediaQuery('(max-width:850px)');


    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(0)} + 0px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(isMobile ? 0 : 8)} + 0px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );


    return <>
        <Drawer variant="permanent" open={open} sx={{ zIndex: isMobile ? 99999 : 'inherit', position: 'absolute' }}>
            <DrawerHeader sx={{ height: ['2.5rem', '3rem'], display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img src={`/logo/logo-tm.svg`} style={{ height: '2.2rem' }} />
                <Box sx={{
                    display: 'flex',
                    height: ['2.5rem', '3rem'],
                    alignItems: 'center'
                }}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </Box>
            </DrawerHeader>
            <Divider />
            <LeftDrawerList />
            <Divider />
        </Drawer>
    </>
}

export default LeftDrawer