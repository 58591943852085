import React, { useEffect, useContext } from 'react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import QRCode from 'react-qr-code'
import ThemeButton from '../components/ThemeButton'
import { Context } from '../../store'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { loginSuccess, setActive } from '../../helpers/publicHelper'
import { Alert, Typography } from '@mui/material';
import useState from 'react-usestateref';

const TelegramLogin = (props) => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [checkTelegram, setCheckTelegram, refCheckTelegram] = useState(state.active.checkTelegram)
    useEffect(() => {
        setCheckTelegram(state.active.checkTelegram)
    }, [state.active.checkTelegram])

    const [values, setValues] = useState({
        telegramStartUrl: ''
    })

    const getTelegramLoginToken = () => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        const headers = {
                            captcha,
                            refid: new URL(window.location.href).searchParams.get('refId')
                        }
                        axios.get(process.env.REACT_APP_API_HOST + '/api/users/telegram-login',
                            { headers })
                            .then(res => {
                                res = res.data;
                                if (res.success) {
                                    setTimeout(() => {
                                        setValues({ ...values, telegramStartUrl: `https://t.me/${res.result.botName}?start=${res.result.token}` });
                                        tryTelegramLogin(res.result, null)
                                    }, 500);
                                } else {
                                    enqueueSnackbar(res.message, {
                                        variant: 'error',
                                    })
                                }
                            })
                            .catch((err) => {
                                enqueueSnackbar('Telegram login token error', {
                                    variant: 'error', autoHideDuration: 1500
                                })
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }
    }

    const tryTelegramLogin = (result, notif) => {
        if (refCheckTelegram) {
            const { token } = result;
            var postData = {
                loginCode: token
            };
            if (window.grecaptcha) {
                window.grecaptcha.ready(() => {
                    window.grecaptcha
                        .execute(state.recaptchaSiteKey, { action: "homepage" })
                        .then(captcha => {
                            const headers = { captcha }
                            axios.post(process.env.REACT_APP_API_HOST + '/api/users/telegram-login', postData, { headers })
                                .then(res => {
                                    const token = res.headers['x-auth-token']
                                    res = res.data;
                                    if (res.success) {
                                        // closeSnackbar(notif);
                                        loginSuccess(state, dispatch, res.result, token);
                                        enqueueSnackbar('Done!', {
                                            variant: 'success'
                                        })
                                    } else {
                                        // Invalid token
                                        if (res.code == 404) {
                                            enqueueSnackbar(res.message, { variant: 'error' });
                                        }
                                        // Expired token
                                        if (res.code == 410) {
                                            enqueueSnackbar('Expired token, scan again.', { variant: 'error' });
                                            getTelegramLoginToken();
                                        }
                                        // Waiting for start
                                        if (res.code == 400) {
                                            // enqueueSnackbar('Waiting ...', { variant: 'warning' })
                                            setTimeout(() => {
                                                tryTelegramLogin(result, notif);
                                            }, 5000);
                                        }
                                    }
                                })
                                .catch((err) => {
                                    enqueueSnackbar('Telegram login token error', {
                                        variant: 'error', autoHideDuration: 1500
                                    })
                                })
                        })
                })
            } else {
                enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
            }


        } else {
            // closeSnackbar(notif)
        }
    }

    useEffect(() => {
        setActive(state, dispatch, {
            checkTelegram: true
        })
        if (state && state.config.api && '/api/users/telegram-login') {
            getTelegramLoginToken();
        }
        return () => {
            setActive(state, dispatch, {
                checkTelegram: false
            })
        }
    }, [state.config.api])

    return (
        <MDBRow className='m-0 text-center pb-5'>
            <MDBCol size='12' className='py-2 text-center'>
                <Typography variant='subtitle2' className='text-orange text-shadow-orange'>
                    Register or Sign In via Telegram
                </Typography>
            </MDBCol>
            <MDBCol size='12' className='pt-2 text-center' style={{ height: 170 }}>
                {values.telegramStartUrl ?
                    <span className='border border-white rounded p-1 d-inline-block bg-white box-shadow'>
                        <QRCode size={150} level='L' value={values.telegramStartUrl} />
                    </span>
                    : <>
                        <div className='text-center d-flex w-100' style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center', height: '150px' }}>
                            <div className='position-relative d-flex m-auto' style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center', width: '4rem' }}>
                                <div className='neonProgress'></div>
                                <span className='position-absolute text-center' style={{ width: 100 }}></span>
                            </div>
                        </div>
                    </>}
            </MDBCol>
            <MDBCol size='12' className='pt-3 text-center'>
                {
                    values.telegramStartUrl &&
                    <ThemeButton variant='outlined' className='px-3'><a target='_blank' className='text-neon' href={values.telegramStartUrl}>Scan the QR or Click Here</a></ThemeButton>
                }
            </MDBCol>
            <MDBCol size='12' className='pt-3 text-center'>
                <Typography variant='subtitle2' style={{
                    color: '#999',
                    fontSize: '0.7rem'
                }} className='text-justify font-weight-normal text-center'>
                    By scanning the QR or using one-click signup
                    <br />
                    you confirm that you have read and agreed to the
                    <br />
                    Terms and Conditions and Privacy Policy of the company, consent to the processing of your personal information and confirm that you are over 18 years old.
                </Typography>
            </MDBCol>
        </MDBRow>
    )
}

export default TelegramLogin
