import React, { useContext } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Context } from '../../../store';
import { getBearer, setActive } from '../../../helpers/publicHelper';
import _ from 'lodash';
import validator from 'validator';
import ThemeInput from '../../components/ThemeInput';
import PIN from '../../components/PIN';
import { Alert, Typography } from '@mui/material';
import ThemeButton from '../../components/ThemeButton';

// Submit Email
const callVerifyEmail = (state, handleSetActive, enqueueSnackbar) => {
    handleSetActive({ waiting: true });
    const postData = {
        email: state.active.kyc.email.address
    };
    axios.post(process.env.REACT_APP_API_HOST + '/api/users/kyc/email', postData,
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                handleSetActive({
                    mode: 'code',
                    waiting: false
                })
                enqueueSnackbar('Check your Mailbox!', {
                    variant: 'success', autoHideDuration: 3000
                })
            } else {
                if (res.code == 429) {
                    handleSetActive({
                        mode: 'code',
                        waiting: false
                    })
                    enqueueSnackbar(res.message, {
                        variant: 'warning', autoHideDuration: 3000
                    })
                } else {
                    handleSetActive({
                        waiting: false
                    })
                    enqueueSnackbar(res.message, {
                        variant: 'error', autoHideDuration: 3000
                    })
                }

            }
        })
        .catch((err) => {

        })
}


// Verify Code
const submitCode = (state, handleSetActive, enqueueSnackbar) => {
    handleSetActive({ waiting: true });
    axios.post(process.env.REACT_APP_API_HOST + '/api/users/kyc/verifyemail', {
        code: state.active.kyc.email.code
    }, {
        headers: { 'x-auth-token': getBearer() }
    })
        .then(res => {
            res = res.data;
            if (res.success) {
                handleSetActive({
                    mode: '',
                    code: '',
                    waiting: false
                })
                enqueueSnackbar('Verified', {
                    variant: 'success', autoHideDuration: 3000
                })
            } else {
                handleSetActive({
                    waiting: false
                })
                enqueueSnackbar(res.message, { // Error is not handled
                    variant: 'error', autoHideDuration: 3000
                })
            }
        })
        .catch((err) => {

        })
}

const KYCEmail = (props) => {
    const [state, dispatch] = useContext(Context);
    const stateKYC = state.user ? state.user.kyc : null;
    const emailVerified = _.has(stateKYC, 'emails.default.verified') ? stateKYC.emails.default.verified : false;
    const { mode, address, waiting, code } = state.active.kyc.email;
    const { enqueueSnackbar } = useSnackbar();

    const handleSetActive = (activeObj) => {
        setActive(state, dispatch, {
            kyc: {
                ...state.active.kyc,
                email: {
                    ...state.active.kyc.email,
                    ...activeObj
                }
            }
        });
    }

    const validateEmail = (email) => {
        if (validator.isEmail(email)) {
            callVerifyEmail(state, handleSetActive, enqueueSnackbar)
        }
        else {
            enqueueSnackbar('Invalid Email Address', {
                variant: 'error', autoHideDuration: 3000
            })
        }
    }


    return (<>

        {
            emailVerified ?
                /* VERIFIED Email */
                <ListItem
                    secondaryAction={<Avatar className={`bg-transparent box-shadow-success border border-success`}><MDBIcon icon='check' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                    <ListItemText
                        primary={<small>{stateKYC.emails.default.address}</small>}
                        className='pe-3'
                        secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                        secondary={<small className='text-success'>Verified</small>}
                    />
                </ListItem>
                :
                /* NOT VERIFIED */
                <>
                    {mode == '' ?
                        <ListItem
                            secondaryAction={<Avatar onClick={() => handleSetActive({ mode: 'add' })} className={`bg-transparent cursor-pointer box-shadow-neon border border-neon`}><MDBIcon icon='plus' color='neon' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                            <ListItemText
                                primary={<span>Email<span className='text-danger ps-1'>*</span></span>}
                                className='pe-3'
                                secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                                secondary={<small className='text-neon text-shadow-neon'>Add your email</small>} />
                        </ListItem> : <></>
                    }
                    {mode == 'add' ?
                        <ListItem
                            secondaryAction={<Avatar onClick={() => validateEmail(address)} className={`bg-transparent mt-1 cursor-pointer box-shadow-neon border border-neon`}><MDBIcon icon='paper-plane' color='white' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                            <ListItemText
                                primary={<ThemeInput
                                    label={<span>Email<span className='text-danger ps-1'>*</span></span>}
                                    value={state.active.kyc.email.address}
                                    type='text'
                                    onChange={e => handleSetActive({ address: e.target.value })}
                                ></ThemeInput>}
                                className='pe-3 me-1' />
                        </ListItem> : <></>
                    }
                    {mode == 'code' ? <>
                        <ListItem
                            secondaryAction=''>
                            <ListItemText
                                primary={
                                    <Alert
                                        sx={{
                                            alignItems: 'center',
                                            '.MuiAlert-message': {
                                                width: '100%'
                                            }
                                        }} variant="outlined" severity="success">
                                        <div className='text-success w-100 text-center'>A code has been sent to
                                            <div className='text-center text-success w-100'>{address}
                                                <Typography variant='caption' component='span' onClick={() => handleSetActive({ mode: 'add' })} className='cursor-pointer ps-2 text-warning'>Edit</Typography>
                                            </div>
                                        </div>
                                    </Alert>
                                    // <div className='text-success2'>A code has been sent to <div>{address}<span onClick={() => handleSetActive({ mode: 'add' })} className='cursor-pointer ps-2 text-warning'>Edit</span></div> </div>
                                }
                                className='' />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <ThemeButton onClick={() => submitCode(state, handleSetActive, enqueueSnackbar)}
                                    disabled={code.length !== 6}
                                    size='small'
                                    color='neon'
                                    className={`px-2`}>
                                    Verify
                                </ThemeButton>}>
                            {/* <Avatar onClick={() => submitCode(state, handleSetActive, enqueueSnackbar)} className={`${code.length !== 6 ? 'opacity-40' : ''} bg-transparent cursor-pointer box-shadow-neon border border-neon`}><MDBIcon icon='check' color='white' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar> }>*/}
                            <ListItemText primary={<PIN inputStyle={{ padding: 0 }} name='emailCode' size='small' handlechange={e => handleSetActive({ code: e.value })} type='number' fields={6} />} className='pe-3 me-1' />
                        </ListItem></> : <></>
                    }
                </>
        }
    </>)
}

export default KYCEmail
