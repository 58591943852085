import CustomIcon from "../components/CustomIcon";

const ProviderLogo = (props) => {
    let { provider, color, ...extra } = props;
    if (color) {
        extra.fill = ((provider == 'netent') ? '#78be21' : provider == 'redtiger' ? 'red' : provider == 'pragmatic' ? '#f19021' : 'white');
    }
    return (provider == 'all') ? <CustomIcon {...extra} icon='im-casino'></CustomIcon> :
        (provider == 'newGames') ? <CustomIcon {...extra} icon='im-casino'></CustomIcon> :
            (provider == 'netent') ? <CustomIcon {...extra} icon='im-netent'></CustomIcon> :
                (provider == 'redtiger') ? <CustomIcon  {...extra} icon='im-redTiger2'></CustomIcon> :
                    (provider == 'pragmatic') ? <CustomIcon {...extra} icon='im-pragmatic2'></CustomIcon> :
                        (provider == 'evolution') ? <CustomIcon  {...extra} icon='im-evolution'></CustomIcon> : <span style={{ width: 3 }} className='d-inline-block'>&nbsp;</span>
}

export {
    ProviderLogo
}
