import * as React from 'react';
import Box from '@mui/material/Box';
import { getBearer } from '../../helpers/publicHelper';
import axios from 'axios';
import { Button, Collapse, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useRef } from 'react';
import ThemeSelect from '../components/ThemeSelect';
import { MDBIcon } from 'mdb-react-ui-kit';

const getPage = (props) => {
    let { setData, _id } = props;
    axios.post(process.env.REACT_APP_API_HOST + '/api/page/details',
        { _id },
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                if (setData) {
                    setData(res.result);
                }
            } else {
                if (setData) {
                    setData({});
                }
            }
        })
}


const print = (data) => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title + '</h1>');
    mywindow.document.write(data);
    // mywindow.document.write(JSON.stringify(pages));
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}


const PageModal = (props) => {
    const { pages, selectedId } = props;
    const [selectedTab, setSelectedTab] = useState(pages.findIndex(p => p._id == selectedId));
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box
            sx={{
                //  bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                height: '100%',
                alignItems: 'stretch'
            }}>

            {isMobile ?
                <Box className='p-2'>
                    <PageTitles onChange={e => setSelectedTab(e.target.value)} value={selectedTab} pages={pages} />
                </Box>
                :
                <Box
                    sx={{
                        width: '10rem',
                    }}>
                    {pages?.map((page, i) =>
                        <Typography variant='subtitle2' className={`cursor-pointer p-2 border-bottom border-light ${selectedTab == i ? 'text-neon' : ''}`} onClick={() => setSelectedTab(i)}>
                            {page.title}
                        </Typography>
                    )}
                </Box>
            }
            <Box
                className='box-shadow-light overflow-scroll'
                sx={{
                    width: ['100%', 'calc(100% - 10rem)'],
                    height: '100%'
                }}>
                <PageContent {...pages?.[selectedTab]} />
            </Box>
        </Box>
    );
}

const PageContent = (props) => {
    const { _id } = props;
    const [data, setData] = React.useState({})
    const iFrameRef = useRef();
    const isMobile = useMediaQuery('(max-width:600px)');

    const pageStyle = `
    body { color: #ddd;font-family: "Roboto","Helvetica","Arial",sans-serif; }
    
${isMobile ? 'body * {margin-left: 0px !important}; html { padding-left: 10px !important; }' : '{}'}
.se-table-size-100 {width: 100%}
table, td, th {border-collapse: collapse; border: 1px solid #999;}
td, th {padding: 5px;}
td p {padding: 3px;margin: 2px;}
a, a * {text-decoration: none;}
    `
    React.useEffect(() => {
        setData({});
        getPage({
            setData,
            _id
        })

        iFrameRef?.current?.addEventListener("load", ev => {
            console.log('iFrame loaded')
            const new_style_element = document.createElement("style");
            new_style_element.textContent = pageStyle;
            ev.target.contentDocument.head.appendChild(new_style_element);
        });

    }, [_id])

    return <Box key={_id} className='h-100'>
        <Collapse in={!Boolean(data?.title)} timeout={0} unmountOnExit>
            <LinearProgress color='info' />
        </Collapse>
        <Collapse in={Boolean(data?.title)} timeout="auto"
            sx={{
                '& .MuiCollapse-wrapper': {
                    height: '100%'
                }
            }}
            className='h-100'>
            <Button className='m-1' onClick={() => print(data?.content)} variant='outlined' size='small' color='success'>
                <Typography >
                    <MDBIcon icon='print' className='pe-1' />
                    Print
                </Typography>
            </Button>

            <Box className='h-100'>
                <iframe
                    ref={iFrameRef}
                    style={{
                        //background: '#121212',
                        color: '#f00',
                        width: '100%',
                        height: '100%',
                    }}
                    // srcDoc={data?.content?.replaceAll(/&nbsp;/gi,'').replaceAll(/margin-left/gi,'x')}
                    srcDoc={data?.content}
                ></iframe>
            </Box>

        </Collapse>
    </Box>
}

const PageTitles = (props) => {
    const { pages } = props;

    return <ThemeSelect
        label='Select Page'
        options={pages.map((page, i) => <option key={i} value={i}>{<span className={`text- p-1`}>{page.title}</span>}</option>)}
        {...props}
    />;
}
export default PageModal
