import React from 'react'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    loading: {
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#0006',
        zIndex: 9,
        left: 0,
        bottom: 0,
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    },
}));

const LayoutProgress = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.loading + ' ' + props.className}>
            <div style={{ flex: 1 }}>
               {props.children}
            </div>
        </div>
    )
}

export default LayoutProgress
