import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { listItemIconSX } from './listItemIconSX';

const CustomListItem = (props) => {
    const { text, icon, url, onClick } = props;

    return <ListItem disablePadding sx={{ display: 'block' }} onClick={onClick}>
        <ListItemButton className='py-0 text-white'>
            <ListItemIcon sx={listItemIconSX}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} primaryTypographyProps={{ fontSize: ['0.7rem', '0.9rem'] }} />
        </ListItemButton>
    </ListItem>
}

export default CustomListItem
