import React, { useEffect, useRef, useState, useContext } from 'react';
import { gsap } from 'gsap';
import { Draggable, InertiaPlugin } from 'gsap/all';
import ThemeButton from '../../layout/components/ThemeButton';
import { Context } from '../../store';
import { Box, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import $ from 'jquery';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import numeral from 'numeral';

const flipCard = (selector) => {
    // console.log('FlipCard', selector)
    var $elem = $(selector);

    $({ deg: 0 }).animate({ deg: 90 }, {
        duration: 300,
        step: function (now) {
            $elem.css({
                transform: 'rotateY(' + now + 'deg)'
            });
        },
        complete: () => {
            $elem.toggleClass('flippedCard');
            $elem.find('.cardFront').toggleClass('d-none');
            $elem.find('.cardBack').toggleClass('d-none');
            $({ deg: 90 }).animate({ deg: 0 }, {
                duration: 300,
                step: function (now) {
                    $elem.css({
                        transform: 'rotateY(' + now + 'deg)'
                    });
                },
                complete: () => { }
            });
        }
    });
}

const cashbackOnLostData = [
    { p: '2%', min: 100, max: 1000 },
    { p: '5%', min: 1001, max: 5000 },
    { p: '10%', min: 5001, max: 50000 },
    { p: '15%', min: 50001, max: 100000 },
    { p: '20%', min: 100001, max: 500000 },
    { p: '25%', min: 500001 },
]

const referralCommissionData = [
    { activeUsers: '0-10', p: 2 },
    { activeUsers: '11-20', p: 4 },
    { activeUsers: '21-50', p: 6 },
    { activeUsers: '51-100', p: 10 },
    { activeUsers: '100-500', p: 12 },
    { activeUsers: '501-1,000', p: 15 },
    { activeUsers: '1,001-5,000', p: 20 },
    { activeUsers: '5,001-10,000', p: 25 },
    { activeUsers: '10,001-50,000', p: 30 },
    { activeUsers: '50,001-100,000', p: 35 },
    { activeUsers: 'Over 100,000', p: 40 },
]

const depositBonusData = [
    { p: '25%', turnover: 20, min: 100, max: 100000 },
    { p: '50%', turnover: 30, min: 250, max: 100000 },
    { p: '75%', turnover: 40, min: 500, max: 100000 },
    { p: '100%', turnover: 50, min: 1000, max: 100000 },
]


const LobbySlider = () => {
    const [state, dispatch] = useContext(Context);
    const [loading, setLoading] = useState(false);

    const [sliderState, setSliderState] = useState({
        stop: false,
        flipped: false,
    })
    const sliderStateRef = useRef();
    sliderStateRef.current = sliderState;

    const initGSAP = () => {
        setLoading(true);
        gsap.registerPlugin(Draggable, InertiaPlugin);
        var slideDelay = 5;
        var slideDuration = 1;
        var snapX;

        var slides = document.querySelectorAll("#LobbySlider .slide");
        var autoPlayLimit = slides.length * 2;
        var autoPlayCount = 0;
        var prevButton = document.querySelector("#LobbySlider #prevButton");
        var nextButton = document.querySelector("#LobbySlider #nextButton");
        var progressWrap = gsap.utils.wrap(0, 1);
        var numSlides = slides.length;

        gsap.set(slides, { xPercent: i => i * 100 });

        var wrap = gsap.utils.wrap(-100, (numSlides - 1) * 100);
        var timer = gsap.delayedCall(slideDelay, autoPlay);

        var animation = gsap.to(slides, {
            xPercent: "+=" + (numSlides * 100),
            duration: 1,
            ease: 'none',
            paused: true,
            repeat: -1,
            modifiers: {
                xPercent: wrap
            }
        });

        var proxy = document.createElement("div");
        var slideAnimation = gsap.to({}, {});
        var slideWidth = 0;
        var wrapWidth = 0;
        resize();

        var draggable = new Draggable(proxy, {
            type: "x",
            lockAxis: true,
            trigger: ".cardFront .draggable",
            inertia: true,
            onPress: updateDraggable,
            onDrag: updateProgress,
            onThrowUpdate: updateProgress,
            snap: {
                x: snapX
            }
        });

        window.addEventListener("resize", resize);
        prevButton.addEventListener("click", function () { animateSlides(1); });
        nextButton.addEventListener("click", function () { animateSlides(-1); });

        function updateDraggable() {
            timer.restart(true);
            slideAnimation.kill();
            this.update();
        }

        function animateSlides(direction) {

            timer.restart(true);
            slideAnimation.kill();

            var x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);

            slideAnimation = gsap.to(proxy, {
                x: x,
                duration: slideDuration,
                onUpdate: updateProgress
            });
        }

        function autoPlay() {
            // console.log('testState', sliderStateRef.current.stop)
            if (draggable.isPressed || draggable.isDragging || draggable.isThrowing || sliderStateRef.current.stop || sliderStateRef.current.flipped) {
                timer.restart(true);
            } else {
                autoPlayCount++;
                animateSlides(-1);
                // if (autoPlayCount < autoPlayLimit) {}
            }
        }

        function updateProgress() {
            animation.progress(progressWrap(gsap.getProperty(proxy, "x") / wrapWidth));
        }

        function resize() {

            var norm = (gsap.getProperty(proxy, "x") / wrapWidth) || 0;

            slideWidth = slides[0].offsetWidth;
            wrapWidth = slideWidth * numSlides;
            snapX = gsap.utils.snap(slideWidth);

            gsap.set(proxy, {
                x: norm * wrapWidth
            });

            animateSlides(0);
            slideAnimation.progress(1);
        }
    }


    useEffect(() => {
        setSliderState({
            stop: false,
            flipped: false,
        })
        initGSAP()
    }, [state.login])

    return (
        <Box sx={{
            '& .carousel-control-prev': {
                width: 'auto !important',
                left: '-2rem',
                opacity: '0.2',
                top: '1rem'
            },
            '& .carousel-control-next': {
                width: 'auto !important',
                right: '-2rem',
                opacity: '0.2',
                top: '1rem'
            }
        }}>
            <MDBRow id='LobbySlider' className='m-0 mb-4  justify-content-center'>
                <MDBCol size='10' md='6' lg='5' className='position-relative rounded-8 p-0' style={{ boxShadow: '0 0px 10px #000', backgroundColor: '#ffffff0b', height: '10rem' }}>
                    <SliderButton type='prev' />
                    <SliderButton type='next' />
                    <Box sx={{
                        position: 'relative',
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'hidden',
                        justifyContent: 'center',
                        height: '30vh'
                    }}>
                        <Box
                            id="slides-container"
                            onMouseOver={() => setSliderState({
                                ...sliderState,
                                stop: true
                            })}
                            onMouseLeave={() => setSliderState({
                                ...sliderState,
                                stop: false
                            })}
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                display: 'flex',
                                flex: 1,
                                height: '100%',
                                width: '100%',
                            }}>

                            <CarouselItem setSliderState={setSliderState} sliderState={sliderState} cardId='Slide1'
                                front={<>
                                    <Typography className='font-weight-bold draggable' sx={{ typography: { xs: 'caption', sm: 'h6' } }} >
                                        REGISTER AND GET
                                        <span className='ps-1 draggable text-neon text-shadow-neon d-inline'>
                                            100% WELCOME
                                        </span>
                                    </Typography>
                                    <Typography className='font-weight-bold draggable' sx={{ typography: { xs: 'caption', sm: 'h6' } }} >
                                        BONUS INSTANTLY!
                                    </Typography>
                                    <div className=''>
                                        <ThemeButton style={{ minWidth: '120px' }} onClick={() => {
                                            // setActive(state, dispatch, { loginRegisterTabs: 0 });
                                            setTimeout(() => {
                                                // document.getElementById('OpenRight').click();
                                                document.getElementById('SignInButton').click();
                                            }, 100);
                                        }} className='mt-2 px-4 mx-1' variant='outlined' color='neon'>Sign In</ThemeButton>
                                        <ThemeButton style={{ minWidth: '120px' }} onClick={() => {
                                            // setActive(state, dispatch, { loginRegisterTabs: 1 });
                                            setTimeout(() => {
                                                // document.getElementById('OpenRight').click();
                                                document.getElementById('RegisterButton').click();
                                            }, 100);
                                        }} className='mt-2 px-4 mx-1' color='neon'>Register</ThemeButton>

                                    </div>
                                    <div className='text-center text-white text-shadow-neon pt-2'>
                                        <Typography onClick={() => { }} variant='caption'>
                                            <span className='d-inline-flex draggable'> 100% welcome bonus package </span>
                                            <span className='flipCardText d-inline-flex text-neon text-decoration-underline cursor-pointer px-1' onClick={() => { flipCard('#LobbySlider #Slide1'); setSliderState({ ...sliderState, flipped: true }) }} > terms and conditions </span>
                                            <span className='d-inline-flex draggable'> apply.</span>
                                        </Typography>
                                    </div>
                                </>}
                                back={<div className=' overflow-y-scroll py-2 px-1 px-md-3  themeScrollbar' style={{ height: '10rem' }}>
                                    <h4 className='py-2 m-0 text-neon'>100% BALANCE BONUS</h4>
                                    <Typography className='text-center w-100 overflow-hidden' variant='body2' component='div'>
                                        iMoon offers up to 100% bonus from your balance.
                                        It means as soon as you reach the minimum amount from the list below you will be able to activate the bonus and receive the funds instantly on your balance.

                                        <Typography variant='subtitle2'>
                                            <Table className='small'>
                                                <TableHead>
                                                    <TableRow sx={{ '& th': { fontSize: ['0.6rem', '1rem'] } }}>
                                                        <TableCell>Bonus</TableCell>
                                                        <TableCell>Turnover</TableCell>
                                                        <TableCell>Minimum</TableCell>
                                                        <TableCell>Maximum</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        depositBonusData.map((data, i) =>
                                                            <TableRow key={i} sx={{ '& td': { fontSize: ['0.6rem', '1rem'] } }}>
                                                                <TableCell>{data.p}</TableCell>
                                                                <TableCell>{data.turnover}x</TableCell>
                                                                <TableCell>${numeral(data.min).format('0,0')}</TableCell>
                                                                <TableCell>{data.max ? `$${numeral(data.max).format('0,0')}` : <MDBIcon icon='infinity'></MDBIcon>}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Typography>

                                        <List>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                In order to withdraw your total balance (funds + bonus) you need to fullfil the total turnover that is
                                                applied on calculated as sum of the  total loss amount and total profits.
                                            </ListItem>
                                            <ListItem className='p-0 d-block' style={{ textAlign: 'justify' }}>
                                                For example if your balance is $1,000 and you apply 100% bonus, your total bonus balance will be $2,000 that requires 50x ($100,000) turnover in order to unlock the bonus balance.
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </div>}>
                            </CarouselItem>

                            <CarouselItem setSliderState={setSliderState} sliderState={sliderState} cardId='Slide2' className={'active'}
                                front={<>
                                    <Typography className='font-weight-bold draggable' sx={{ typography: { xs: 'caption', sm: 'h6' } }} >
                                        EARN UP TO
                                    </Typography>
                                    <Typography className='font-weight-bold draggable text-neon text-shadow-neon' sx={{ typography: { xs: 'h5', sm: 'h4' } }} >
                                        40% REFERRAL
                                    </Typography>
                                    <Typography className='font-weight-bold draggable' sx={{ typography: { xs: 'caption', sm: 'h6' } }} >
                                        COMMISION BY INVITING YOUR FRIENDS
                                    </Typography>
                                    <div className='text-center text-white text-shadow-neon pt-2'>
                                        <Typography variant='caption'>
                                            <span className='d-block d-md-inline draggable'> Affiliate program </span>
                                            <span className='flipCardText d-inline-flex text-neon text-decoration-underline cursor-pointer px-1' onClick={() => { flipCard('#LobbySlider #Slide2'); setSliderState({ ...sliderState, flipped: true }) }} > terms and conditions </span>
                                            <span className='d-inline-flex draggable'> apply. </span>
                                        </Typography>
                                    </div>
                                </>}
                                back={<div className=' overflow-y-scroll py-2 px-1 px-md-3 themeScrollbar' style={{ height: '10rem' }}>
                                    <div className='px-3'>
                                        <Typography variant='body1' className='d-inline-block pe-md-1'>
                                            iMoon affiliate
                                        </Typography>
                                        <Typography variant='body1' className='d-inline-block'>
                                            program offers up to
                                        </Typography>
                                    </div>
                                    <h3 className='py-2 m-0 text-neon'>40% COMMISSION</h3>
                                    <Table>
                                        <TableHead>
                                            <TableRow sx={{ '& th': { fontSize: ['0.6rem', '1rem'] } }}>
                                                <TableCell>Active Users</TableCell>
                                                <TableCell>Commission</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                referralCommissionData.map((data, i) =>
                                                    <TableRow key={i} sx={{ '& td': { fontSize: ['0.6rem', '1rem'] } }}>
                                                        <TableCell>{data.activeUsers}</TableCell>
                                                        <TableCell>{numeral(data.p / 100).format('%0')}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                    {/* <Typography variant='body2' className='text-start' component='div'>
                                        <List>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Welcome to the official iMoon Affiliate Programme, we take pride in delivering exceptional service to our Members which in return results in fairer mutual benefits.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Here at iMoon, we have developed a platform to encourage financial freedom by generating income from referring family, friends and your network.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Our Affiliate Programme offers between 2%-50% commission on referrals. We have streamlined our process to ensure Affiliate Members are able to access benefits solely via the help of our system.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Our system allows Members to create campaigns which collect and track marketing activity and results.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Commission earned will be automatically transferred to your main Wallet at the end of each month.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                iMoon does not convert or exchange your commission from Cryptocurrency or Token deposits received from your Referral.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Commission received will be added to each Cryptocurrency wallet individually, I.e If your Referral bets using Dogecoin your commission payout will be paid in Dogecoin directly to your wallet at the end of each month.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Commission withdrawal is simple, yet you may be required to verify your account at any time as an anti-fraud procedure.
                                            </ListItem>
                                        </List>
                                    </Typography> */}
                                </div>}>
                            </CarouselItem>

                            <CarouselItem setSliderState={setSliderState} sliderState={sliderState} cardId='Slide3'
                                front={<>
                                    <Typography className='font-weight-bold draggable' sx={{ typography: { xs: 'caption', sm: 'h6' } }} >
                                        ENJOY UP TO
                                    </Typography>
                                    <Typography className='font-weight-bold draggable text-neon text-shadow-neon' sx={{ typography: { xs: 'h5', sm: 'h4' } }} >
                                        25% CASHBACK
                                    </Typography>
                                    <Typography className='font-weight-bold draggable' sx={{ typography: { xs: 'caption', sm: 'h6' } }} >
                                        BONUS EVERYDAY IF YOU WERE UNLUCKY!
                                    </Typography>
                                    <div className='text-center text-white text-shadow-neon pt-2'>
                                        <Typography variant='caption'>
                                            <span className='d-inline-flex draggable'> Bonus applies on your loss everyday at </span>
                                            <span className='flipCardText d-inline-flex text-neon text-decoration-underline cursor-pointer px-1' onClick={() => { flipCard('#LobbySlider #Slide3'); setSliderState({ ...sliderState, flipped: true }) }}> terms and conditions </span>
                                            <span className='d-inline-flex draggable'> promotion. </span>
                                        </Typography>
                                    </div>
                                </>}
                                back={<div className=' overflow-y-scroll py-2 px-1 px-md-3 themeScrollbar' style={{ height: '10rem' }}>
                                    <h3 className='py-2 m-0 text-neon d-inline'>Up to 25%  Cashback Bonus</h3>
                                    <div className='px-0 overflow-hidden'>
                                        <Typography variant='body1' className='d-inline'>
                                            iMoon takes care of unlucky players from 2% up to 25% cashback instantly on their loss.
                                        </Typography>

                                        <Table>
                                            <TableHead>
                                                <TableRow sx={{ '& th': { fontSize: ['0.6rem', '1rem'] } }}>
                                                    <TableCell>%</TableCell>
                                                    <TableCell>Min</TableCell>
                                                    <TableCell>Max</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    cashbackOnLostData.map((data, i) =>
                                                        <TableRow key={i} sx={{ '& td': { fontSize: ['0.6rem', '1rem'] } }}>
                                                            <TableCell>{data.p}</TableCell>
                                                            <TableCell>${numeral(data.min).format('0,0')}</TableCell>
                                                            <TableCell>{data.max ? `$${numeral(data.max).format('0,0')}` : <MDBIcon icon='infinity'></MDBIcon>}</TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <Typography variant='body2' className='text-start' component='div'>
                                        <List>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                From minimum loss of $100 on your deposit based on the PL you will be able to withdraw cashback bonus.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                Keep in mind that each withdraw will reset your cashback bonus percentage level.
                                            </ListItem>
                                            <ListItem className='p-0 d-block pb-2' style={{ textAlign: 'justify' }}>
                                                In order to receive higher cashback bonus, you can wait to gain your cashback percentage level up and withdraw them all at once from the right menu at bonus tab.
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </div>}>
                            </CarouselItem>
                        </Box>
                    </Box>
                </MDBCol>
            </MDBRow>
        </Box>
    )
}

const FlipBackButton = (props) => {
    const { cardId, setSliderState, sliderState } = props;
    return <MDBIcon className='position-absolute cursor-pointer'
        style={{ top: '0.7rem', right: '0.3rem', zIndex: 9999 }}
        icon='times' onClick={() => {
            flipCard(`#LobbySlider #${cardId}`);
            setSliderState({ ...sliderState, flipped: false, stop: false })
        }}></MDBIcon>
}

const CarouselItem = (props) => {
    const [state, dispatch] = useContext(Context);

    const { cardId, front, back } = props;
    return <Box className='slide' sx={{
        position: 'absolute',
        fontWeight: '700',
        color: 'rgba(255,255,255,0.9)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100% / 1)',
    }}>
        <div id={cardId} className='w-100'>
            <div className='cardFront d-flex flex-column justify-content-center' style={{ height: '10rem' }}>
                {front}
            </div>
            <div className='cardBack d-none position-relative' style={{ height: '10rem' }}>
                <FlipBackButton {...props}></FlipBackButton>
                {back}
                <MDBIcon
                    style={{
                        bottom: '-5px',
                        left: '50%',
                        transform: 'translateY(-50%)'
                    }}
                    icon='chevron-down' className='bounce position-absolute text-neon text-shadow-black'></MDBIcon>
            </div>
        </div>
    </Box>
}

const SliderButton = (props) => {
    const { type } = props;
    const left = type == 'prev';
    return <Box sx={{
        fontSize: '1.5rem',
        position: 'absolute',
        left: left ? '-1.5rem' : 'auto',
        right: !left ? '-1.5rem' : 'auto',
        top: '50%',
        opacity: 0.2,
        transform: 'translateY(-50%)'
    }}>
        <MDBIcon id={`${left ? 'prev' : 'next'}Button`} icon={`chevron-${left ? 'left' : 'right'}`}></MDBIcon>
    </Box>
}
export default LobbySlider