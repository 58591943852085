import React, { Fragment } from 'react'
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { MDBIcon } from 'mdb-react-ui-kit';
import ThemeButton from './ThemeButton';

const ThemeSelect = (props) => {
    const { label, value, setValue, onChange, labelClass, options, children,
        start, startClassName, startStyle,
        end, endClassName, endStyle,
        className, style,
        selectClassName, inputStyle,
        type, placeholder, multiple, ...extra } = props;

    const optionsList = options || children;

    return <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
            <Fragment>
                <Box
                    className={'text-white h-100 w-100 cursor-pointer position-relative d-flex align-items-center justify-content-between form-control bg-transparent p-0 ' + className}
                    sx={{
                        minHeight: 'calc(1.75rem + 0.33rem + 2px)',
                        height: 'calc(1.75rem + 0.33rem + 2px)',
                        border: '1px solid #555',
                        padding: '0.25rem',
                        fontSize: '0.8rem',
                        ...style
                    }} variant="contained" {...bindTrigger(popupState)}>
                    {label && <Typography className={labelClass + ' rounded-pill position-absolute px-1 mx-2 text-neon'} sx={{
                        fontSize: ['0.6rem'],
                        top: ['-0.3rem'],
                        lineHeight: ['0.7rem'],
                        backgroundColor: '#000',
                        zIndex: 4
                    }}>{label}</Typography>}
                    <span className='overflow-hidden'>
                        {optionsList ?
                            <>
                                {optionsList.length && optionsList.filter(option => option.props.value == value) ? <span>{optionsList.filter(option => option.props.value == value)[0] && optionsList.filter(option => option.props.value == value)[0].props.children}</span> : <></>}
                                {(optionsList.length && optionsList.filter(option => option.props.value == value).length == 0) &&
                                    optionsList[0].props.children}
                            </>
                            :
                            <>
                                {children.length && children.filter(option => option.props.value == value) ? <span>{children.filter(option => option.props.value == value)[0] && children.filter(option => option.props.value == value)[0].props.children}</span> : <></>}
                                {(children.length && children.filter(option => option.props.value == value).length == 0) &&
                                    children[0].props.children}
                            </>
                        }


                    </span>
                    <MDBIcon icon='chevron-down' className='px-1'></MDBIcon>
                </Box>
                <Menu sx={{
                    '& .MuiPaper-root': {
                        background: '#191919 !important',
                        minWidth: '150px',
                        boxShadow: '0 0 10px #26ddff',
                        borderRadius: '6px !important'
                    },
                    '& .MuiPaper-root ul': {
                        padding: 0
                    },
                    '& .MuiPaper-root ul li': {
                        padding: '2px 0px',
                        fontSize: '0.7rem'
                    }
                }} {...bindMenu(popupState)}>
                    {optionsList.length && optionsList.map((option, i) => <MenuItem
                        sx={{
                            // minHeight: '2rem !important',
                            cursor: option.props.disabled ? 'default' : 'pointer',
                        }}
                        key={i}
                        className={'' + ' ' + (i % 2 ? 'bg-black' : '')}
                        onClick={() => {
                            if (option.props.disabled) return;
                            onChange({ target: { value: option.props.value } });
                            if (!multiple) { popupState.close(); }
                        }}
                        value={option.props.value}>
                        <div className='w-100 d-flex justify-content-between align-items-center'>
                            {option.props.children}
                            {option.props.value == value && <MDBIcon icon='check' className='text-neon text-shadow-neon me-1 pe-1 small'></MDBIcon>}
                        </div>
                    </MenuItem>)}
                    {
                        multiple &&
                        <Box className='py-1 text-center'>
                            <ThemeButton onClick={() => popupState.close()} variant='outlined' size='small' className='px-3 py-1'>OK</ThemeButton>
                        </Box>
                    }
                </Menu>
            </Fragment>
        )}
    </PopupState>
}


export default ThemeSelect
