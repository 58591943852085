import React, { useState, useContext, useEffect } from 'react';
import { MDBIcon, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import axios from 'axios';
import validator from 'validator';
import ThemeButton from '../components/ThemeButton'
import { countries, loginSuccess, setActive } from '../../helpers/publicHelper';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import ThemePhoneInput from '../components/ThemePhoneInput/ThemePhoneInput';
import { Alert, Checkbox, FormControlLabel, Typography } from '@mui/material';
import TelegramLogin from './TelegramLogin';
import { Context } from '../../store';
import PIN from '../components/PIN';
import ThemeInput from '../components/ThemeInput';
import moment from 'moment';
import { Popover } from 'react-tiny-popover'
import Termsofuse from '../../pages/Termsofuse';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import { Box } from '@mui/system';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TermsAndConditions from '../footer/TermsAndConditions';

const getCountryPrefixByCountryCode = (countryCode) => {
    const country = countries.find(x => x.code == countryCode);
    if (country && country.phone) {
        return country.phone
    } else {
        return false
    }
}

const SignUp = (props) => {
    const [state, dispatch] = useContext(Context);

    const [signUpState, setSignUpState] = useState({
        _id: '',
        accept: false,
        countryCode: state.countryCode ? getCountryPrefixByCountryCode(state.countryCode) : '1',
        mobile: '',
        email: '',
        password: '',
        mode: 'email-mobile',
        otp: '',
        twoFA: '',
        code: '', // SignUp verify code
        waiting: false,
        emailCode: '',
        mobileCode: '',
        verificationCode: '',
        emailEdit: true,
        mobileEdit: true,
        verificationMode: 'email-mobile',
        refId: new URL(window.location.href).searchParams.get('refId'),
        username: '',
        birthDate: Date.now() - (18.02 * 31536000000)
    })

    return (<>
        {/* <UFOTabs /> */}
        <MDBRow className='m-0'>
            <MDBCol size='12' className='text-centerdanger'>
                {signUpState.mode == 'telegram' && <TelegramLogin></TelegramLogin>}
                {signUpState.mode == 'email-mobile' || signUpState.mode == 'verification' ? <SignUpForm signUpState={signUpState} setSignUpState={setSignUpState}></SignUpForm> : <></>}
            </MDBCol>
        </MDBRow>
    </>
    )
}

const SignUpForm = (props) => {
    const { signUpState, setSignUpState } = props;
    const [state, dispatch] = useContext(Context);
    const { t } = state;
    const [showPassword, setShowPassword] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const handleSetActive = (activeObj) => {
        setSignUpState({
            ...signUpState,
            ...activeObj
        })
        // setActive(state, dispatch, {
        //     signUp: {
        //         ...signUpState,
        //         ...activeObj
        //     }
        // });
    }

    const signUpRequest = () => {
        var postData = _.pick(signUpState, ['birthDate', 'username', 'mobile', 'email', 'password', 'mode', 'refId']);
        console.log('moment:::', moment('2022-06-04', true).isValid())
        if (postData.username.length < 6) return enqueueSnackbar('Username must be 6 character or more.', { variant: 'error', autoHideDuration: 2500 })
        postData.mobile = {
            prefix: signUpState.countryCode,
            number: postData.mobile
        };

        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        // let postData = _.pick(loginValues, ['loginMode', 'login', 'password', 'twofa']);
                        const headers = { captcha };
                        axios.post(process.env.REACT_APP_API_HOST + '/api/users/register', postData, { headers })
                            .then((res) => {
                                res = res.data;
                                if (res.success) {
                                    enqueueSnackbar(res.message, { variant: 'success', });
                                    handleSetActive({
                                        _id: res.result,
                                        mode: 'verification',
                                        waiting: false
                                    })
                                } else {
                                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2500 })
                                }
                            })
                            .catch((err) => {
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }


    }

    const validateSignUpForm = () => {
        console.log(signUpState)
        if (!signUpState.accept) {
            return enqueueSnackbar('Accept the terms.', {
                variant: 'error',
            })  // setValues({ ...values, message: { style: 'danger', text: 'Accept the terms.' } })
        }
        if (signUpState.mode == 'email' && !validator.isEmail(signUpState.email)) {
            return enqueueSnackbar('Invalid Email', {
                variant: 'error',
            }) // setValues({ ...values, message: { style: 'danger', text: 'Invalid Email' } })
        }
        if (signUpState.mode == 'mobile' && !(validator.isMobilePhone(signUpState.mobile) && validator.isLength(signUpState.mobile, { min: 8, max: 10 }))) {
            return enqueueSnackbar('Invalid Mobile', {
                variant: 'error',
            })
        }
        if (signUpState.mode == 'email-mobile' && !validator.isEmail(signUpState.email)) {
            return enqueueSnackbar('Invalid Email', {
                variant: 'error',
            }) // setValues({ ...values, message: { style: 'danger', text: 'Invalid Email' } })
        }
        if (signUpState.mode == 'email-mobile' && !(validator.isMobilePhone(signUpState.mobile) && validator.isLength(signUpState.mobile, { min: 8, max: 10 }))) {
            return enqueueSnackbar('Invalid Mobile', {
                variant: 'error',
            })
        }
        if (signUpState.password.trim() == '') {
            enqueueSnackbar('Enter Password.', {
                variant: 'warning',
            })
            return // setValues({ ...values, password: '', mode: 'password', message: { style: 'danger', text: '' } })
        }
        if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\d\D]{8,30}$/.test(signUpState.password))) {
            enqueueSnackbar('Password length should be 8 letters or more.', {
                variant: 'warning',
            })
        }
        // if (!validator.isLength(signUpState.password, { min: 8, max: 30 })) {
        //     enqueueSnackbar('Password length should be 8 letters or more.', {
        //         variant: 'warning',
        //     })
        //     return //setValues({ ...values, message: { style: 'danger', text: 'Password length should be 8 letters or more.' } })
        // }
        console.log(signUpState)
        signUpRequest()
    }

    const handleChange = (e) => {
        console.log(e)
        if (e.id) {
            handleSetActive({
                [e.id]: e.value
            })
        }
    }

    const verify = () => {
        var postData = _.pick(signUpState, ['email',
            //  'emailCode', 'mobileCode',
            'verificationCode']);
        postData.mobile = {
            prefix: signUpState.countryCode,
            number: signUpState.mobile
        };
        postData.mode = 'email-mobile';

        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        // let postData = _.pick(loginValues, ['loginMode', 'login', 'password', 'twofa']);
                        const headers = { captcha };
                        axios.post(process.env.REACT_APP_API_HOST + '/api/users/registerverify', postData, { headers })
                            .then((res) => {
                                const token = res.headers['x-auth-token'];
                                res = res.data;
                                if (res.success) {
                                    enqueueSnackbar(res.message, { variant: 'success' })
                                    if (res.code == 200) {
                                        handleSetActive({
                                            mode: 'email-mobile',
                                            code: ''
                                        })
                                        loginSuccess(state, dispatch, res.result, token)
                                    }
                                } else {
                                    if (res.code == 43) {
                                        enqueueSnackbar(res.message, { variant: 'error' })
                                    } else if (res.code == 44) {
                                        enqueueSnackbar(res.message, { variant: 'error' })
                                    } else {
                                        enqueueSnackbar(res.message, { variant: 'error' })
                                    }
                                }
                            })
                            .catch((err) => {
                                enqueueSnackbar('Failed!!', { variant: 'error' })
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }


    }

    return (<>
        <form id='SignUpForm' autoComplete="off" noValidate='noValidate' className='text-center pb-3' onSubmit={e => { e.preventDefault(); validateSignUpForm() }}>
            {(signUpState.mode == 'email-mobile' || signUpState.mode == 'verification') &&
                <>
                    <ThemeInput
                        className={`mb-3 ${signUpState.mode == 'verification' ? 'd-none' : ''}`}
                        inputClassName='-rounded-pill overflow-hidden bg-black'
                        // disabled={!signUpState.username}
                        label={
                            <span style={{ color: '#fff' }}>Username
                                <span className='text-danger'> *</span>
                            </span>
                        } labelStyle={{ background: '#121212' }}
                        value={signUpState.username}
                        // end={!signUpState.emailEdit ? <small className={'text-neon cursor-pointer'} style={{ fontSize: '0.7rem' }}>Edit</small> : ''}
                        onChange={e => handleSetActive({
                            username: e.target.value
                        })}>
                    </ThemeInput>
                    <ThemeInput
                        className={`mb-3 ${signUpState.mode == 'verification' ? 'd-none' : ''}`}
                        inputClassName='-rounded-pill overflow-hidden bg-black'
                        disabled={!signUpState.emailEdit}
                        label={<span style={{ color: '#fff' }}>Email<span className='text-danger'> *</span></span>}
                        value={signUpState.email}
                        end={!signUpState.emailEdit ? <small className={'text-neon cursor-pointer'} style={{ fontSize: '0.7rem' }}>Edit</small> : ''}
                        onChange={e => handleSetActive({
                            email: e.target.value
                        })}></ThemeInput>
                    {/* <MDBRow className={`m-0 ${signUpState.mode !== 'verification' ? 'd-none' : ''}`}>
                        <MDBCol size='12'>
                            <PIN name='emailCode' handlechange={handleChange} type='number' fields={6} />
                        </MDBCol>
                    </MDBRow> */}

                    <div className={`mb-3 ${signUpState.mode == 'verification' ? 'd-none' : ''}`}>
                        <ThemePhoneInput
                            label={<span style={{ color: '#fff' }}>Phone<span className='text-danger'> *</span></span>}
                            value={{ phone: signUpState.mobile, countryCode: signUpState.countryCode }}
                            onChange={e => { handleSetActive({ mobile: e.phone, countryCode: e.countryCode }) }}>
                        </ThemePhoneInput>
                    </div>

                    <MDBRow className={`m-0 mt-3 ${signUpState.mode !== 'verification' ? 'd-none' : ''}`}>
                        <MDBCol size='12'>
                            {/* <PIN name='mobileCode' handlechange={handleChange} type='number' fields={6} /> */}
                            <PIN name='verificationCode' handlechange={handleChange} type='number' fields={6} />
                        </MDBCol>
                        <MDBCol size='12' className={'pt-3 '}>
                            <Alert icon={false} sx={{ fontSize: '0.7rem' }} variant="outlined" severity="success">
                                We have sent an OTP to your email and your mobile. Please verify at least one of those to complete your registration.
                            </Alert>
                        </MDBCol>
                    </MDBRow>


                    <MDBRow className={`my-2 ${signUpState.mode == 'verification' ? 'd-none' : ''}`}>
                        <MDBCol size='12'>
                            <span className={"d-block w-100 -bg-black rounded-pill position-relative noArrow mt-2 py-1 "} >
                                <Typography component='span' className={'rounded-1 position-absolute mx-2 '} sx={{
                                    fontSize: ['0.6rem'],
                                    top: ['-0.6rem'],
                                    lineHeight: ['0.7rem'],
                                    backgroundColor: '#121212',
                                    zIndex: 4,
                                    left: 0,
                                    color: '#fff',
                                    padding: '1px 3px'
                                }}
                                >Date of Birth</Typography>
                                <div className='d-flex justify-content-start justify-content-md-between'>
                                    <DateSelector signUpState={signUpState} setSignUpState={setSignUpState} mode='year'></DateSelector>
                                    <DateSelector signUpState={signUpState} setSignUpState={setSignUpState} mode='month'></DateSelector>
                                    <DateSelector signUpState={signUpState} setSignUpState={setSignUpState} mode='date'></DateSelector>
                                </div>
                            </span>
                        </MDBCol>
                    </MDBRow>

                    <div className={`position-relative mt-3 ${signUpState.mode == 'verification' ? 'd-none' : ''}`}>
                        <ThemeInput
                            inputStyle={{ background: '#000' }}
                            endStyle={{ background: '#000' }}
                            inputClassName='bg-black'
                            label={<span style={{ color: '#fff' }}>Password<span className='text-danger'> *</span></span>}
                            labelStyle={{ background: '#121212' }}
                            value={signUpState.password}
                            type={showPassword ? 'text' : 'password'}
                            end={<MDBIcon onClick={() => setShowPassword(!showPassword)} className='text-neon cursor-pointer' style={{}} icon={showPassword ? 'eye' : 'eye-slash'}></MDBIcon>}
                            onChange={e => handleSetActive({ password: e.target.value })}>
                        </ThemeInput>
                        <Box className='pt-1 d-flex justify-content-between' sx={{ fontSize: '0.5rem', textAlign: 'start', '& svg': { width: '0.7rem', height: '0.7rem' } }}>
                            <Box className=' w-50'>
                                <Box className={/^(?=.*[A-Z])[\d\D]{1,30}$/.test(signUpState.password) ? 'text-success' : 'text-danger'}>
                                    {/^(?=.*[A-Z])[\d\D]{1,30}$/.test(signUpState.password) ? <TaskAltIcon /> : <HighlightOffIcon />} At least one capital letter
                                </Box>
                                <Box className={/^(?=.*[a-z])[\d\D]{1,30}$/.test(signUpState.password) ? 'text-success' : 'text-danger'}>
                                    {/^(?=.*[a-z])[\d\D]{1,30}$/.test(signUpState.password) ? <TaskAltIcon /> : <HighlightOffIcon />} At least one lower case letter
                                </Box>
                            </Box>
                            <Box className='text-start'>
                                <Box className={/^(?=.*\d)[\d\D]{1,30}$/.test(signUpState.password) ? 'text-success' : 'text-danger'}>
                                    {/^(?=.*\d)[\d\D]{1,30}$/.test(signUpState.password) ? <TaskAltIcon /> : <HighlightOffIcon />} At least one number
                                </Box>
                                <Box className={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\d\D]{8,30}$/.test(signUpState.password) ? 'text-success' : 'text-danger'}>
                                    {/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\d\D]{8,30}$/.test(signUpState.password) ? <TaskAltIcon /> : <HighlightOffIcon />} Between 8-30 Characters
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </>
            }

            {signUpState.mode == 'email-mobile' &&
                <MDBRow>
                    {signUpState.mode == 'email-mobile' || signUpState.mode == 'mobile' ?
                        <MDBCol size='12' className='pt-1 mt-1' style={{ color: '#555', fontSize: '0.7rem', textAlign: 'justify' }}>
                            <p className='small text-justify p-0 m-0'>
                                By clicking this button you confirm that you have read and agree to the
                                <span onClick={() => {
                                    setActive(state, dispatch, {
                                        modal: {
                                            title: 'Terms of Use',
                                            content: <Termsofuse />,
                                            open: true
                                        }
                                    })
                                }} className='text-neon d-inline cursor-pointer'>

                                    <TermsAndConditions  selectedIndex={1} />

                                </span>
                                and
                                <span onClick={() => {
                                    setActive(state, dispatch, {
                                        modal: {
                                            title: 'Privacy Policy',
                                            content: <PrivacyPolicy />,
                                            open: true
                                        }
                                    })
                                }} className='text-neon d-inline cursor-pointer'>
                                    <TermsAndConditions selectedIndex={2} />

                                </span>
                                of the company, consent to the processing of your personal information and confirm that you are of legal age.
                            </p>
                        </MDBCol> : <></>
                    }
                    <MDBCol size='8' className='ps-2 pe-0 text-start'>
                        <FormControlLabel
                            label={<small className='text-neon text-shadow-neon' style={{ fontSize: '0.55rem' }}>{`Accept Terms & Conditions`}</small>}
                            onChange={e => handleSetActive({ accept: e.target.checked })}
                            control={<Checkbox
                                color='neon'
                                checked={signUpState.accept}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' } }} />} />
                    </MDBCol>
                    <MDBCol size='4' className='text-end ps-0 pt-1'>
                        <ThemeButton
                            variant={!signUpState.accept && signUpState.mode !== 'telegram' ? 'outlined' : ''}
                            disabled={!signUpState.accept && signUpState.mode !== 'telegram'} className='px-2 w-100' type='submit'>
                            {signUpState.waiting ? <MDBIcon spin icon='sync'></MDBIcon> : signUpState.mode !== 'password' ? t('Sign Up') : t('Set Password')}
                        </ThemeButton>
                    </MDBCol>
                </MDBRow>
            }

            {signUpState.mode == 'verification' &&
                <MDBRow className='mx-0 my-3'>
                    <MDBCol size='12'>
                        <ThemeButton onClick={verify} disabled={signUpState.verificationCode.length !== 6} className='px-3' type='button'>
                            {/* <ThemeButton onClick={verify} disabled={signUpState.emailCode.length !== 6 || signUpState.mobileCode.length !== 6} variant='outlined' type='button'> */}
                            Verify
                        </ThemeButton>
                    </MDBCol>
                </MDBRow>
            }

        </form >

    </>)
}

const DateSelector = (props) => {
    // const [state, dispatch] = useContext(Context);
    const { mode, signUpState, setSignUpState } = props;
    const { birthDate } = signUpState;

    let defaultOptions = [];
    if (mode == 'year') { defaultOptions = Array.from({ length: 105 }, (_, i) => parseInt(moment(birthDate).get('year')) - i) }
    else if (mode == 'month') { defaultOptions = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] }
    else { defaultOptions = Array.from({ length: 31 }, (_, i) => i + 1) }

    const [values, setValues] = useState({
        options: defaultOptions,
        current: moment(birthDate).format(mode === 'year' ? 'YYYY' : mode === 'month' ? 'MMMM' : 'DD')
    })

    useEffect(() => {
        setValues({
            ...values,
            current: moment(birthDate).format(mode === 'year' ? 'YYYY' : mode === 'month' ? 'MMMM' : 'DD')
        })
    }, [birthDate])

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    // const buttonRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            setIsPopoverOpen(false);
        }, 200);
    }, [mode])

    const setDate = (num) => {
        const currentBirthDate = signUpState.birthDate;
        setSignUpState({
            ...signUpState,
            birthDate: moment(currentBirthDate).set(mode, num).valueOf()
        })
        setIsPopoverOpen(false)
    }


    return (<Popover
        containerStyle={{
            zIndex: 999999,

        }}
        isOpen={isPopoverOpen}
        reposition={false}
        onClickOutside={() => setIsPopoverOpen(false)}
        positions={['bottom', 'right', 'bottom']} // preferred positions by priority
        align={'start'}
        content={
            <div
                style={{
                    height: '19rem'
                }}
                className='bg-black overflow-scroll noScrollbar -rounded-5 border border-neon box-shadow-neon'>
                <div className='py-2 px-3 text-center'>
                    {values.options.map((num, i) =>
                        <div
                            key={i}
                            onClick={() => setDate(num)}
                            className={`${num == values.current ? '-bg-neon text-neon fw-bold' : ''}
                        text-hover-neon cursor-pointer small border-bottom- border-neon- py-1`}>{num}</div>
                    )}
                </div>
            </div >
        }>
        <span
            onClick={e => setIsPopoverOpen(!isPopoverOpen)}
            style={{
                border: '1px solid #555',
                width: mode == 'year' ? '30%' : mode == 'date' ? '30%' : '38%'
            }}
            className={`px-md-1 ${mode == 'year' ? 'me-1' : mode == 'date' ? 'ms-1' : ''} py-1 d-inline-block bg-black box-shadow-none rounded-1`}>
            <Typography className='d-flex justify-content-between align-items-center w-100' typography={['caption', 'caption']}>
                <span className='ps-1'>
                    {values.current}
                </span>
                <MDBIcon className='small me-1 opacity-40' icon='chevron-down'></MDBIcon>
            </Typography>
        </span>
    </Popover>
    )
}


export default SignUp
