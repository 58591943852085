import React, { useContext } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';
import { Context } from '../../../store';
import { useHistory } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../../../helpers/publicHelper';
import { listItemIconSX } from '../listItemIconSX';

export const LogoutItem = (props) => {
    const [state, dispatch] = useContext(Context);
    const history = useHistory();
    return <ListItem
        onClick={() => logout(state, dispatch, history)}
        disablePadding sx={{ display: 'block' }} {...props}>
        <ListItemButton className='py-0 text-danger'>
            <ListItemIcon sx={listItemIconSX}>
                <LogoutIcon className='text-danger' />
            </ListItemIcon>
            <ListItemText primary='Logout' primaryTypographyProps={{ fontSize: ['0.7rem', '0.9rem'] }} />
        </ListItemButton>
    </ListItem>;
};
