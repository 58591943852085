
import BTC from 'cryptocurrency-icons/128/color/btc.png'
import LTC from 'cryptocurrency-icons/128/color/ltc.png'
import ETH from 'cryptocurrency-icons/128/color/eth.png'
import TRX from 'cryptocurrency-icons/128/color/trx.png'
import USD from 'cryptocurrency-icons/128/color/usd.png'
import EUR from 'cryptocurrency-icons/128/color/eur.png'
import CNY from 'cryptocurrency-icons/128/color/cny.png'
import JPY from 'cryptocurrency-icons/128/color/jpy.png'
import GBP from 'cryptocurrency-icons/128/color/gbp.png'
import RUB from 'cryptocurrency-icons/128/color/rub.png'
import DOGE from 'cryptocurrency-icons/128/color/doge.png'
import USDT from 'cryptocurrency-icons/128/color/usdt.png'

// Available Currencies
const currencyList = ['USD', 'USDT', 'BTC', 'ETH', 'DOGE', 'TRX'];

// Currency Detail
const currencyDetail = {
    BTC: {
        title: 'Bitcoin',
        symbol: 'BTC',
        symbol2: 'BTC',
        format: '0,0.00[000000]',
        icon: BTC,
        type: 'crypto',
    },
    LTC: {
        title: 'Litecoin',
        symbol: 'LTC',
        symbol2: 'LTC',
        format: '0,0.00[000000]',
        icon: LTC,
        type: 'crypto',
    },
    ETH: {
        title: 'Ethereum',
        symbol: 'ETH',
        symbol2: 'ETH',
        format: '0,0.00[0000]',
        icon: ETH,
        type: 'crypto',
    },
    TRX: {
        title: 'Tron',
        symbol: 'TRX',
        symbol2: 'TRX',
        format: '0,0.00',
        icon: TRX,
        type: 'crypto',
    },
    DOGE: {
        title: 'Doge',
        symbol: 'DOGE',
        symbol2: 'DOGE',
        format: '0,0.00',
        icon: DOGE,
        type: 'crypto',
    },
    USDT: {
        title: 'Tether',
        symbol: 'USDT',
        symbol2: 'USDT',
        format: '0,0.00',
        icon: USDT,
        type: 'crypto',
    },
    USDTE: {
        title: 'Tether (ERC-20)',
        symbol: 'USDT',
        symbol2: 'USDT-E',
        format: '0,0.00',
        icon: USDT,
        type: 'crypto',
    },
    USDTT: {
        title: 'Tether (TRC-20)',
        symbol: 'USDT',
        symbol2: 'USDT-T',
        format: '0,0.00',
        icon: USDT,
        type: 'crypto',
    },
    // Fiats ***************************
    USD: {
        title: 'US Dollar',
        symbol: 'USD',
        symbol2: 'USD',
        format: '0,0.00',
        icon: USD,
        type: 'fiat',
    },
    EUR: {
        title: 'Euro',
        symbol: 'EUR',
        symbol2: 'EUR',
        format: '0,0.00',
        icon: EUR,
        type: 'fiat',
    },
    IRT: {
        title: 'تومان',
        symbol: 'IRT',
        symbol2: 'IRT',
        format: '0,0',
        icon: '/cryptoicons/irt.svg',
        type: 'fiat',
    },
    IRR: {
        title: 'ریال',
        symbol: 'IRR',
        symbol2: 'IRR',
        format: '0,0',
        icon: '/cryptoicons/irr.svg',
        type: 'fiat',
    },
    CNY: {
        title: 'Chinese Yuan',
        symbol: 'CNY',
        symbol2: 'CNY',
        format: '0,0.00',
        icon: CNY,
        type: 'fiat',
    },
    JPY: {
        title: 'Japanese Yen',
        symbol: 'JPY',
        symbol2: 'JPY',
        format: '0,0.00',
        icon: JPY,
        type: 'fiat',
    },
    GBP: {
        title: 'Pound Sterling ',
        symbol: 'GBP',
        symbol2: 'GBP',
        format: '0,0.00',
        icon: GBP,
        type: 'fiat',
    },
    RUB: {
        title: 'Russian Ruble',
        symbol: 'RUB',
        symbol2: 'RUB',
        format: '0,0.00',
        icon: RUB,
        type: 'fiat',
    },
}

// Currency Icon
const currencyIcon = (currency) => {
    if (currencyDetail[currency]) {
        return currencyDetail[currency].icon || USD
    }
}

// Currency Format
const currencyFormat = (currency) => {
    if (currencyDetail[currency]) {
        return currencyDetail[currency].format || '0,0.00'
    }
}

export {
    currencyList,
    currencyDetail,
    currencyIcon,
    currencyFormat,
}
