import React from 'react'
import { MDBIcon } from 'mdb-react-ui-kit';
import { Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import $ from 'jquery';

const ThemeInput = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const {
        label, labelClass, labelStyle, multiline, rows,
        start, startClassName, startStyle,
        end, endClassName, endStyle,
        className, style, copyButton,
        inputClassName, inputStyle,
        type, placeholder, noFocusEffect, ...extra } = props;
    return (
        <span className={"d-block w-100 position-relative noArrow mt-2 " + className} style={style}>
            {label ? <Typography component='span' className={'themeInputLabel rounded-1 position-absolute mx-2 ' + labelClass} sx={{
                fontSize: ['0.6rem'],
                top: ['-0.3rem'],
                lineHeight: ['0.7rem'],
                backgroundColor: '#000',
                zIndex: 4,
                left: 0,
                color: '#26ddff',
                padding: '1px 3px'
            }}
                style={labelStyle}
            >{label}</Typography> : <></>}
            <span className='input-group'>
                {start ? <span style={{ border: '1px solid #555', ...startStyle }} className={"input-group-text " + startClassName}>{start}</span> : <></>}
                {multiline ?
                    <textarea {...extra} type={type ? type : 'text'}
                        rows={rows || 4}
                        style={{ height: 'auto', color: '#fff', fontSize: '0.8rem', border: '1px solid #555', padding: '0.25rem', boxShadow: 'none', ...inputStyle }}
                        onFocus={(e) => {
                            if (noFocusEffect) return
                            e.target.classList.add('border-neon', 'box-shadow-neon')
                        }}
                        onBlur={(e) => {
                            if (noFocusEffect) return
                            e.target.classList.remove('border-neon', 'box-shadow-neon')
                        }}
                        className={"form-control bg-transparent " + inputClassName || ''} placeholder={placeholder} />
                    :
                    <input {...extra} type={type ? type : 'text'}
                        style={{ border: '1px solid #555', fontSize: '0.8rem', color: '#fff', padding: '0.25rem', boxShadow: 'none', ...inputStyle }}
                        onFocus={(e) => {
                            if (noFocusEffect) return
                            $(e.currentTarget).parent().parent().find('.themeInputLabel *').addClass('text-neon');
                            e.target.classList.add('border-neon', 'box-shadow-neon')
                        }}
                        onBlur={(e) => {
                            if (noFocusEffect) return
                            $(e.currentTarget).parent().parent().find('.themeInputLabel *').removeClass('text-neon');
                            e.target.classList.remove('border-neon', 'box-shadow-neon')
                        }}
                        className={"form-control bg-transparent " + inputClassName || ''} placeholder={placeholder} />
                }
                {end ? <span style={{ border: '1px solid #555', ...endStyle }} className={"input-group-text " + endClassName}>{end}</span> : <></>}
                {copyButton ? <span style={{ border: '1px solid #555', fontSize: '0.8rem', ...endStyle }} className={"input-group-text px-2 text-warning " + endClassName}>
                    <CopyToClipboard text={props.value}
                        className='cursor-pointer'
                        onCopy={() => {
                            enqueueSnackbar('Copied', {
                                variant: 'success', autoHideDuration: 1500
                            })
                        }}>
                        <MDBIcon icon='copy'></MDBIcon>
                    </CopyToClipboard>
                </span> : <></>}
            </span>
        </span>)
}

export default ThemeInput
