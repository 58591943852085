import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import ModalContent from '../modal/ModalContent';
import PageModal from './PageModal';
import CustomListItem from './CustomListItem';

const PageItem = (props) => {
    const { title, _id, pages } = props;
    const [open, setOpen] = useState(false);

    return <>
        <CustomListItem onClick={() => setOpen(true)} {...props} text={title} />

        <Modal
            open={open}
            disableScrollLock={false}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: ['90vw', '80vw'],
                height: ['70vh'],
            }}>
                <ModalContent
                    setOpen={setOpen}
                    header={<Box className='ps-2 d-flex w-100 justify-content-start align-items-center position-relative'>
                        <span>
                            Legal
                        </span>
                        
                    </Box>}
                >
                    <PageModal pages={pages} selectedId={_id} />
                </ModalContent>
            </Box>
        </Modal>
    </>;
}

export default PageItem
