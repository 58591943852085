import React, { useState, useContext } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { getBearer, getStatus, kycDetails, setActive } from '../../../helpers/publicHelper';
import FileUpload from '../../../components/FileUpload';
import { Context } from '../../../store';


const KYCDoc = (props) => {
    const [state, dispatch] = useContext(Context);
    const { name } = props;
    const title = kycDetails(name).title;
    const doc = state.user.kyc.documents[name];
    const { enqueueSnackbar } = useSnackbar();

    const handleSetActive = (activeObj) => {
        setActive(state, dispatch, {
            kyc: {
                ...state.active.kyc,
                documents: {
                    ...state.active.kyc.documents,
                    ...activeObj
                }
            }
        });
    }

    const uploadSuccess = (doc, file) => {
        console.log(file);
        if (file.length < 1) return enqueueSnackbar('UploadError', {
            variant: 'error', autoHideDuration: 3000
        });
        axios.post(state.active.host + '/api/users/kyc/documents/', {
            name: doc,
            file: file[0]._id
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then(res => {
                res = res.data;
                if (res.success) {
                    enqueueSnackbar(kycDetails(doc).title + ' uploaded successfully', {
                        variant: 'success', autoHideDuration: 3000
                    })
                }

            })
            .catch((err) => {
                console.log(err)
            })
            .then(() => {
                handleSetActive({ [name]: { waiting: false } })
            })
    }

    const uploadError = (message) => {
        enqueueSnackbar(message, {
            variant: 'error', autoHideDuration: 3000
        })
    }

    return <><ListItem
        secondaryAction={<RightButtons uploadSuccess={uploadSuccess} uploadError={uploadError} name={name}></RightButtons>}>
        <ListItemAvatar>
            <Avatar variant="square" className='rounded-2 box-shadow-neon p-1 bg-transparent'>
                {
                    doc && doc.url ?
                        <img src={doc.url} className='img-fluid' />
                        :
                        // <CustomIcon fill='#ffffff' icon='im-moon2'></CustomIcon>
                        <MDBIcon className='text-neon' icon='image'></MDBIcon>
                }
                {
                    doc && doc.status == 'confirmed' ?
                        <></>
                        :
                        <FileUpload
                            style={{ top: 0, left: 0 }}
                            className='opacity-0 py-5 cursor-pointer position-absolute'
                            name={''}
                            onChange={() => handleSetActive({ [name]: { waiting: true } })}
                            onUploaded={(file) => uploadSuccess(name, file)}
                            onError={uploadError}
                        ></FileUpload>
                }
            </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={<span>{title}{(state.user.kyc.isRequired && state.user.kyc.isRequired[name]) ? <span className='text-danger ps-1'>*</span> : ''}</span>}
            about='asd'
            className='pe-3'
            secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
            secondary={
                doc && state.active.kyc.documents && state.active.kyc.documents[name] ?
                    <small className={`text-capitalize text-${state.active.kyc.documents[name].waiting ? 'success' : getStatus(doc.status).color}`}>{state.active.kyc.documents[name].waiting ? 'uploading ...' : doc.status}</small>
                    :
                    <small>
                        {state.user.kyc.isRequired && state.user.kyc.isRequired[name] ?
                            <span className={`text-capitalize text-neon text-shadow-neon`}>Waiting</span>
                            :
                            'No File'
                        }
                    </small>}
        />

    </ListItem>
        {
            doc && doc.description && doc.status == 'rejected' ?
                <ListItem>
                    <small style={{ fontSize: '0.6rem' }}>
                        <strong className='text-danger'>Details: </strong>
                        <small className='opacity-60'>{doc.description}</small>
                    </small>
                </ListItem>
                :
                <></>
        }
    </>
}

const RightButtons = (props) => {
    const [state, dispatch] = useContext(Context);
    const { name, uploadError, uploadSuccess } = props;
    const status = state.user.kyc.documents[name] ? state.user.kyc.documents[name].status : '';
    return (<>
        {
            status == 'confirmed' ?
                <>
                    <Avatar className={`bg-transparent box-shadow-${getStatus(status || 'neon').color} border border-${getStatus(status || 'neon').color}`}>
                        <MDBIcon icon='check' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon>
                    </Avatar>
                </>
                :
                <Avatar className={`bg-transparent box-shadow-${getStatus(status || 'neon').color} border border-${getStatus(status || 'neon').color}`}>
                    <MDBIcon spin={status == 'pending'} icon={status == 'pending' ? 'spinner' : 'upload'} className={'text-' + getStatus(status).color} style={{ fontSize: '0.8rem' }}></MDBIcon>
                    <FileUpload
                        style={{ top: 0, left: 0 }}
                        className='opacity-0 py-5 cursor-pointer position-absolute'
                        name={''}
                        onUploaded={(file) => uploadSuccess(name, file)}
                        onError={uploadError}
                    ></FileUpload>
                </Avatar>
        }
    </>)
}


export default KYCDoc
