import React, { createContext, useReducer, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from 'react-i18next';
import Reducer from './reducer';

const initialState = {
    recaptchaSiteKey: '6LdsCDgcAAAAAGSl2eIx_SqtsUGLDIb45C3pT1-Y',
    config: {
        api: {},
        countryAccess: false,
        currencyList: ['USDT', 'BTC', 'ETH', 'DOGE', 'TRX', 'USD'],
        fiatList: ['USD', 'EUR', 'JPY', 'BGP', 'RUB'],
        init: false,
        kycList: ['ppt', 'bc', 'id', 'pp'],
        withdrawFees: {
            BTC: 0,
            DOGE: 0,
            TRX: 0,
            ETH: 0,
            'USDT-TRX': 0,
            'USDT-ETH': 0,
        }
    },
    user: {
        kyc: { documents: {}, isRequired: { phone: true, email: true } },
        profile: {},
        settings: {},
        transactions: [],
        wallet: {
            USDT: {},
            USD: {}
        },
    },
    login: false,
    notification: {
        verification: 0,
        assist: 0
    },
    active: {
        host: process.env.REACT_APP_API_HOST,
        env: process.env.REACT_APP_API_HOST.indexOf('stage') > -1 ? 'stage' : 'prod',
        height: window.innerHeight - 65,
        assistOpen: false,
        checkTelegram: false,
        backdrop: false,
        showHeader: true,
        showHeaderArrow: true,
        loginRegisterTabs: 0,
        layoutDrawer: {
            right: false,
            left: false
        },
        footer: true,
        currencyUpdating: false,
        currency: 'USD',
        blockchain: 'TRX',
        fiat: 'USD',
        showFiat: true,
        rightTab: 0,
        modal: { title: '', content: '', open: false },
        withdrawValue: 0,
        withdrawAddress: '',
        leftDrawer: {
            casino: true,
            sport: true,
            slots: false,
            liveCasino: false,
            // digitain: false
        },
        // signUp: {
        //     _id: '',
        //     accept: false,
        //     countryCode: '1',
        //     mobile: '',
        //     email: '',
        //     password: '',
        //     mode: 'email-mobile',
        //     otp: '',
        //     twoFA: '',
        //     code: '', // SignUp verify code
        //     waiting: false,
        //     emailCode: '',
        //     mobileCode: '',
        //     emailEdit: true,
        //     mobileEdit: true,
        //     verificationMode: 'email-mobile',
        //     refId: '',
        //     username: '',
        //     birthDate: ''// Date.now() - (18 * 31536000000)
        // },
        kyc: {
            mobile: {
                mode: '',
                countryCode: '',
                number: '',
                code: '',
                waiting: false
            },
            email: {
                mode: '',
                address: '',
                code: '',
                waiting: false
            },
            address: {
                mode: '',
                line1: '',
                line2: '',
                city: '',
                country: '',
                waiting: false
            },
            documents: {
                ppt: { waiting: false },
                bc: { waiting: false },
                pp: { waiting: false },
                id: { waiting: false },

            }
        }
    },
    rates: {},
};

const Store = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies(['bearer']);
    const [state, dispatch] = useReducer(Reducer, {
        ...initialState,
        t: t,
        i18n: i18n,
        cookies: cookies,
        setCookie: setCookie,
        removeCookie: removeCookie
    });

    const [contextGameList, setContextGameList] = useState({
        count: 0,
        games: [],
        dga: []
    })
    return (
        <Context.Provider value={[state, dispatch]}>
            <GameListContext.Provider value={[contextGameList, setContextGameList]}>
                {children}
            </GameListContext.Provider>
        </Context.Provider >
    )
};

export const Context = createContext(initialState);
export const GameListContext = createContext({
    count: 0,
    games: []
});

export const store = {};
export default Store;
