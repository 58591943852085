import React, { useContext } from 'react'
import { Context } from '../../store';
import Footer from '../Footer';
import LobbyTopCarousel from './LobbyTopCarousel';
import ProviderLogos from './ProviderLogos';
import LobbyGamesCarousel from './LobbyGamesCarousel';
import LobbyCryptoLogos from './LobbyCryptoLogos';
import LobbySlider from './LobbySlider';
import LobbySliderLogin from './LobbySliderLogin';

const Lobby = () => {
    const [state, dispatch] = useContext(Context);
    return (<>
        <div id='LobbyWrapper' className="noScrollbar text-center" style={{
            minHeight: '60vh',
            // maxHeight: '60vh',
            width: '100vw',
            overflow: 'hidden'
        }}>
            {
                state.config.init &&
                <div className='position-relative'>
                    {/* <DeviceSize></DeviceSize> */}
                    <LobbyTopCarousel />
                    <ProviderLogos />
                    {!state.login ? <LobbySlider /> : <LobbySliderLogin />}
                    <LobbyCryptoLogos direction='left'></LobbyCryptoLogos>
                    <LobbyGamesCarousel id='CarouserlGames2' direction='right' provider='all' type='all' mode='live' limit={20}></LobbyGamesCarousel>
                    <LobbyGamesCarousel id='CarouserlGames1' direction='left' provider='all' type='all' mode='all' limit={20}></LobbyGamesCarousel>
                    <LobbyGamesCarousel id='CarouserlGames4' direction='right' provider='netent' type='all' mode='all' limit={20}></LobbyGamesCarousel>
                    <LobbyGamesCarousel id='CarouserlGames3' direction='left' provider='all' type='tablegames' mode='notLive' limit={20}></LobbyGamesCarousel>
                    <Footer />
                </div>
            }
        </div>
    </>
    )
}

export default Lobby
