import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
// import $ from 'jquery'
import { Context } from '../store';
import { useSnackbar } from 'notistack';
import { getBearer, setActive } from '../helpers/publicHelper';
import LayoutProgress from '../layout/LayoutProgress';
import ThemeButton from '../layout/components/ThemeButton';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

const createUser = (enqueueSnackbar, setFrameSrc) => {
    console.log('create user1')
    var postData = {
        currency: 'USD'
    };
    console.log('create user')
    axios.post(process.env.REACT_APP_API_HOST + '/api/poker/launch', postData, {
        headers: { 'x-auth-token': getBearer() }
    })
        .then((res) => {
            res = res.data;
            if (!res.success) {
                enqueueSnackbar('Error', { variant: 'error', autoHideDuration: 2000 })
            } else {
                setFrameSrc(res.result)
            }
        })
        .catch((err) => {
            if (err.response) {
                console.error(err.response.data);
            } else {
                console.error(err);
            }
        })
}

const Poker = () => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        message: {
            text: 'Loading ...'
        },
    })
    const [frameSrc, setFrameSrc] = useState()

    // useEffect(() => {

    //     createUser(values, setValues, setFrameSrc)
    // }, [])

    useEffect(() => {
        // window.scrollTo(0, 1);
        console.log('Poker UseEffect')
        if (state.login) {
            createUser(enqueueSnackbar, setFrameSrc)
        } else {
            setValues({
                ...values, message: {
                    style: 'danger',
                    text: 'Login to Play'
                }
            })
            // $('#NavAlien').addClass('neon-blink-info')
        }
        return () => {
            // $('#NavAlien').removeClass('neon-blink-info')
        }
    }, [state.login])

    useEffect(() => {
        setActive(state, dispatch, {
            footer: false,
        })
    }, [])

    return (<>
        <MDBRow className='mx-0'>
            <MDBCol size='12' className=' _d-inline-block bg-black'
            //  style={{ height: '100vh' }}
            >
                {/* <a href={frameSrc} target="_blank">Open</a> */}
                {frameSrc ?
                    <>
                        <iframe id='PokerIFrame'
                            title='Poker'
                            onLoad={(e) => {
                                // console.log('im', $('#PokerIFrame')[0].contentWindow);
                            }} src={frameSrc} className='position-fixed overflow-hidden _bg-danger my-5_' style={{
                                top: state.active.showHeader ? 56 : 0, left: 0, width: '100vw', height: state.active.showHeader ? (window.innerHeight - 56) : window.innerHeight
                            }}></iframe>
                    </>
                    : <>
                        <LayoutProgress>
                            <div className='position-relative d-flex m-auto' style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center', width: '15rem', height: '20rem' }}>
                                <div style={{ left: 0, right: 0, marginBottom: '0rem' }} className='neonProgress position-absolute mx-auto'></div>

                                {state.login ?
                                    <></> :
                                    <ThemeButton onClick={() => setActive(state, dispatch, { layoutDrawer: { ...state.active.layoutDrawer, right: true } })} variant='outlined' style={{ marginTop: '10rem' }} className='mx-auto'>Login to Play</ThemeButton>
                                }
                            </div>
                        </LayoutProgress>
                    </>
                }
            </MDBCol>
        </MDBRow>
    </>);
}

export default Poker
