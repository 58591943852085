import React from 'react';
import { Box, Toolbar } from '@mui/material';
import ModalHeader from './ModalHeader';

const ModalContent = (props) => {
    const { children, header, setOpen } = props;
    return <Box className='noScrollbar d-flex flex-column' sx={{ height: '100% !important', overflow: 'scroll' }}>
        <ModalHeader setOpen={setOpen}>{header}</ModalHeader>
        <Toolbar />
        <Box
            component="main"
            className='w-100 overflow-scroll flex-grow-1'
        >{children}</Box>
    </Box>

}

export default ModalContent