import React, { useContext } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Context } from '../../../store';
import { getBearer, setActive } from '../../../helpers/publicHelper';
import ThemePhoneInput from '../../components/ThemePhoneInput/ThemePhoneInput';
import _ from 'lodash';
import PIN from '../../components/PIN';
import ThemeButton from '../../components/ThemeButton';
import { Alert, Typography } from '@mui/material';

// Submit Mobile
const callVerifyMobile = (state, handleSetActive, enqueueSnackbar) => {
    handleSetActive({ waiting: true });
    const postData = {
        prefix: state.active.kyc.mobile.countryCode,
        number: state.active.kyc.mobile.number
    };
    axios.post(process.env.REACT_APP_API_HOST + '/api/users/kyc/mobile', postData,
        {
            headers: { 'x-auth-token': getBearer() }
        })
        .then(res => {
            res = res.data;
            if (res.success) {
                handleSetActive({
                    mode: 'code',
                    waiting: false
                })
                enqueueSnackbar('Check your phone!', {
                    variant: 'success', autoHideDuration: 3000
                })
            } else {
                if (res.code == 429) {
                    handleSetActive({
                        mode: 'code',
                        waiting: false
                    })
                    enqueueSnackbar(res.message, {
                        variant: 'warning', autoHideDuration: 3000
                    })
                } else {
                    handleSetActive({
                        waiting: false
                    })
                    enqueueSnackbar(res.message, {
                        variant: 'error', autoHideDuration: 3000
                    })
                }

            }
        })
        .catch((err) => {

        })
}

// Verify Code
const submitCode = (state, handleSetActive, enqueueSnackbar) => {
    handleSetActive({ waiting: true });
    axios.post(process.env.REACT_APP_API_HOST + '/api/users/kyc/verifymobile', {
        code: state.active.kyc.mobile.code
    }, {
        headers: { 'x-auth-token': getBearer() }
    })
        .then(res => {
            res = res.data;
            if (res.success) {
                handleSetActive({
                    mode: '',
                    code: '',
                    waiting: false
                })
                enqueueSnackbar('Verified', {
                    variant: 'success', autoHideDuration: 3000
                })
            } else {
                handleSetActive({
                    waiting: false
                })
                enqueueSnackbar(res.message, { // Error is not handled
                    variant: 'error', autoHideDuration: 3000
                })
            }
        })
        .catch((err) => {

        })
}

const KYCMobile = (props) => {
    const [state, dispatch] = useContext(Context);
    const stateKYC = state.user ? state.user.kyc : null;
    const mobileVerified = _.has(stateKYC, 'mobiles.default.verified') ? stateKYC.mobiles.default.verified : false;
    const { mode, number, countryCode, waiting, code } = state.active.kyc.mobile;
    const { enqueueSnackbar } = useSnackbar();

    const handleSetActive = (activeObj) => {
        setActive(state, dispatch, {
            kyc: {
                ...state.active.kyc,
                mobile: {
                    ...state.active.kyc.mobile,
                    ...activeObj
                }
            }
        });
    }

    const validateMobile = (countryCode, number) => {
        console.log(countryCode, number)
        if (
            countryCode && countryCode.length > 0 &&
            number && number.length > 8
        ) {
            callVerifyMobile(state, handleSetActive, enqueueSnackbar)
        } else {
            enqueueSnackbar('Invalid Mobile Number', {
                variant: 'error', autoHideDuration: 3000
            })
        }
    }


    return (<>

        {
            mobileVerified ?
                /* VERIFIED MOBILE */
                <ListItem
                    secondaryAction={<Avatar className={`bg-transparent box-shadow-success border border-success`}><MDBIcon icon='check' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                    <ListItemText
                        primary={<small>(+{stateKYC.mobiles.default.prefix}) {stateKYC.mobiles.default.number}</small>}
                        className='pe-3'
                        secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                        secondary={<small className='text-success'>Verified</small>}
                    />
                </ListItem>
                :
                /* NOT VERIFIED */
                <>
                    {mode == '' ?
                        <ListItem
                            secondaryAction={<Avatar onClick={() => handleSetActive({ mode: 'add' })} className={`bg-transparent mt-1 cursor-pointer box-shadow-neon border border-neon`}><MDBIcon icon='plus' color='neon' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon></Avatar>}>
                            <ListItemText
                                primary={<span>Mobile<span className='text-danger ps-1'>*</span></span>}
                                className='pe-3'
                                secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                                secondary={<small onClick={() => handleSetActive({ mode: 'add' })} className='text-neon text-shadow-neon cursor-pointer'>Add your mobile</small>} />
                        </ListItem> : <></>
                    }
                    {mode == 'add' ?
                        <ListItem secondaryAction={<Avatar sx={{
                            fontSize: '0.5rem',
                            textAlign: 'center'
                        }} onClick={() => validateMobile(countryCode, number)} className={`bg-transparent mt-1 text-neon cursor-pointer box-shadow-neon border border-neon`}>
                            {/* <MDBIcon icon='sms' color='white' style={{ color: '#0f0', fontSize: '0.8rem' }}></MDBIcon> */}
                            Get Code
                        </Avatar>}>
                            <ListItemText
                                primary={
                                    <ThemePhoneInput
                                        label={<span>Phone<span className='text-danger ps-1'>*</span></span>}
                                        value={{ phone: number, countryCode: countryCode }}
                                        onChange={e => {
                                            handleSetActive({ number: e.phone, countryCode: e.countryCode });

                                        }}
                                    ></ThemePhoneInput>
                                }
                                className='pe-3 me-1' />
                        </ListItem> : <></>
                    }
                    {mode == 'code' ? <>
                        <ListItem
                            secondaryAction=''>
                            <ListItemText
                                primary={
                                    <Alert
                                        sx={{
                                            alignItems: 'center',
                                            '.MuiAlert-message': {
                                                width: '100%'
                                            }
                                        }} variant="outlined" severity="success">
                                        <div className='text-success w-100 text-center'>A code has been sent to
                                            <div className='text-center text-success w-100'>(+{countryCode}) {number}
                                                <Typography variant='caption' component='span' onClick={() => handleSetActive({ mode: 'add' })} className='cursor-pointer ps-2 text-warning'>Edit</Typography>
                                            </div>
                                        </div>
                                    </Alert>
                                } />
                        </ListItem>
                        <ListItem>
                            <Typography variant='body2' component='span' onClick={() => handleSetActive({ mode: 'add' })} className=''>Enter the code:</Typography>
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <ThemeButton onClick={() => submitCode(state, handleSetActive, enqueueSnackbar)}
                                    disabled={code.length !== 6}
                                    size='small'
                                    color='neon'
                                    className={`px-2`}>
                                    Verify
                                </ThemeButton>}>
                            <ListItemText primary={<PIN inputStyle={{ padding: 0 }} name='emailCode' size='small' handlechange={e => handleSetActive({ code: e.value })} type='number' fields={6} />} className='pe-3 me-1' />
                        </ListItem></> : <></>
                    }
                </>
        }
    </>)
}

export default KYCMobile
