import React, { useState, useContext } from 'react';
import { MDBCol } from 'mdb-react-ui-kit';
import axios from 'axios';
import _ from 'lodash';
import ThemeInput from '../components/ThemeInput';
import ThemeButton from '../components/ThemeButton';
import { useSnackbar } from 'notistack';
import { getBearer } from '../../helpers/publicHelper';
import { Context } from '../../store';

const ChangePassword = () => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        oldPassword: '',
        password: '',
        confirmPassword: '',
    })

    const submit = () => {
        const { password, confirmPassword, oldPassword } = values;
        if (oldPassword.length < 1) return enqueueSnackbar('Enter the "Old password"', {
            variant: 'error',
            autoHideDuration: 3000
        });
        if (password !== confirmPassword) return enqueueSnackbar('"Password" and "Confirm Password" must be equal.', {
            variant: 'error',
            autoHideDuration: 3000
        });
        if (password.length < 8) return enqueueSnackbar('"Password" must be 8 characters or more.', {
            variant: 'error',
            autoHideDuration: 3000
        });

        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        axios.post(process.env.REACT_APP_API_HOST + '/api/users/changepassword', values, {
                            headers: {
                                captcha: captcha,
                                'x-auth-token': getBearer(),
                            }
                        })
                            .then(res => {
                                res = res.data;
                                if (res.success) {
                                    enqueueSnackbar(res.message, {
                                        variant: 'success',
                                        autoHideDuration: 3000
                                    });
                                } else {
                                    return enqueueSnackbar(res.message, {
                                        variant: 'warning',
                                        autoHideDuration: 3000
                                    });
                                }
                            })
                            .catch((err) => {
                                return enqueueSnackbar(err.message, {
                                    variant: 'error',
                                    autoHideDuration: 3000
                                });
                            })
                    })
            })
        }
    }

    return (
        <MDBCol size='12' className='pb-4'>
            <form autoComplete="off" id='ChangePassword' noValidate='noValidate' className='text-start' onSubmit={e => { e.preventDefault(); submit() }}>
                <ThemeInput labelClass='_text-warning' className='mb-3' autoComplete='new-password' type='password' onChange={e => setValues({ ...values, oldPassword: e.target.value })} label='Old Password' value={values.oldPassword}></ThemeInput>
                <ThemeInput labelClass='_text-warning' className='mb-3' autoComplete='new-password' type='password' onChange={e => setValues({ ...values, password: e.target.value })} label='Password' value={values.password}></ThemeInput>
                <ThemeInput labelClass='_text-warning' className='mb-3' autoComplete='new-password' type='password' onChange={e => setValues({ ...values, confirmPassword: e.target.value })} label='Confirm Password' value={values.confirmPassword}></ThemeInput>
                <MDBCol size='12' className='text-center'>
                    <ThemeButton type='submit' className='px-3' variant='outlined'>Change Password</ThemeButton>
                </MDBCol>
            </form>
        </MDBCol>
    )
}

export default ChangePassword
