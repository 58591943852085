import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Context } from '../../store';
import { setActive } from '../../helpers/publicHelper';
import { currencyIcon } from '../../helpers/currency';

const SelectCurrency = () => {
    const [state, dispatch] = useContext(Context);
    const handleSetActive = (activeObj) => {
        setActive(state, dispatch, activeObj);
    }

    return <Grid container className='p-4 pb-0'>
        {
            state.config.currencyList.map((currency, i) =>
                <Grid item xs={2} key={currency}
                    className={'cursor-pointer mb-2 px-0 ' + ('USD'.indexOf(currency) !== -1 ? 'opacity-30' : '')}
                    onClick={() => {
                        handleSetActive({
                            currency,
                            withdrawValue: state?.user?.wallet?.inUSD?.withdraw?.byCurrency?.[currency]?.availableWithdrawAmount || 0
                        })
                    }}>
                    <Box className='position-relative m-auto' sx={{ height: '1.5rem', width: '1.5rem' }}>
                        <img className='position-absolute'
                            style={{
                                left: '0.024rem',
                                top: '0.024rem',
                                borderRadius: '50rem',
                                width: '1.45rem',
                                height: '1.45rem',
                            }} src={currencyIcon(currency)} />
                        <span className='position-absolute'
                            style={{
                                position: 'absolute',
                                display: 'inline-block',
                                left: 0,
                                top: 0,
                                borderRadius: '50rem',
                                border: state.active.currency == currency ? '0.15rem solid #26ddff' : '0.15rem solid transparent',
                                boxShadow: state.active.currency == currency ? '0px 0px 1rem #26ddff' : '',
                                width: '1.5rem',
                                height: '1.5rem',
                                zIndex: 999
                            }}>
                        </span>
                    </Box>
                </Grid>
            )
        }
    </Grid>
}

export default SelectCurrency