import React, { useContext } from "react";
import { makeStyles, styled } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Context } from "../store";
import { setActive } from "./../helpers/publicHelper";
import CIL from "../pages/CIL";
import ITech from "../pages/ITech";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import PageItem from "./navbar/PageItem";
import TermsAndConditions from "./footer/TermsAndConditions";
import numeral from "numeral";
import { currencyIcon } from "../helpers/currency";
import { useEffect } from "react";
import { width } from "@mui/system";
// import SocketConnection from "./auth/SocketConnection";

const useStyles = makeStyles((theme) => ({
    footer: {
        height: '70px',
        bottom: 0,
        left: 0,
        width: '100%',
        overflow: 'hidden',
        //  position: 'absolute',
        zIndex: 99999,
        text: 'center',
        color: '#fff',
        marginTop: '1rem !important',
        [theme.breakpoints.up(768)]: {
            height: '100px',
        },
        [theme.breakpoints.up(880)]: {
            height: '110px',
        },
        [theme.breakpoints.up(1250)]: {
            height: '80px',
        },
        [theme.breakpoints.up(1500)]: {
            height: '95px',
        },
        [theme.breakpoints.up(1920)]: {
            height: '5.5rem',
        },
    },
    footerBG: {
        backgroundColor: 'transparent',
        [theme.breakpoints.up(1250)]: {
            backgroundColor: 'transparent',
        }
    },
    wave: {
        filter: 'drop-shadow(0px 0px 5px #26ddff)',
        top: 0,
        left: 0,
        position: 'absolute',
        [theme.breakpoints.up(768)]: {
            transform: 'scale(1, 0.5)',
            transformOrigin: 'top',
        },
        [theme.breakpoints.up(880)]: {
            transform: 'scale(1, 0.7)',
            transformOrigin: 'top',
        },
        [theme.breakpoints.up(1250)]: {
            transform: 'scale(1, .4)',
            transformOrigin: 'top',
        },

    },
    itechMobile: {
        width: 35
    },
    itechDesktop: {
        width: '3vw'
    }
}));

const Footer = () => {
    const classes = useStyles();
    const [state, dispatch] = useContext(Context);

    useEffect(() => {
        setTimeout(() => {
            window.apg_f5200ddd_281d_4644_b29b_899f07790f01?.init()
        }, 1000);
    }, [])


    return (
        <>
            <div className='pt-5 position-relative'
                style={{
                    width: '100vw',
                }}
            >
                <Box
                    className='overflow-hidden'
                    sx={{
                        '& svg.glow': {
                            filter: ['drop-shadow(0 0px 10px #26ddff) drop-shadow(0 0px 15px #26ddff)', 'drop-shadow(0 0px 10px #26ddff) drop-shadow(0 0px 30px #26ddff)']
                        }
                    }}
                >
                    <div className='position-relative overflow-hidden'>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width='100%' version="1.1"
                            viewBox="0 0 790 113"
                            xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g>
                                {/* <path className='glow' fill="#26ddff" d="M790 30l0 50 -790 0 0 -50c65,4 131,8 196,11 67,3 134,16 200,16 67,0 133,-13 200,-16 65,-3 130,-7 194,-11z" /> */}
                                {/* <path fill="#222" d="M790 0l0 113 -790 0 0 -113c65,9 130,18 195,25 67,7 134,32 200,32 67,0 133,-25 200,-32 65,-7 130,-16 195,-25z" /> */}
                            </g>
                        </svg>

                        <svg className='glow' xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width='100%' version="1.1"
                            style=
                            {{
                                left: 0,
                                bottom: '-10px',
                                position: 'absolute'
                            }}
                            viewBox="0 0 790 113"
                            xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g>
                                <path fill="#26ddff" d="M790 30l0 50 -790 0 0 -50c65,4 131,8 196,11 67,3 134,16 200,16 67,0 133,-13 200,-16 65,-3 130,-7 194,-11z" />
                            </g>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width='100%' version="1.1"
                            style=
                            {{
                                width: '104vw',
                                left: '-2vw',
                                position: 'absolute'
                            }}
                            viewBox="0 0 790 113"
                            xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g>
                                <path fill="#000" d="M790 0l0 113 -790 0 0 -113c65,9 130,18 195,25 67,7 134,32 200,32 67,0 133,-25 200,-32 65,-7 130,-16 195,-25z" />
                            </g>
                        </svg>
                    </div>
                </Box>
                <div className='position-absolute- w-100 text-center bg-black'>
                    <div className={classes.footerBG_ + ' position-relative w-100 h-100-'}>

                        <div className={`position-absolute- text-center pb-2 small m-auto -d-none d-md-block opacity-40`} style={{ bottom: 0, width: '70vw', left: 0, right: 0, fontSize: '0.6rem' }}>
                        iMoon B.V. (hereinafter "Company", "iMoon" or "Website") is registered under the Commercial Register of Curaçao under registration number 158383, with an office address at Emancipatie Boulevard Dominico F Don Martina, 31, Curaçao, duly licensed through a sublicense granted by Antillephone N.V., pursuant to Master Gaming License #8048/JAZ issued by the Governor of Curaçao.
                          </div>
                        <FooterITech />
                        {/* <FooterCIL/> */}
                        <FooterAntillephone />
                    </div>
                    <hr />
                    <Grid container justifyContent='center'>
                        <Grid item className='text-neon m-3'>
                            <TermsAndConditions selectedIndex={1} />
                        </Grid>
                        <Grid item className='text-warning m-3 d-flex align-items-center'>
                            <SmallAvatar alt='BTC' src={currencyIcon('BTC')} />
                            BTC/USD:

                            {
                                numeral(
                                    state?.rates?.USD?.BTC ? 1 / state?.rates?.USD?.BTC : 0
                                ).format('0,0.00')}
                        </Grid>
                    </Grid>
                </div>
            </div>

            {/* <MDBFooter className={classes.footer}>
                <div className={classes.footerBG + ' posi/tion-relative w-100 h-100'}>
                    <img src='/lobby/wave.svg' className={classes.wave + ` img-fluid w-100`} />
                    <div className={`position-absolute text-center pb-2 small m-auto d-none d-md-block`} style={{ bottom: 0, width: '80vw', left: 0, right: 0, fontSize: '0.7rem' }}>
                        imoon.com is operated by iMoon B.V registered in the Commercial Register of Curaçao under number 158383 and address Emancipatie Boulevard Dominico F Don Martina 31 Curaçao.
                        iMoon B.V. is duly licensed through a sublicense granted by C.I.L. Curaçao Interactive Licensing N.V., pursuant to Master Gaming License #5536/JAZ issued by the Governor of Curaçao.
                    </div>
                    <FooterITech></FooterITech>
                    <FooterCIL></FooterCIL>
                </div>
            </MDBFooter> */}
            {/* {
                state.active.footer
                    &&
                    (isMobile && (window.innerHeight > window.innerWidth) || !isMobile) &&
                    !(isMobile && state.active.layoutDrawer.left || isMobile && state.active.layoutDrawer.right)
                    ?
                    <MDBFooter className={classes.footer}>
                        <div className={classes.footerBG + ' position-relative w-100 h-100'}>
                            <img src='/lobby/wave.svg' className={classes.wave + ` img-fluid w-100`} />
                            <div className={`position-absolute text-center pb-2 small m-auto d-none d-md-block`} style={{ bottom: 0, width: '80vw', left: 0, right: 0, fontSize: '0.7rem' }}>
                                imoon.com is operated by iMoon B.V registered in the Commercial Register of Curaçao under number 158383 and address Emancipatie Boulevard Dominico F Don Martina 31 Curaçao.
                                iMoon B.V. is duly licensed through a sublicense granted by C.I.L. Curaçao Interactive Licensing N.V., pursuant to Master Gaming License #5536/JAZ issued by the Governor of Curaçao.
                            </div>
                            <FooterITech></FooterITech>
                            <FooterCIL></FooterCIL>
                        </div>
                    </MDBFooter> : <></>
            } */}
        </>
    );
}

const FooterITech = () => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const desktop = useMediaQuery('(min-width:600px)');

    return (
        <Box sx={{
            position: 'absolute',
            right: ['0.7rem', '5rem'],
            bottom: '1rem',
            cursor: 'pointer',
            display: 'inline-block'
        }}
            onClick={(e) => {
                setActive(state, dispatch, {
                    modal: {
                        title: 'iTech',
                        content: <ITech></ITech>,
                        open: true
                    }
                })
            }}>
            <img id='iTech' src='/itechlab/logo.svg' alt='itech lab certification' className={(desktop ? classes.itechDesktop : classes.itechMobile) + ' m-md-2'} />
        </Box>
    )
}

const FooterCIL = () => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const desktop = useMediaQuery('(min-width:600px)');

    return (
        <Box sx={{
            position: 'absolute',
            left: ['0.7rem', '5rem'],
            bottom: '1rem',
            cursor: 'pointer',
            display: 'inline'
        }}
            onClick={(e) => {
                setActive(state, dispatch, {
                    modal: {
                        title: 'CIL',
                        content: <CIL></CIL>,
                        open: true
                    }
                })
            }}>
            <img id='CIL' src='/cil/cil.svg' alt='CIL certification' className={(desktop ? classes.itechDesktop : classes.itechMobile) + ' m-md-2'} />
        </Box>
    )
}

const FooterAntillephone = () => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const desktop = useMediaQuery('(min-width:600px)');

    return (
        <Box sx={{
            position: 'absolute',
            left: ['0.7rem', '5rem'],
            bottom: '0.8rem',
            cursor: 'pointer',
            display: 'inline',
            width: desktop ? '3.5vw' : '40px'
        }}
            onClick={(e) => {
                setActive(state, dispatch, {
                    modal: {
                        title: 'CIL',
                        content: <CIL></CIL>,
                        open: true
                    }
                })
            }}>
            <div id="apg-f5200ddd-281d-4644-b29b-899f07790f01" data-apg-seal-id="f5200ddd-281d-4644-b29b-899f07790f01" data-apg-image-size="64" data-apg-image-type="basic-light-large"></div>
        </Box>
    )
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

export default Footer;