import React, { useState, useEffect, useContext } from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import axios from 'axios';
import { Box, Collapse, Grow, Slide, Typography, Zoom } from '@mui/material';
import $ from 'jquery'
import validator from 'validator';
import _ from 'lodash';
import ThemeButton from '../components/ThemeButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { Context } from '../../store';
import ThemeInput from '../components/ThemeInput';
import { handleAxiosError, loginSuccess } from '../../helpers/publicHelper';



const Login = (props) => {
    const [state, dispatch] = useContext(Context);
    const { t } = state;
    const { setView, oneClick } = props;
    const { enqueueSnackbar } = useSnackbar();

    // // Listen for authorization success.
    // document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    //     // Handle successful response.
    //     console.log(event.detail.data);
    // });

    // // Listen for authorization failures.
    // document.addEventListener('AppleIDSignInOnFailure', (event) => {
    //     // Handle error.
    //     console.log(event.detail.error);
    // });

    const [values, setValues] = useState({
        phone: '',
        step: 1,
        loginMode: 'username',
        login: '',
        showPassword: false,
        password: '',
        passwordMode: 'password',
        twofa: '-',
        waiting: false
    })

    const handleLoginSuccess = (token, result) => {
        setValues({
            ...values,
            waiting: false
        });
        loginSuccess(state, dispatch, result, token);
    }

    const ask2FA = (res) => {
        setValues({
            ...values,
            step: 3
        })
    }

    const callLogin = (loginValues) => {
        if (window.grecaptcha) {
            console.log('window.grecaptcha', window.grecaptcha)
            console.log('window.grecaptcha', window.grecaptcha)
            window.grecaptcha.ready(() => {
                console.log('window.grecaptchaReady', window.grecaptcha)
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        let postData = _.pick(loginValues, ['loginMode', 'login', 'password', 'twofa']);
                        const headers = { captcha };
                        axios.post(process.env.REACT_APP_API_HOST + '/api/users/login', postData, { headers })
                            .then((res) => {
                                const token = res.headers['x-auth-token'];
                                res = res.data;
                                if (res.success) {
                                    // no 2FA
                                    if (res.code == 200) {
                                        handleLoginSuccess(token, res.result);
                                    }
                                    // ask for 2FA
                                    else if (res.code == 403) { ask2FA(res); }

                                    else if (res.code == 503) {
                                        enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 3000 })
                                    }
                                } else {
                                    setValues({
                                        ...values,
                                        waiting: false,
                                    })
                                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 3000 })
                                }
                            })
                            .catch(err => {
                                // if (err.response && err.response.data && err.response.data.code == 503) {
                                //     enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 })
                                // }
                                handleAxiosError(err);
                                setValues({
                                    ...values,
                                    waiting: false,
                                })
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }
    }

    useEffect(() => {
        if (values.step >= 0 && values.step < 4) {
            $('input[name=login]').trigger('focus');
        }
    }, [values.step])

    const validateStep = () => {
        console.log('validateStep')
        const login = values.login;
        let loginMode = '';

        if (validator.isEmail(login)) {
            loginMode = 'email'
        } else if (validator.isMobilePhone(login) && validator.isLength(login, { min: 8, max: 10 })) {
            loginMode = 'mobile'
        } else if (!validator.contains(login, '@') && !validator.isNumeric(login)) {
            loginMode = 'username'
        } else {
            loginMode = 'error'
            return setValues({
                ...values,
                loginMode: loginMode
            })
        }

        setValues({
            ...values,
            message: {
                text: ''
            },
            waiting: true,
            loginMode: loginMode,
        });

        callLogin({
            ...values,
            waiting: true,
            loginMode: loginMode,
        });
    }

    const [showAnimation, setShowAnimation] = useState(false)
    const google = window.google;
    // const AppleID = window.AppleID;

    const handleCredentialResponse = (response) => {
        let postData = {
            token: response.credential,
            refId: new URL(window.location.href).searchParams.get('refId')
        }
        axios.post(process.env.REACT_APP_API_HOST + '/api/users/loginByGoogle', postData,
            //  { headers }
        )
            .then((res) => {
                const token = res.headers['x-auth-token'];
                res = res.data;
                if (res.success) {
                    // no 2FA
                    if (res.code == 200) {
                        handleLoginSuccess(token, res.result);
                    }
                    // ask for 2FA
                    else if (res.code == 403) { ask2FA(res); }

                    else if (res.code == 503) {
                        enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 3000 })
                    }
                } else {
                    setValues({
                        ...values,
                        waiting: false,
                    })
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 3000 })
                }
            })
            .catch(err => {
                handleAxiosError(err);
                setValues({
                    ...values,
                    waiting: false,
                })
            })
    }

    useEffect(() => {
        if (google) {
            google.accounts.id.initialize({
                client_id: "959184640275-k0c0b1nsc33amq9riuolpvvvtcmh0ist.apps.googleusercontent.com",
                callback: handleCredentialResponse
            });
            google.accounts.id.renderButton(
                document.getElementById("buttonDiv"),
                { theme: "dark", size: "medium", width: '220px', text: 'continue_with', logo_alignment: "center", shape: 'rectangular', class: 'bg-danger' }  // customization attributes
            );
            // google.accounts.id.prompt(); // also display the One Tap dialog

        } else {
            console.log('no window.google!')
        }
        setShowAnimation(true)
        return () => {
            setShowAnimation(false)
        }
    }, [google])

    // useEffect(() => {
    //     if (AppleID) {
    //         AppleID.auth.init({
    //             clientId: '[CLIENT_ID]',
    //             scope: '[SCOPES]',
    //             redirectURI: '[REDIRECT_URI]',
    //             state: '[STATE]',
    //             nonce: '[NONCE]',
    //             usePopup: true
    //         });
    //     } else {
    //         console.log('no window.AppleID!')
    //     }
    //     return () => {

    //     }
    // }, [AppleID])

    return (
        <MDBRow className='m-0 py-2 mb-5'>
            <MDBCol size='12'>
                {/* Login */}
                <form onSubmit={e => { e.preventDefault(); validateStep() }}>
                    <div className='position-relative mb-3'>
                        <ThemeInput
                            inputClassName='rounded-pill- overflow-hidden bg-black'
                            label={
                                <span style={{ color: '#fff' }}>Username, Email or Phone
                                    <span className='text-danger'> *</span>
                                </span>
                            }
                            labelStyle={{ background: '#121212' }} value={values.login} onChange={e => setValues({ ...values, login: e.target.value })}></ThemeInput>
                    </div>
                    <div className={`position-relative`}>
                        <ThemeInput
                            inputClassName='rounded-pill- overflow-hidden bg-black'
                            label={
                                <span style={{ color: '#fff' }}>Password
                                    <span className='text-danger'> *</span>
                                </span>
                            }
                            labelStyle={{ background: '#121212' }} value={values.password} type={values.showPassword ? 'text' : 'password'} onChange={e => setValues({ ...values, password: e.target.value })}></ThemeInput>
                        <MDBIcon onClick={() => setValues({ ...values, showPassword: !values.showPassword })} className='text-neon position-absolute cursor-pointer' style={{ right: '0.8rem', top: '0.6rem', zIndex: 3 }} icon={values.showPassword ? 'eye' : 'eye-slash'}></MDBIcon>
                    </div>
                    <MDBRow>
                        <MDBCol size='6' className={`pt-3 d-flex text-warning`}>
                            <MDBIcon onClick={() => setView('help')} icon='exclamation-circle' className=' d-inline-block cursor-pointer ms-0 pt-1' style={{ fontSize: '1.1rem' }}></MDBIcon>
                            <small onClick={() => setView('forgotPassword')} className='cursor-pointer text-white ps-1 d-inline-block pt-1' style={{ fontSize: '0.7rem' }}>{t('Help?')}</small>
                        </MDBCol>
                        <MDBCol size='6' className='text-end pt-3'>
                            <ThemeButton waiting={values.waiting} type='submit' variant='outlined_' className='text-neon rounded-pill px-3 py-1'>{t('Sign In')}</ThemeButton>
                        </MDBCol>
                    </MDBRow>
                </form>
            </MDBCol>
            <div className='position-relative d-flex my-4 small'>
                <div className='w-50 pe-3'>
                    <hr className='' />
                </div>
                <div className='w-50 ps-3'>
                    <hr className='' />
                </div>
                <small className='text-neon position-absolute m-auto' style={{
                    left: '50%',
                    right: '50%',
                    top: '0.5rem',
                    transform: 'translateX(-50%)',
                    width: '30px',
                    textAlign: 'center'
                }}>OR</small>
            </div>

            <Zoom in={showAnimation}
                unmountOnExit
                style={{ transitionDelay: '300ms', transitionDuration: '500ms' }}>
                <div className='text-center small cursor-pointer mb-3'>
                    <Typography sx={{ typography: ['caption', 'caption'] }} onClick={oneClick}
                        className='py-2 box-shadow-neon- px-1 rounded-2 d-inline-block'
                        style={{
                            backgroundColor: '#229ED9',
                            width: '220px'
                        }}>
                        <MDBIcon icon='paper-plane' className='me-2'></MDBIcon>
                        Continue with Telegram
                    </Typography>
                </div>
            </Zoom>

            <Zoom in={showAnimation}
                // unmountOnExit
                style={{ transitionDelay: '600ms', transitionDuration: '500ms' }}>
                <div className='text-center small position-relative -bg-danger'>
                    <Typography component='div' sx={{ typography: ['caption', 'caption'] }} className='text-black rounded-2 position-relative d-inline-block mb-3'
                        style={{
                            top: 0,
                            left: 0,
                            backgroundColor: '#fff',
                            width: '220px'
                        }}>
                        <div id="buttonDiv" className='position-absolute opacity-0  w-100'></div>
                        <div className='py-2'>
                            <span className='me-1' style={{
                                background: 'linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}>
                                <svg className='mt-n1' style={{}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                                        <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
                                        <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
                                        <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
                                        <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
                                    </g>
                                </svg>
                                {/* <MDBIcon icon='google' fab className='me-2'></MDBIcon> */}
                            </span>
                            Continue with Google
                        </div>
                    </Typography>
                </div>
            </Zoom>

            {/* <Zoom in={showAnimation}
                // unmountOnExit
                style={{ transitionDelay: '500ms', transitionDuration: '500ms' }}>
                <div className='text-center small  text-center m-auto' style={{ width: '250px' }}>
                    <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in"></div>
                </div>
            </Zoom> */}
        </MDBRow>
    )
}

export default Login
