import React, { useContext, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Draggable } from 'gsap/Draggable'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { Box, List, ListItem, useMediaQuery } from '@mui/material'
import { GameListContext } from '../../store'
import GameListCard from '../../games/GameListCard'

function buildSeamlessLoop(items, spacing, animateFunc) {
    let rawSequence = gsap.timeline({ paused: true }), // this is where all the "real" animations live
        seamlessLoop = gsap.timeline({ // this merely scrubs the playhead of the rawSequence so that it appears to seamlessly loop
            paused: true,
            repeat: -1, // to accommodate infinite scrolling/looping
            onRepeat() { // works around a super rare edge case bug that's fixed GSAP 3.6.1
                this._time === this._dur && (this._tTime += this._dur - 0.01);
            },
            onReverseComplete() {
                this.totalTime(this.rawTime() + this.duration() * 100); // seamless looping backwards
            }
        }),
        cycleDuration = spacing * items.length,
        dur; // the duration of just one animateFunc() (we'll populate it in the .forEach() below...
    items.concat(items).concat(items).forEach((item, i) => {
        let anim = animateFunc(items[i % items.length]);
        rawSequence.add(anim, i * spacing);
        dur || (dur = anim.duration());
    });
    seamlessLoop.fromTo(rawSequence, {
        time: cycleDuration + dur / 2
    }, {
        time: "+=" + cycleDuration,
        duration: cycleDuration,
        ease: "none"
    });
    return seamlessLoop;
}

const LobbyGamesCarousel = (props) => {

    const { provider, mode, type, limit, id, direction } = props;
    const [contextGameList, setContextGameList] = useContext(GameListContext);
    const ltr = direction == 'right';
    const [games, setGames] = useState({
        list: [],
        count: 0
    })

    const [filter, setFilter] = useState({
        provider,
        type,
        limit,
        live: mode == 'live',
    });

    const [iteration, setIteration] = useState(0);

    const iterationRef = useRef();
    iterationRef.current = iteration;

    const [animate, setAnimate] = useState(true)
    const animateRef = useRef();
    animateRef.current = animate;
  const isMobile = useMediaQuery('(max-width:600px)');

    const xPercent = isMobile ? 480 : 600;
    useEffect(() => {
        if (games && games.list.length > 10) {
            gsap.registerPlugin(ScrollTrigger, Draggable);
            gsap.set(`#${id} .cards li`, { xPercent: xPercent, opacity: 0, scale: 0 });

            const spacing = 0.1, // spacing of the cards (stagger)
                snapTime = gsap.utils.snap(spacing), // we'll use this to snapTime the playhead on the seamlessLoop
                cards = gsap.utils.toArray(`#${id} .cards li`),
                // this function will get called for each element in the buildSeamlessLoop() function, and we just need to return an animation that'll get inserted into a master timeline, spaced
                animateFunc = element => {
                    const tl = gsap.timeline();
                    tl.fromTo(element, { scale: 1, opacity: 0 }, { scale: 1, opacity: 1, zIndex: 100, duration: 0.5, yoyo: true, repeat: 1, ease: "power1.in", immediateRender: false })
                        .fromTo(element, { xPercent: ltr ? -xPercent : xPercent }, { xPercent: ltr ? xPercent : -xPercent, duration: 1, ease: "none", immediateRender: false }, 0);
                    return tl;
                },
                seamlessLoop = buildSeamlessLoop(cards, spacing, animateFunc),
                playhead = { offset: 0 }, // a proxy object we use to simulate the playhead position, but it can go infinitely in either direction and we'll just use an onUpdate to convert it to the corresponding time on the seamlessLoop timeline.
                wrapTime = gsap.utils.wrap(0, seamlessLoop.duration()), // feed in any offset (time) and it'll return the corresponding wrapped time (a safe value between 0 and the seamlessLoop's duration)
                scrub = gsap.to(playhead, { // we reuse this tween to smoothly scrub the playhead on the seamlessLoop
                    offset: 0,
                    onUpdate() {
                        seamlessLoop.time(wrapTime(playhead.offset)); // convert the offset to a "safe" corresponding time on the seamlessLoop timeline
                    },
                    duration: 0.5,
                    ease: "power3",
                    paused: true
                }),
                trigger = ScrollTrigger.create({
                    start: 0,
                    onUpdate(self) {
                        let scroll = self.scroll();
                        if (scroll > self.end - 1) {
                            //  wrap(1, 1);
                        } else if (scroll < 1 && self.direction < 0) {
                            //  wrap(-1, self.end - 1);
                        } else {
                            // scrub.vars.offset = (iterationRef.current + self.progress) * seamlessLoop.duration();
                            // scrub.invalidate().restart(); // to improve performance, we just invalidate and restart the same tween. No need for overwrites or creating a new tween on each update.
                        }
                    },
                    end: "+=3000",
                    // pin: ".gallery"
                }),
                // converts a progress value (0-1, but could go outside those bounds when wrapping) into a "safe" scroll value that's at least 1 away from the start or end because we reserve those for sensing when the user scrolls ALL the way up or down, to wrap.
                progressToScroll = progress => gsap.utils.clamp(1, trigger.end - 1, gsap.utils.wrap(0, 1, progress) * trigger.end),
                wrap = (iterationDelta, scrollTo) => {
                    setIteration(iterationRef.current + iterationDelta)
                    // iteration += iterationDelta;
                    trigger.scroll(scrollTo);
                    trigger.update(); // by default, when we trigger.scroll(), it waits 1 tick to update().
                };

            // when the user stops scrolling, snap to the closest item.
            // ScrollTrigger.addEventListener("scrollEnd", () => scrollToOffset(scrub.vars.offset));

            // feed in an offset (like a time on the seamlessLoop timeline, but it can exceed 0 and duration() in either direction; it'll wrap) and it'll set the scroll position accordingly. That'll call the onUpdate() on the trigger if there's a change.
            function scrollToOffset(offset) { // moves the scroll playhead to the place that corresponds to the totalTime value of the seamlessLoop, and wraps if necessary.
                console.log(offset)
                let snappedTime = snapTime(offset),
                    progress = (snappedTime - seamlessLoop.duration() * iterationRef) / seamlessLoop.duration(),
                    scroll = progressToScroll(progress)
                    ;
                if (progress >= 1 || progress < 0) {
                    return wrap(Math.floor(progress), scroll);
                }
                trigger.scroll(scroll);
            }
            Draggable.create(`#${id} .drag-proxy`, {
                type: "x",
                trigger: `#${id} .cards`,
                onPress() {
                    this.startOffset = scrub.vars.offset;
                },
                onDrag() {
                    setAnimate(false)
                    scrub.vars.offset = this.startOffset + (ltr ? (this.x - this.startX) : (this.startX - this.x)) * 0.001;
                    scrub.invalidate().restart();
                },
                onDragEnd() {
                    setAnimate(true)
                    console.log(scrub.vars.offset)
                }
            });
            setInterval(() => {
                if (animateRef.current) {
                    scrub.vars.offset += 0.0002;
                    scrub.invalidate().restart();
                }
            }, 50);
        }
    }, [games.list])

    const filterGames = (params) => {
        const { provider, type, limit, live } = params || {};
        const newGamesList = (contextGameList.games.filter(g => {
            if (mode == 'live' && !g.live) return false;
            if (mode == 'notLive' && g.live) return false;
            let result = (((provider == 'all') || (g.provider == provider)) &&
                ((type == 'all') || (g.type == type)));
            return result
        }));
        const slicedNewGamesList = newGamesList.slice(0, limit);
        setGames({ list: slicedNewGamesList, count: newGamesList.length });
    }

    useEffect(() => {
        if (contextGameList.games) {
            filterGames({
                ...filter,
            })
        }
    }, [filter, contextGameList.games])

    return (
        <Box sx={{
            height: ['11rem', '12rem']
        }}>
            <MDBRow className='mx-0 my-3 position-relative'>
                <MDBCol size='12'>
                    <div id={id} className='w-100 overflow-hidden'>
                        <div className="gallery">
                            <List className='cards position-absolute' sx={{
                                width: ['8rem', '8rem'],
                                '& li': {
                                    // widght: '150px',
                                    margin: '0',
                                    display: 'inline-block',
                                    padding: '0',
                                    listStyle: 'none',
                                    position: 'absolute',
                                    left: 0,
                                    '& > div': {
                                        borderRadius: '0.5rem',
                                        width: '139px !important',
                                        maxWidth: '200% !important',
                                        padding: '0px !important',
                                        margin: '0px !important',
                                        boxShadow: '0 0 10px 1px #25ddff55'
                                    },
                                },
                                // max-height: 1rem;
                                top: 0,
                                right: '50%',
                                transform: 'translate(50%, 0%)'
                            }}>
                                {games.list.map((game, i) =>
                                    <ListItem key={i}
                                    //onTouchStart={() => setAnimate(false)}
                                    //onTouchEnd={() => setAnimate(true)}
                                    >
                                        <GameListCard imageHeight='186px' hideExtra={true} key={i} {...game}></GameListCard>
                                    </ListItem>
                                )}
                                {/* {games.list.map((item, i) => {
                                    const { title, image, url, positions, index, provider, className, height } = item;
                                    return <ListItem
                                        onTouchStart={() => setAnimate(false)}
                                        onTouchEnd={() => setAnimate(true)}
                                    >

                                        <div>
                                            <img className={`img-fluid -m-auto ${className}`}
                                                style={{ height }}
                                                src={image + '?1'} />
                                        </div>
                                    </ListItem>
                                })} */}
                            </List>
                        </div>
                        <div className="drag-proxy position-absolute invisible"></div>
                    </div>
                </MDBCol>
            </MDBRow>
        </Box>)
}

export default LobbyGamesCarousel

