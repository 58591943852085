
export const listItemIconSX = {
    minWidth: 0,
    mr: '1rem',
    width: '2rem',
    py: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& svg': {
        // width: '1rem',
        fontSize: ['1.2rem', '1.5rem'],
        transition: 'all 0.3s easeInOut'
    },
    '& svg:hover': {
        fill: '#26ddff'
    }
};
