import { Avatar, Box, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { Context } from '../../store';
import PlayerTier from '../../user/PlayerTier';

const MiniProfile = () => {
    const [state, dispatch] = useContext(Context);
    const { avatar, username, tier } = state.user?.profile || {};
    return <Box className=' position-relative d-flex p-3 align-items-center'>
        <Box className=' position-relative d-flex me-3'>
            {/* {avatar ? <MDBIcon onClick={() => uploadSuccess()} style={{ top: 7, left: -5, zIndex: 9999 }} className=' cursor-pointer text-danger position-absolute m-auto bg-white rounded-circle' icon='times-circle'></MDBIcon> : <></>} */}
            <Avatar className={`bg-transparent box-shadow-neon m-auto`}>
                <span className='position-relative text-center'>
                    <img src={avatar || '/alien.svg'} className='img-fluid m-auto' style={{ margin: avatar ? 0 : 'auto', width: avatar ? '100%' : '60%', height: 'auto' }} />
                </span>
            </Avatar>
        </Box>
        <Box>
            <Typography variant='caption' className='d-block'>
                {username}
            </Typography>
            <Typography variant='caption' className='d-block'>
                <PlayerTier tier={tier} />
            </Typography>
        </Box>
    </Box>
}

export default MiniProfile