import React, { useContext, useEffect, useState } from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import ThemeInput from '../components/ThemeInput';
import ThemeButton from '../components/ThemeButton';
import { AppBar, Tab, Tabs } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import $ from 'jquery';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import axios from 'axios';
import validator from 'validator';
import ThemePhoneInput from '../components/ThemePhoneInput/ThemePhoneInput';
import { Context } from '../../store';

const validateLogin = (login) => {
    let mode = '';
    if (validator.isEmail(login)) mode = 'email';
    if (validator.isMobilePhone(login)) mode = 'mobile';
    return mode;
}

const callPasswordrecovery = (state, values, enqueueSnackbar) => {
    const mode = validateLogin(values.login);
    if (!mode) {
        return enqueueSnackbar(`Invalid ${values.tabIndex == 0 ? 'email addrass.' : 'phone number.'}`, { variant: 'error', autoHideDuration: 3000 })
    }
    if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(state.recaptchaSiteKey, { action: "homepage" })
                .then(captcha => {
                    let postData = {
                        login: values.login,
                        mode
                    };
                    const headers = { captcha }
                    axios.post(process.env.REACT_APP_API_HOST + '/api/users/passwordrecovery', postData, { headers })
                        .then((res) => {
                            res = res.data;
                            res.message = res.message || 'No Message';
                            if (res.success) {
                                if (res.code == 200) {
                                    enqueueSnackbar(res.message, { variant: 'success', autoHideDuration: 3000 })
                                } else {
                                    enqueueSnackbar(res.message, { variant: 'warning', autoHideDuration: 3000 })
                                }
                            } else {
                                enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 3000 })
                            }
                        })
                        .catch(err => {
                            enqueueSnackbar('Fatal Error', { variant: 'error', autoHideDuration: 3000 })
                        })
                })
        })
    } else {
        enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <div>{children}</div>
            )
            }
        </div>
    );
}

const ForgotPassword = (props) => {
    const { setView } = props;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState({
        login: '',
        tabIndex: 0,
        phone: '',
        countryCode: ''
    })

    const handleShowLoginHelp = () => {
        // setView('help');
        setView('loginSignup')
    }

    useEffect(() => {
        $('#DivForgotPassword').animate({
            opacity: 1
        }, 300)
    }, [])

    return (
        <div id='DivForgotPassword' className='pt-2' style={{ opacity: 0, display: 'block', height: '13.6rem' }}>
            <MDBRow className='mx-0'>
                <MDBCol size='12' className='p-0'>
                    <span className='cursor-pointer text-neon' onClick={handleShowLoginHelp} >
                        <ChevronLeftIcon />
                        <small style={{ fontSize: '0.6rem' }}>Back to Login/SignUp</small></span>
                </MDBCol>
            </MDBRow>
            <MDBRow className='mx-0 pt-2'>
                <MDBCol size='12' className='p-0'>
                    <AppBar position="static" color="transparent">
                        <Tabs sx={{
                            '.MuiTabs-indicator': {
                                width: '100%',
                                backgroundColor: '#26ddff'
                            },
                        }} value={values.tabIndex} onChange={(event, newValue) => { setValues({ ...values, login: '', tabIndex: newValue }); }}>
                            <Tab className='w-50' sx={{ fontSize: '0.7rem' }} label="Email" />
                            <Tab className='w-50' sx={{ fontSize: '0.7rem' }} label="Mobile" />
                        </Tabs>
                    </AppBar>
                </MDBCol>
                <form onSubmit={e => { e.preventDefault(); callPasswordrecovery(state, values, enqueueSnackbar); }}>
                    <MDBCol size='12' className='py-3'>
                            <TabPanel value={values.tabIndex} index={0} >
                                <ThemeInput label='Email' labelStyle={{ background: '#121212' }} value={values.login} onChange={e => setValues({ ...values, login: e.target.value })}></ThemeInput>
                            </TabPanel>
                            <TabPanel value={values.tabIndex} index={1} >
                                <ThemePhoneInput
                                    label='Phone'
                                    value={{ phone: values.phone, countryCode: values.countryCode }}
                                    onChange={e => { setValues({ ...values, login: e.countryCode + e.phone, phone: e.phone, countryCode: e.countryCode }) }}>
                                </ThemePhoneInput>
                            </TabPanel>
                    </MDBCol>
                    <MDBCol size='12' className='text-center'>
                        <ThemeButton type='submit' variant='outlined' className='text-neon rounded-pill px-3 py-1'>Recover</ThemeButton>
                    </MDBCol>
                </form>
            </MDBRow>
        </div>
    )
}

export default ForgotPassword
