import React, { useContext, useEffect, useRef, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Avatar, Grid, LinearProgress, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import moment from 'moment';
import { Context } from '../../store';
import $ from 'jquery';
import { getBearer } from '../../helpers/publicHelper';
import axios from 'axios';
import { chatSocket } from './../../context/socket';

const getMessages = (state, dispatch, setChat) => {
    axios.post(state.active.host + '/api/users/chats', {
        limit: 50,
        skip: 0
    }, {
        headers: { 'x-auth-token': getBearer() }
    })
        .then(res => {
            res = res.data;
            if (res.success) {
                const chat = res.result[0];
                const lastSeenAt = chat.members[state.user.profile._id].lastSeenAt; // new Date(1641891563000);
                const newMessages = chat.messages.filter(m => {
                    const messageDate = new Date(m.createdAt);
                    return messageDate > lastSeenAt
                });
                setChat({
                    ...res.result[0]
                });
                // dispatch({
                //     type: 'setAll', payload: {
                //         notification: {
                //             ...state.notification,
                //             assist: newMessages.length
                //         }
                //     }
                // })
                setTimeout(() => {
                    $('#ChatMessagesWrapper').animate({
                        scrollTop: 20000
                    }, 50);
                }, 2000);
            } else {
                console.log(res.message)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

const Assist = () => {
    const [state, dispatch] = useContext(Context);
    const [chat, setChat] = useState({
        members: {},
        messages: [],
    })

    const addMessage = (message) => {
        chatSocket.emit('chat', {
            ...message
        })
    }

    const stateRef = useRef();
    stateRef.current = state;

    const incAssistNotif = () => {
        const currentCount = stateRef.current.notification.assist;
        dispatch({
            type: 'setAll', payload: {
                notification: {
                    ...stateRef.current.notification,
                    assist: currentCount + 1
                }
            }
        })
    }



    const chatRef = useRef();
    chatRef.current = chat;
    useEffect(() => {
        chatSocket.on('chat', message => {
            let cloneMessages = JSON.parse(JSON.stringify(chatRef.current.messages));
            cloneMessages.push(message);
            setChat({ ...chatRef.current, messages: cloneMessages });
            $('#ChatMessagesWrapper').animate({
                scrollTop: 20000
            }, 50);
            incAssistNotif()
        })
        getMessages(state, dispatch, setChat);
    }, [])

    useEffect(() => {
        $('#ChatMessagesWrapper').animate({
            scrollTop: 20000
        }, 50);
    }, [chat, state.active.rightTab])

    return (
        <>
            <MDBRow className='mx-0 position-relative'>
                <MDBCol size='12' id='ChatMessagesWrapper' className='bg-black noScrollbar py-2 mb-2' style={{ height: '500px', overflow: 'auto' }}>
                    {chat && chat.messages.map((message, i) => {
                        const { text, updatedAt } = message;
                        const sender_id = message.sender ? message.sender._id : '';
                        const sender = chat.members[sender_id] || {
                            nickname: '',
                            avatar: '',
                        }
                        const { nickname, avatar } = sender;
                        const isUser = sender_id == state.user.profile._id;
                        const bgColor = isUser ? blue[400] : '#222';
                        return <MDBRow key={i}>
                            <MDBCol className={isUser ? 'pe-0' : ''}>
                                <Grid className='my-1' container justifyContent='space-between' alignItems='end'>
                                    {!isUser ?
                                        <Grid item xs='auto' className='box-shadow-neon rounded-circle' sx={{ zIndex: 99 }}>
                                            <Avatar alt="admin" sx={{ width: 30, height: 30, 'img': { width: '75%', height: 'auto' } }} src={avatar || "/alien.svg"} />
                                            {/* {avatar || defaultAvatar} */}
                                        </Grid> : <></>
                                    }
                                    <Grid item xs className='p-0 ms-n2  position-relative _overflow-hidden d-flex'>
                                        <svg viewBox="0 0 60 42"
                                            style={{
                                                position: 'absolute',
                                                left: !isUser ? 0 : 'auto',
                                                right: isUser ? 0 : 'auto',
                                                bottom: 0,
                                                height: '1.6rem',
                                                zIndex: 0,
                                                transform: isUser ? 'scaleX(-1)' : 'none'
                                            }}>
                                            <g>
                                                <path fill={bgColor} d="M40 0l25 0 0 0c0,22 -19,42 -42,42l-19 0c23,0 30,-20 30,-42l11 0z"></path>
                                            </g>
                                        </svg>
                                        <Typography
                                            sx={{
                                                width: 'fit-content',
                                                maxWidth: '80%',
                                                backgroundColor: bgColor,
                                                zIndex: 9,
                                                marginRight: isUser ? '1.05rem' : 'auto',
                                                marginLeft: !isUser ? '1.05rem' : 'auto',
                                                zIndex: 0,
                                                borderBottomLeftRadius: isUser ? '1.5rem !important' : '1rem !important',
                                                borderBottomRightRadius: !isUser ? '1.5rem !important' : '1rem !important',
                                            }}
                                            variant='caption' className='chatMessage p-2 px-3 rounded-9 position-relative'>
                                            {!isUser ? <span className='text-neon mb-0 d-block'>{nickname || 'iMoon Support'}</span> : <></>}
                                            {text}<small className='small opacity-0 d-list-item float-right px-1' style={{ fontSize: '0.4rem' }}>{moment(updatedAt).format('hh:mm')}</small>
                                            <small className='small position-absolute opacity-60 float-right ps-1' style={{
                                                bottom: '0.5rem',
                                                right: '0.6rem',
                                                fontSize: '0.6rem'
                                            }}>{moment(updatedAt).format('hh:mm')}</small>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MDBCol>
                        </MDBRow>
                    }
                    )}
                    {chat && (chat?.messages?.length == 0) && <LinearProgress color='info' />}
                </MDBCol>
                <SendBox _id={state.user ? state.user.profile._id : '0'} addMessage={addMessage}></SendBox>
            </MDBRow>
        </>
    )
}

const SendBox = (props) => {
    const [state, dispatch] = useContext(Context);
    const { addMessage, _id } = props;
    const [values, setValues] = useState({
        _id: _id,
        text: '',
        time: Date.now()
    })

    const sendMessage = (e) => {
        e.preventDefault();
        addMessage(values);
        setValues({ ...values, text: '' })
    }

    return <div className='p-0 pb-4 pb-md-2 pt-1 box-shadow-neon' style={{
        backgroundColor: '#222',
        bottom: 0,
        left: state.active.layoutDrawer.left ? '13rem' : 0,
        right: 0,
        zIndex: 99,
        width: '100%'// state.active.layoutDrawer.left ? 'calc(100vw - 13rem)' : '100vw',
    }}>
        <form className='position-relative d-flex mt-1' style={{ alignItems: 'center' }} onSubmit={sendMessage}>
            <MDBIcon icon='paperclip' className='px-2 d-inline-block text-white opacity-50' style={{ fontSize: '1.2rem' }}></MDBIcon>
            <div className='position-relative d-inline-block bg-black rounded-pill pe-4' style={{ flex: 1 }}>
                <input onChange={e => setValues({ ...values, text: e.target.value })} value={values.text} className='bg-transparent d-inline-block border-0 p-2 text-white pe-3 w-100' style={{ outline: 'none', fontSize: '0.8rem' }} />
            </div>
            <button type='submit' className='bg-transparent border-0 p-0' style={{ outline: 'none' }}>
                <MDBIcon icon='arrow-alt-circle-up' className={'pt-0 font-weight-light mx-2 d-inline-block text-neon _border _border-neon text-shadow-neon rounded-circle ' + (!values.text ? 'opacity-30' : '')} style={{ fontSize: '1.4rem' }}></MDBIcon>
            </button>
        </form>
    </div>
}

export default Assist
