import React, { useContext, useEffect, useState } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AppBar, Box, Divider, LinearProgress, List, ListItemButton, Modal, Toolbar, Typography } from '@mui/material';
import { Context } from '../../../store';
import DepositIcon from '@mui/icons-material/Download';
import WithdrawIcon from '@mui/icons-material/Upload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import MiniProfile from '../../user/MiniProfile';
import Profile from '../../user/Profile';
import Deposit from '../../depositWithdraw/Deposit';
import Withdraw from '../../depositWithdraw/Withdraw';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VerifiedIcon from '@mui/icons-material/Verified';
import Verification from '../../user/Verification';
import Transactions from '../../user/Transactions';
import Referral from '../../user/Referral';
import ModalContent from '../../modal/ModalContent';
import ModalHeaderTabs from '../../modal/ModalHeaderTabs';
import _ from 'lodash';
import { LogoutItem } from './LogoutItem';
import CustomListItem from '../CustomListItem';

const RightDrawerList = (props) => {
    const [state, dispatch] = useContext(Context);

    return (
        <Box>
            <MiniProfile />
            <Divider />
            <List className='p-0'>
                <DepositWithdrawItem text='Deposit' mode={0} icon={<DepositIcon color='success' />} />
                <DepositWithdrawItem text='Withdraw' mode={1} icon={<WithdrawIcon color='danger' />} />
                <Divider />
                <TransactionsItem text='Transactions' mode={0} icon={<ReceiptIcon />} />
                <TransactionsItem text='Bet History' mode={1} icon={<ManageSearchIcon />} />
                {/* <CustomListItem text='Transactions' icon={<ReceiptIcon />} /> */}
                {/* <CustomListItem text='Bet History' icon={<ManageSearchIcon />} /> */}
                <Divider />
                <ProfileItem text='Profile' icon={<AccountCircleIcon />} />
                <VerificationItem text='Verification' icon={<VerifiedIcon />} />
                <ReferralItem text='Referral' icon={<GroupsIcon />} />
                <Divider />
                <LogoutItem />
            </List>
        </Box>
    )
}



const ProfileItem = (props) => {
    const { icon } = props;
    const [values, setValues] = useState({
        open: false,
        mode: 0
    })

    return <>
        <CustomListItem onClick={() => setValues({ open: true, mode: 0 })} {...props} />

        <Modal
            open={values.open}
            disableScrollLock={true}
            onClose={() => setValues({ open: false, mode: 0 })}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                minHeight: '300px', 
                outline:'none'
            }}>
                <AppBar position="static" color="transparent" className='box-shadow-neon'>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Typography variant='body1'>
                            Profile
                        </Typography>
                        <HighlightOffIcon className='cursor-pointer' color='danger' fontSize='small' onClick={() => setValues({ open: false, mode: 0 })} />
                    </Toolbar>
                </AppBar>
                <Profile />
            </Box>
        </Modal>
    </>
}

const VerificationItem = (props) => {
    const { icon } = props;
    const [state, dispatch] = useContext(Context);

    const [values, setValues] = useState({
        open: false,
        mode: 0
    })
    const kycScore = Math.round(state.user ? state.user.kyc.score : 0);
    let kycColor =
        kycScore < 10 ? 'danger' :
            kycScore < 20 ? 'orange' :
                kycScore < 40 ? 'warning' :
                    kycScore < 60 ? 'info' :
                        kycScore <= 90 ? 'neon' : 'success';
    return <>
        <CustomListItem onClick={() => setValues({ open: true, mode: 0 })} {...props} />

        <Modal
            open={values.open}
            disableScrollLock={true}
            onClose={() => setValues({ open: false, mode: 0 })}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                minHeight: '300px'
            }}>
                <AppBar position="static" color="transparent" className='box-shadow-neon'>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Typography variant='body1'>
                            Verification
                        </Typography>
                        <HighlightOffIcon className='cursor-pointer' color='danger' fontSize='small' onClick={() => setValues({ open: false, mode: 0 })} />
                    </Toolbar>
                </AppBar>
                <Box className='d-flex justify-content-between align-items-center pt-3 px-3'>
                    <Box className='w-100'>
                        <LinearProgress variant="determinate" color={kycColor} value={state.user ? state.user.kyc.score : 0} />
                    </Box>
                    <Typography
                        variant='caption'
                        component='small'
                        className='ps-2'
                        sx={{
                            color: 'info'
                        }}>{`${kycScore}%`}</Typography>
                </Box>
                <Verification />
            </Box>
        </Modal>
    </>
}

const DepositWithdrawItem = (props) => {
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(props.mode || 0);
    const tabs = ['Deposit', 'Withdraw'];

    return <>
        <CustomListItem onClick={() => setOpen(true)} {...props} />

        <Modal
            open={open}
            disableScrollLock={true}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                minHeight: '35rem',
                maxHeight: '70vh',
            }}>
                <ModalContent
                    setOpen={setOpen}
                    header={<ModalHeaderTabs tabs={tabs} setTab={setTab} tab={tab} />}>
                    {tab == 0 && <Deposit />}
                    {tab == 1 && <Withdraw />}
                </ModalContent>
            </Box>
        </Modal>
    </>
}

const TransactionsItem = (props) => {
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(props.mode || 0);
    const tabs = ['Transactions', 'Bet History'];

    return <>
        <CustomListItem onClick={() => setOpen(true)} {...props} />

        <Modal
            open={open}
            disableScrollLock={false}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: ['0', '50%'],
                left: [0, '50%'],
                transform: ['translate(0, 0rem)', 'translate(-50%, -50%)'],
                width: ['100vw', '350px'],
                minHeight: '35rem',
                height: ['calc(100vh)', '70vh'],
            }}>
                <ModalContent
                    setOpen={setOpen}
                    header={<ModalHeaderTabs tabs={tabs} setTab={setTab} tab={tab} />}>
                    {tab == 0 && <Transactions type={['deposit', 'withdraw', 'topup']} />}
                    {tab == 1 && <Transactions type={['bet']} />}
                </ModalContent>
            </Box>
        </Modal>
    </>
}

const ReferralItem = (props) => {
    const [open, setOpen] = useState(false);

    return <>
        <CustomListItem onClick={() => setOpen(true)} {...props} />

        <Modal
            open={open}
            disableScrollLock={true}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                maxHeight: '70vh',
            }}>
                <ModalContent
                    setOpen={setOpen}
                    header={
                        <Box className='ps-2 d-flex w-100 justify-content-start align-items-center position-relative'>
                            <span>
                                Referral
                            </span>
                        </Box>
                    }
                // header={<ModalHeaderTabs tabs={tabs} setTab={setTab} tab={tab} />}
                >
                    <Referral />
                </ModalContent>
            </Box>
        </Modal>
    </>
}


export default RightDrawerList
