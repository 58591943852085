import React from 'react'

const Stats = () => {
    return (
        <iframe style={{
            width: '100%',
            height: 'calc(100vh - 70px)'
        }} src='https://stats.imoon.com'></iframe>
    )
}

export default Stats
