import { Grid, List, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdb-react-ui-kit'

const PrivacyPolicy = () => {
    return (
        <MDBCard className='mx-2' style={{ backgroundColor: '#24232999', minHeight: 'calc(100vh - 60px)' }}>
            <MDBCardBody className='p-2 text-gray'>
                <Grid container>
                    <Grid item xs={12}>
                        <Title index='1' title='iMoon.com' />
                        <Content>
                            iMoon B.V. (hereinafter “Company,” “iMoon” or “Website”) is registered under the Commercial Register of Curaçao under registration number 158383, with an office address at Emancipate Boulevard Dominico F Don Martina, 31, Curaçao, duly licensed through a sublicense granted by C.I.L. Curaçao Interactive Licensing N.V., pursuant to Master Gaming License #5536/JAZ issued by the Governor of Curaçao.
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='2' title='Privacy Policy' />
                        <Content>
                            <Box>
                                <InlineTitle index='2.1.'>
                                    This Privacy Policy provides how iMoon will manage a User’s personal information as a result of:
                                    <ul className='pt-3'>
                                        <li>The User being a registered member on iMoon’s website;</li>
                                        <li>The User’s consumption of the iMoon website or Websites under the iMoon brand, including any mobile phone, tablet or applications that are owned and/or operated by iMoon; or</li>
                                        <li>Whenever iMoon otherwise deals with the User.</li>
                                    </ul>
                                </InlineTitle>
                                <InlineTitle index='2.2.'>
                                    All references to “Player” or “User” refers to any person who utilizes, plays or uses iMoon’s service(s) and/or product(s).
                                </InlineTitle>
                                <InlineTitle index='2.3.'>
                                    “Games” or “the Games” (whether capitalized or not) refers to any of the gaming products or services offered on iMoon’s website.
                                </InlineTitle>
                                <InlineTitle index='2.4.'>
                                    iMoon is committed to ensuring that the personal information collected about a User is protected and is used, stored and disclosed in accordance with this Privacy Policy.
                                </InlineTitle>
                            </Box>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='3' title='Using the Website' />
                        <Content>
                            <InlineTitle index='3.1.'>
                                In using the Website, the User agrees to be bound by the terms of this Privacy Policy. If a User does not agree with any part of this Privacy Policy, the User must not use or continue to use any iMoon website(s).
                            </InlineTitle>
                            <InlineTitle index='3.2.'>
                                iMoon reserves the right to amend this Privacy Policy at any time and without advance notice. In the case of any amendments to this Agreement, iMoon is not required to, but will endeavor to take steps to bring such amendments/changes to a User's attention (such as for example via email or placing a notice on a Website). If a User continues using iMoon services after any amendment, such action will be deemed as the User's acceptance of the amendment and will be bound by the changes, updates and/or modifications in the amendment whether or not a User has been notified, seen or read the amendment(s).
                            </InlineTitle>
                            <InlineTitle index='3.3.'>
                                This Privacy Policy will not apply to websites that are linked to the Website and over which iMoon has no control.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='4' title='Personal Information' />
                        <Content>
                            <InlineTitle index='4.1.'>
                                The kind of personal information that iMoon may collect from a User includes but is not limited to:
                                <ul className='pt-3'>
                                    <li>Full name (First, Middle, Last);</li>
                                    <li>Permanent residential address;</li>
                                    <li>Telegram unique ID;</li>
                                    <li>Date and place of birth;</li>
                                    <li>Nationality;</li>
                                    <li>Identity reference/passport number;</li>
                                    <li>Phone number;</li>
                                    <li>Email address;</li>
                                    <li>Confirmation of 18+ age;</li>
                                    <li>Personally submitted preferences;</li>
                                    <li>Location data;</li>
                                    <li>Device information; and</li>
                                    <li>IP address.</li>
                                </ul>
                            </InlineTitle>
                            <InlineTitle index='4.2.'>
                                The Company collects, or may collect, such information through methods including:
                                <ul className='pt-3'>
                                    <li>Data input during account registration processes; and</li>
                                    <li>Communications with Users.</li>
                                </ul>
                            </InlineTitle>
                            <InlineTitle index='4.3.'>
                                iMoon reserves the right to also collect documents and proofs to verify the User’s account, to process deposits or withdrawals and to conduct anti-fraud checks (on iMoon’s own initiative or as required by applicable legislation). Such proofs may include passport scans, payment slips, bank statements, a photo with required documents and passport, etc.                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='5' title='Collection Purposes' />
                        <Content>
                            <InlineTitle index='5.1.'>
                                iMoon will attempt to collect a User’s personal information in a non-intrusive way, in order to operate its business as a licensed online casino for the following purposes, including but not limited to:
                                <ul className='pt-3'>
                                    <li>For Know Your Customer (KYC) identity verification;</li>
                                    <li>In processing a User’s application when opening an account on an iMoon website or any managed subdomains;</li>
                                    <li>In providing information to improve services to a User;</li>
                                    <li>To identify a User as a member, authenticate a User's identity for security purposes, and/or comply with iMoon's legal obligations;</li>
                                    <li>To maintain a User’s account;</li>
                                    <li>To upgrade and enhance a User’s experience within a Website(s) or application(s), or to tailor or develop information, services or products to suit a User’s needs, which may include market researching and conducting promotions;</li>
                                    <li>To create aggregate data about members through demographic profiling, statistical analysis of the database, and to allow for more efficient operation of iMoon’s business.</li>
                                    <li>To respond to a User’s questions, comments or requests;</li>
                                    <li>To determine members’ liability to pay legally required taxes of any kind, where applicable;</li>
                                    <li>To comply with iMoon’s contractual, legal and statutory obligations;</li>
                                    <li>For taking appropriate action if iMoon has reason to suspect that unlawful activity or misconduct of a serious nature has been, is being or may be engaged in that relates to iMoon functions and activities;</li>
                                    <li>To establish, exercise or defend any legal claims; and</li>
                                    <li>To process a User’s bets and transactions, including the User’s credit card usage and online payment systems.</li>
                                </ul>
                            </InlineTitle>
                            <InlineTitle index='5.2.'>
                                iMoon will share a User’s data with its payment processors to facilitate payment transactions in accordance with their privacy policies.
                            </InlineTitle>
                            <InlineTitle index='5.3.'>
                                If a User does not provide iMoon with the requested personal information, iMoon may be unable to process a User application to become a member, provide the User with iMoon’s services or respond to a User’s inquiry or complaint.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='6' title='Use of Information' />
                        <Content>
                            <InlineTitle index='6.1.'>
                                By using iMoon, becoming a member or otherwise providing iMoon with personal information, a User consents to iMoon collecting, using, processing and potentially disclosing a User’s personal information for the purposes stated in clause 5 and on the bases described in this Privacy Policy, such as:
                                <ul className='pt-3'>
                                    <li>Processing the User’s bets and transactions;</li>
                                    <li>Verifying the User account information by sending OTP (on-time password) directly or through third-party channels;</li>
                                    <li>Providing the User with gaming and other ancillary services;</li>
                                    <li>Rendering customer support, such as assistance with setting up and managing the User account; and</li>
                                    <li>Undertaking necessary measures to secure the User account and to identify the required verification checks.</li>
                                </ul>
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='7' title='Direct Marketing and Opting Out' />
                        <Content>
                            <InlineTitle index='7.1.'>
                                From time to time, iMoon may use a User’s personal information to inform the User about iMoon’s products or services or about promotional activities which iMoon believes may be of interest or benefit to a User. iMoon may do this via email, SMS, telephone or email. If the User no longer wishes to receive marketing or promotional material from iMoon at all or in any particular form, the User may contact iMoon at any time by email to support@imoon.com with the request with which iMoon will comply as soon as is practical.                            </InlineTitle>
                            <InlineTitle index='7.2.'>
                                From time to time, iMoon may contact a User about the management and administration of the User’s iMoon account. These communications can be via any modes of contact recorded when registering as a member. Such communication does not affect a User’s opt-in or opt-out status for direct marketing communications.                            </InlineTitle>
                            <InlineTitle index='7.3.'>
                                By accepting any contest prize or winnings from iMoon, a User consents to the use of their name and/or nickname for advertising and promotional purposes without additional compensation, except where is prohibited by law.                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='8' title='Management of the Personal Information' />
                        <Content>
                            <InlineTitle index='8.1.'>
                                iMoon will take all reasonable steps to ensure that any collected, used or disclosed personal information is stored in a secure environment that is accessed only by authorized persons. iMoon stores received personal information as described in this Privacy Policy for as long as a User uses iMoon’s services or as necessary to fulfill the purpose(s) for which it was collected, provide iMoon’s services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce iMoon’s agreements and to comply with applicable laws.
                            </InlineTitle>
                            <InlineTitle index='8.2.'>
                                iMoon will destroy or permanently de-identify stored personal information when it is no longer required for any purpose permitted, including iMoon legal or operational obligations.
                            </InlineTitle>
                            <InlineTitle index='8.3.'>
                                A User can request to have their personal data deleted if iMoon no longer has a legal reason to continue to process or store it. A User can request the deletion of their personal data by emailing support@imoon.com.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='9' title='Security of Personal Information' />
                        <Content>
                            <InlineTitle index='9.1.'>
                                The User acknowledges that no data transmission over the internet is totally secure. Accordingly, iMoon does not guarantee or warrant the security of any information which a User transmits. Any information a User transmits to iMoon is transmitted at a User’s own risk. However, once iMoon receives the transmission, it will take reasonable steps to protect the User’s personal information from misuse, loss and unauthorized access, modification and disclosure including by using password-protected systems and databases and Secure Socket Layer (SSL) technology. By using iMoon services or providing personal information to iMoon, a User agrees that iMoon may communicate with the User electronically regarding security, privacy, and administrative issues relating to the User’s use of the services.
                            </InlineTitle>
                            <InlineTitle index='9.2.'>
                                iMoon’s employees, agents and contractors are required to maintain the confidentiality of members’ personal information and trading behavior. Information posted on bulletin boards or communicated within a social media environment (for example, Facebook, Twitter, Chat Rooms) becomes public information. iMoon cannot guarantee the security of this type of disclosed information.
                            </InlineTitle>
                            <InlineTitle index='9.3.'>
                                iMoon takes seriously the responsibility to exclude children from accessing any Website services. iMoon will not accept a minor’s information for opening an account. However, it is ultimately the responsibility of parents or guardians to monitor their children’s internet activities.
                            </InlineTitle>
                            <InlineTitle index='9.4.'>
                                iMoon advises Users to always log out of their account(s) when a User has completed their time on a Website. This is particularly important if a User shares a computer with another person. The User is responsible for securing and accessing their own computer, mobile device or any other handset used to access the website.
                            </InlineTitle>
                            <InlineTitle index='9.5.'>
                                A User is solely responsible for maintaining the secrecy of their username, password and any account information.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='10' title='Access to Personal Information' />
                        <Content>
                            <InlineTitle index='10.1.'>
                                A User may access personal information collected by iMoon by following the “settings” link on a Website or by contacting support@imoon.com. iMoon will grant the User access to the User’s personal information in the manner requested and within a reasonable period, if possible.
                            </InlineTitle>
                            <InlineTitle index='10.2.'>
                                If iMoon refuses a User’s request or cannot give access in the manner a User has requested, iMoon will endeavor to meet a User’s requirements by other means. iMoon may not be able to give a User access to their personal information where it may have an unreasonable impact on another individual's privacy. If iMoon refuses access for such reasons, the User will be advised in writing about the refusal, the iMoon’s reasons and the complaint mechanisms will be available to the User.
                            </InlineTitle>
                            <InlineTitle index='10.3.'>
                                If a User has any queries, requests for access or correction or complaints relating to handling of their personal information, please contact iMoon by email at support@imoon.com or by the chat on the website.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='11' title='Cookies' />
                        <Content>
                            <InlineTitle index='11.1.'>
                                By registering an account and using iMoon services, a User acknowledges that iMoon will use and collect cookies.
                            </InlineTitle>
                            <InlineTitle index='11.2.'>
                                Cookies are small text files stored on a User’s computer or equipment when a User visits an iMoon website. Some of the cookies are essential for the Website to operate; others improve a User’s experience and help iMoon deliver a better service.
                            </InlineTitle>
                            <InlineTitle index='11.3.'>
                                iMoon’s partners also provide advertising cookies to track Website visits and new User registrations. iMoon does not share a User’s personal information (such as name or email) to affiliated partners, except for site visit data collected directly by such advertising cookies. However, a User’s site visit data may be linked with other personal information collected through third-party providers. The latter external data processing is governed by the privacy policies of these third-party providers.
                            </InlineTitle>
                            <InlineTitle index='11.4.'>
                                Most online browsers automatically accept cookies. It is possible for the User to block some or all cookies or to delete cookies that have already been set by modifying the User’s browser settings. However, iMoon recommends that a User does not block or delete the cookies, as this may restrict their use of iMoon’s website.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='12' title='International Data Transfers' />
                        <Content>
                            <InlineTitle index='12.1.'>
                                12.1.	All information processed by iMoon may be transferred, processed, and stored anywhere in the world, including but not limited to other countries, which may have data protection laws that differ from the laws where the User lives. iMoon endeavors to safeguard the User information consistent with the requirements of applicable laws. The laws of these jurisdiction may affect the ability of iMoon to delete or maintain User data.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='13' title='Personal Information Disclosure in Case of Fraud' />
                        <Content>
                            <InlineTitle index='13.1.'>
                                If iMoon finds out a User’s involvement into any type of fraudulent actions, including but not limited to game manipulations, payment fraud, provision of a faulty personal information, money laundering or use of stolen cryptos, iMoon reserves the right to disclose a User's personal information to third parties or to the competent authorities, to protect the legitimate interests of the Company, its customers or any third party.
                            </InlineTitle>
                            <InlineTitle index='13.2.'>
                                The User’s data shall also be disclosed if required by competent authorities in cases of inquiries regarding financial and fiscal records, fraud, money laundering or investigations into any other illegal activity.
                            </InlineTitle>
                            <InlineTitle index='13.3.'>
                                iMoon uses special anti-fraud methods to prevent any type of financial fraud on the Website. Any attempts to take fraudulent actions will lead to immediate termination of the User's account. iMoon reserves the right to determine any claimed bonuses or promotions, competition entries or accounts which connected to the same person, using all the appropriate means.
                            </InlineTitle>
                        </Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Title index='14' title='Legal Basis for Processing Information' />
                        <Content>
                            <InlineTitle index='14.1.'>
                                iMoon has many different legal responsibilities and obligations, some of which are based on the following:
                                <InlineTitle index='14.1.1.'>
                                    Legal compliance with Anti-Money Laundering (AML) laws and the combating of the financing of terrorism (CFT) laws.
                                </InlineTitle>
                                <InlineTitle index='14.1.2.'>
                                    Monitoring and investigating transactions for the purposes of preventing fraud, terms abuse, money laundering and other illegal or irregular gaming activities.
                                </InlineTitle>
                                <InlineTitle index='14.1.3.'>
                                    To comply with iMoon’s legal and regulatory duties and responsibilities to the relevant licensing and regulatory authorities, as well as all duties and responsibilities owed under any other applicable legislation and to any other applicable regulators in other relevant jurisdictions.
                                </InlineTitle>
                            </InlineTitle>
                        </Content>
                    </Grid>

                </Grid>
                {/* <MDBRow className='text-gray'>
                    <MDBCol xs='12'>
                    </MDBCol>
                    <MDBCol>
                        <div>
                            <h6 className='text-white mt-5'>PRIVACY POLICY</h6>
                            <p>
                                This is the privacy policy of iMoon Softwares N.V. (owner and operator of iMoon.com (http://iMoon.com/)) This Privacy Policy sets out how iMoon.com will manage your personal information which we collect as a result of your membership of iMoon, your use of our websites located at iMoon.com and any pages or websites under the ’iMoon’ brand including any mobile phone or tablet applications that are owned and/or operated by us or whenever we otherwise deal with you. We are committed to ensuring that the personal information we collect about you is protected and is used, stored and disclosed in accordance with the Privacy Act and this Privacy Policy.
                            </p>

                            <h6 className='text-white mt-5'>GENERAL TERMS</h6>

                            <p>
                                Before using our website, please read this Privacy Policy carefully. We have a legal obligation under gambling regulations to process personal data from players in order to allow them to participate in games and provide them with services. This Privacy Policy explains what personal data we collect from users, for what reason we collect it and how we use it.
                            </p>
                            <p>
                                By registering a player account you confirm your consent with this Privacy Policy. If you do not agree with the terms of this Privacy Policy and do not wish to provide us with the personal information we require, please do not use this website. Please note that this Privacy Policy constitutes an agreement between you and the company. We may periodically make modifications to this Policy. While we will do our best to notify you of such changes, we recommend that you revisit this Privacy Policy regularly. Your continued use of the Website and/or its services will constitute your consent to the Privacy Policy.
                            </p>

                            <h6 className='text-white mt-5'>INFORMATION COLLECTED</h6>

                            <p>
                                The kinds of Personal Information that iMoon.com (http://iMoon.com/) may collect from you is:
                            </p>

                            <ul>
                                <li>Name</li>
                                <li>Email Address</li>
                                <li>Personally Submitted Preferences</li>
                                <li>Date of Birth</li>
                                <li>Country of Citizenship</li>
                                <li>Physical Address</li>
                                <li>Identification Number</li>
                                <li>Government Issued Identification</li>
                                <li>Location Data</li>
                                <li>Device Information</li>
                                <li>IP Address</li>
                            </ul>

                            The Company collects, or may collect, such information through methods including:
                            <ul>
                                <li>Data input during account registration processes</li>
                                <li>Communications with Customers</li>
                            </ul>

                            The Personal Information which we may request to use and process shall include, without limitation:
                            <ol>
                                <li>Any of the information that you provide to us when filling in the forms on our account registration pages, as well as any other data that you further submit via the Website or email.</li>
                                <li>Correspondence made with us via the Website, e-mail, web chat or through other means of communication.</li>
                                <li>All Player Account transaction history, whether this takes place via the Website or via other means of communication.</li>
                                <li>Website logins and their details, including traffic data, GeoIP location data, browser/device data, weblogs, activity logs and other traffic information recorded in our system.</li>
                                <li>Survey participations or any other customer assessments that we may carry out from time to time.</li>
                            </ol>
                            <h6 className='text-white mt-5'>USE OF INFORMATION</h6>

                            <p>
                                iMoon.com (http://iMoon.com/) will collect your Personal Information only by lawful and fair means and not in an intrusive way to operate its business as a licensed online wagering bookmaker we also process the Personal Information we collect from you in order to deliver our services. In particular and we will use your data for the following purposes:
                            </p>

                            for the following purposes:
                            <ul>
                                <li>For know-your-customer (KYC) identity verification.</li>
                                <li>If you wish to subscribe to iMoon.com (http://iMoon.com/)’s marketing communications.</li>
                                <li>To process your application to become a Member of iMoon Softwares or any managed subdomains.</li>
                                <li>To provide and improve services to you as a Member.</li>
                                <li>To identify you as a Member and authenticate your identity for security purposes and to comply with our legal obligations.</li>
                                <li>To maintain your Member account.</li>
                                <li>To upgrade and enhance your experience within the website or over the telephone, or to tailor or develop information, services or products to suit your needs which may include market research and conducting promotions.</li>
                                <li>To tell you about our products or services that we think may be of interest to you by communicating with you via email, SMS or telephone.</li>
                                <li>To create aggregate data about Members through demographic profiling, statistical analysis of the database to provide to potential and existing stakeholders, and to allow for more efficient operation of iMoon.com (http://iMoon.com/)’s business.</li>
                                <li>To respond to your questions, comments, or requests.</li>
                                <li>To determine Members' liability to pay goods and services tax and other taxes where applicable.</li>
                                <li>To comply with iMoon.com (http://iMoon.com/)’s contractual, legal, and statutory obligations.</li>
                                <li>For taking appropriate action if iMoon.com (http://iMoon.com/) has reason to suspect that unlawful activity or misconduct of a serious nature has been, is being or may be engaged in that relates to our functions and activities.</li>
                                <li>To establish, exercise or defend any legal claims.</li>
                                <li>To manage job applications.</li>
                            </ul>
                            <p>
                                If you do not provide iMoon.com (http://iMoon.com/) with the Personal Information we request, we may be unable to process your application to become a Member, provide you with our services or respond to your enquiry or complaint.
                            </p>
                            <p>
                                By using iMoon.com (http://iMoon.com/), becoming a Member or otherwise choosing to provide iMoon.com (http://iMoon.com/) with Personal Information you consent to iMoon.com (http://iMoon.com/) collecting, using, processing and potentially disclosing your Personal Information for the above purposes and on the bases described in this Privacy Policy.
                            </p>

                            <ol start='6'>
                                <li>Processing your bets and transactions.</li>
                                <li>Providing you with gaming and other ancillary services that you seek from our Website.</li>
                                <li>Rendering customer support, such as assistance with setting up and managing your account.</li>
                                <li>Undertaking necessary measures for User Account security and to identify the required verification checks.</li>
                                <li>Providing registered players with information about our promotional offers, or providing promotional information from our selected business partners, associates and affiliates (only if players specifically consented to receiving such marketing material).</li>
                                <li>Monitoring and investigating transactions for the purposes of preventing fraud, terms abuse, money laundering and other illegal or irregular gaming activities.</li>
                                <li>Analyzing customer trends through market study assessments (participation in surveys is not obligatory and you can always choose not to take part).</li>
                                <li>Conducting research and statistical analysis of aggregated data.</li>
                            </ol>

                            <h6 className='text-white mt-5'>PROMOTIONAL</h6>
                            <p>
                                Unless you have elected not to receive promotional materials, we may use your Personal Information, including your email address and phone number, to send you marketing communications regarding products, services and promotions. This may include information about products and services from our business partners. Whenever you decide to stop receiving such marketing and advertising material, you may opt out of this in your Account settings or by contacting our customer support at info@iMoon.com
                            </p>
                            <p>
                                Additionally, note that by accepting any contest prize or winnings from us, you consent to the use of your name and/or nickname for advertising and promotional purposes without additional compensation, except where prohibited by law.
                            </p>

                            <h6 className='text-white mt-5'>PERSONAL INFORMATION</h6>
                            <p>
                                We should not collect any Personal Information about you without your knowledge. We may, however, automatically collect certain data about you where you would have provided such information through the use of our services and through your interactions with us.
                            </p>
                            <p>
                                We may also lawfully receive certain Personal Information from online vendors and service providers. In addition, we retain the right to engage the services of third-party providers to render technical support, so as to process your online transactions and source gaming content.
                            </p>
                            <p>
                                Please understand that we may be granted access to any information you may provide to such vendors, service providers and third-party e-commerce services. Rest assured that we will use and safeguard any Personal Information so obtained, as set out in this Policy. Any information that you provide will only be disclosed to third parties outside the Company in accordance with this Privacy Policy and we shall take any necessary steps to ensure that our agreements with third-party service providers always protect your private information.
                            </p>

                            <h6 className='text-white mt-5'>DATA RECIPIENTS</h6>
                            <p>
                                We may pass information that you have given us to other entities within our group of companies and to our business partners. These companies include our parent companies and all of the subsidiaries of these respective companies, as well as other companies with whom we carry out business and hold necessary agreements. We shall always use our best efforts to ensure that your information and data is treated securely and in accordance with this Privacy Policy.
                            </p>

                            <h6 className='text-white mt-5'>RELEASING DATA TO THIRD PARTIES</h6>

                            <p>
                                We do not sell or rent your personal data to third parties. We may also disclose your personal information to a regulatory or law enforcement agency if we believe it to be necessary to protect the legitimate interests of the Company, its customers or any third party.
                            </p>
                            <p>
                                We use third-party data processors to process limited personal data on our behalf. Such service providers support the Website, especially relating to hosting and operating the websites, marketing, analytics, improving the websites, and sending email newsletters. We shall ensure that the transfer of the Personal Data to the recipient is compliant with applicable Data Protection Legislation and that the same obligations are imposed on the processor as is imposed on us under the respective Services Agreement.
                            </p>
                            <p>
                                Our websites may also include social media features (e.g. “share” or “like” buttons). Such features are provided by third-party social media platforms such as Facebook. Where data is collected this way, its processing is governed by the privacy policy of the respective social media platforms.
                            </p>
                            <p>
                                In addition to the above, we may also release personal data if we acquire any new businesses. Should the Company undergo any changes to its structure such as a merger, acquisition by another company or a partial acquisition, it is most likely that our customers’ personal data will be included within the sale or transfer. We will, as part of our Policy, inform our players by email prior to affecting such transfer of personal data.
                            </p>
                            <p>
                            </p>Please note our content may link to third party websites to provide relevant references. We are not responsible for such external content, which may contain separate privacy policies and data processing disclosures.

                            <h6 className='text-white mt-5'>DATA RETENTION</h6>
                            <p>
                                As stated under our Terms and Conditions both you and we can decide to have your Player Account closed at any time. Following closure of your account, we will retain your personal data on record for as long as required by law. This data shall only be used should it be required by competent authorities in cases of enquiries regarding financial and fiscal records, fraud, money laundering or investigations into any other illegal activity.
                            </p>
                            <p>
                                You are to note that due to anti-money laundering regulations in licensed gaming jurisdictions we are obliged to retain personal data of players submitted during registration and any data passed on during the operative period of a Player Account for a minimum of five years from last player transaction or account closure. Therefore, requests for erasure prior to the lapse of this period cannot be entertained.
                            </p>
                            <p>
                                Being committed to providing secure services to players we will take all reasonable precautions to ensure that all the data that you have submitted via the Website remains safe.
                            </p>
                            <p>
                                Player Accounts can only be accessed with the player’s e-mail and one-time password. You may also set up two-factor authentication (2FA) as additional protection from unauthorized use of your account. You are responsible for keeping your login information confidential and making sure it cannot be accessed by another person.
                            </p>

                            <h6 className='text-white mt-5'>PERSONAL INFORMATION DISCLOSURE IN CASE OF FRAUD</h6>
                            <p>
                                In case if we find out the client's involvement into any type of fraudulent actions, such as game manipulations, payment fraud, provision of a faulty personal information, money laundering, use of stolen credit cards or other, the company reserves t
                            </p>

                            <p>
                                He right to disclose the player's personal information to third parties or make it public.
                            </p>

                            <h6 className='text-white mt-5'>ANTI-FRAUD</h6>

                            <p>
                                We use special anti-fraud methods to prevent any type of financial fraud on the website. Any attempts to take fraudulent actions will lead to immediate termination of the player's account. We reserve the right to determine any claimed bonuses or promotions, competition entries or accounts which connected to the same person, using all the appropriate means. In case any multiple accounts have been used, we immediately abolish any bonuses and bonus winnings, furthermore terminate such accounts and withhold promotional benefits the player ever had.
                            </p>

                            <h6 className='text-white mt-5'>SELLING AND RELEASING OF DATA</h6>

                            <p>
                                We do not sell personal data about our customers to third parties. However, we may release personal data if we acquire any new businesses. Should we undergo any changes to company structure such as a merger, acquisition by another company or a partial acquisition, it is most likely that our customers’ personal data will be included within the sale or transfer. We will, as part of our policy, inform our customers by email prior to affecting such transfer of personal data. We further reserve the right to release personal data when we believe that release is appropriate in order to comply with the law, or to enforce our Terms and Conditions, or to protect our rights, property, or safety, and also for the protection of any other companies within our group of companies.
                            </p>

                            <h6 className='text-white mt-5'>REGULATIONS</h6>

                            <p>
                                We hereby acknowledge that in collecting and processing your Personal Information for the purposes of managing your Member Account, we are bound by strict legal provisions on the protection of personal data. Consequently, we endeavour to protect your personal information and respect your privacy in accordance with best business practices and applicable regulations. In addition and for the avoidance of doubt, nothing in this Privacy Policy shall prevent us from retaining your Personal Information where we are required to do so by law.
                            </p>

                            <h6 className='text-white mt-5'>SECURITY OF PERSONAL DATA</h6>

                            <p>
                                We understand the importance of providing security, confidentiality, integrity of information. We store all personal information we receive directly from you in our secure network behind active up-to-date firewall software. We are committed to providing secure services to players and we will take all reasonable precautions to ensure that all the data that you have submitted via the Website remains safe.
                            </p>
                            <p>
                                The player is responsible for keeping their login information confidential and making sure it cannot be accessed by another person.
                            </p>
                            <p>
                                However, sending information via the internet is usually not completely secure, and we cannot guarantee the security of your data while it is being sent.
                            </p>
                            <h6 className='text-white mt-5'>Supervisor Authority</h6>
                            <p>
                                If you are located in the European Economic Area, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.
                            </p>

                            <h6 className='text-white mt-5'>COOKIES</h6>
                            <p>
                                By registering an Account and using our services you acknowledge that we will use cookies (Cookies are small text files that are stored on your computer or equipment when you visit certain web pages. They record your preferences and send information from your browser in order to help improve your Website experience and deliver a better and more personalized service. Furthermore, some of the cookies we use are essential for the Website to operate) in order to store some information on your computer, laptop, tablet or mobile device when you access our Website. In order to make your visit to the website more user-friendly, to keep track of visits to the website and to improve the service, we collect cookies. Your personal information may be also used to inform you in case of news, changes, new services and promotions that may interest you. If you wish to unsubscribe from such direct marketing, you may contact Customer Support.
                            </p>
                            <p>
                                If you wish to delete any cookies already stored on your computer or stop the cookies that keep track of your browsing patterns on this Website, you can do so by deleting your existing
                            </p>

                            <p>
                                Cookies and managing your browser's privacy settings to block them (Cookies can usually be changed on the “Settings” section of your browser). We recommend that you do not block or delete your cookies as this may restrict your use of our Website.
                            </p>
                        </div>
                    </MDBCol>
                </MDBRow> */}
            </MDBCardBody>
        </MDBCard >


    )
}

const Title = (props) => {
    const { title, index } = props;
    return <Box className={'my-3'}>
        <Typography variant='h6'>
            <span className='d-inline-block' style={{ minWidth: '2rem' }}>{index}.</span>
            {title}
        </Typography>
    </Box>
}

const Content = (props) => {
    const { children } = props;
    return <Box sx={{ paddingLeft: '2rem' }}>
        <Typography variant='body1'>
            {children}
        </Typography>
    </Box>
}

const InlineTitle = (props) => {
    const { children, index } = props;
    return <Box className={'mb-3'}>
        <Typography variant='body1'>
            <span className='d-inline-block' style={{ minWidth: '2rem' }}>{index}</span>
            <p className='d-inline ps-2'>
                {children}
            </p>
        </Typography>
    </Box>
}

export default PrivacyPolicy
