import React, { useState, useEffect, useContext } from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import $ from 'jquery'
import axios from 'axios';
import _ from 'lodash';
import ThemeInput from '../components/ThemeInput';
import ThemeButton from '../components/ThemeButton';
import { useSnackbar } from 'notistack';
import { Context } from '../../store';

const PasswordRecoveryCallback = (props) => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        password: '',
        confirmPassword: '',
        code: window.location.pathname.split('/')[3]
    });

    const submit = () => {
        const { password, confirmPassword, code } = values;
        if (password !== confirmPassword) return enqueueSnackbar('"Password" and "Confirm Password" must be equal.', {
            variant: 'error',
            autoHideDuration: 3000
        });
        if (password.length < 8) return enqueueSnackbar('"Password" must be 8 characters or more.', {
            variant: 'error',
            autoHideDuration: 3000
        });

        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        var postData = _.pick(values, ['password', 'confirmPassword', 'code']);
                        const headers = { captcha };
                        axios.post(process.env.REACT_APP_API_HOST + '/api/users/resetpassword', postData, { headers })
                            .then(res => {
                                res = res.data;
                                if (res.success) {
                                    enqueueSnackbar(res.message, {
                                        variant: 'success',
                                        autoHideDuration: 3000
                                    });
                                    setTimeout(() => {
                                        window.location.href = '/'
                                    }, 2000);
                                } else {
                                    return enqueueSnackbar(res.message, {
                                        variant: 'warning',
                                        autoHideDuration: 3000
                                    });
                                }
                            })
                            .catch((err) => {
                                return enqueueSnackbar(err.message, {
                                    variant: 'error',
                                    autoHideDuration: 3000
                                });
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }


    }

    return (<MDBRow className='justify-content-center'>
        <MDBCol size='12' md='3' xl='2' className='text-center box-shadow-neon rounded-6 pb-4 mt-4'>
            <h6 className='d-inline-block text-neon text-shadow-neon pt-4'>
                Password Recovery
            </h6>
            <form autoComplete="off" id='PasswordRecover' noValidate='noValidate' className='pt-2 text-start' onSubmit={e => { e.preventDefault(); submit() }}>
                <ThemeInput className='mb-3' autoComplete='currenct-password' type='password' onChange={e => setValues({ ...values, password: e.target.value })} label='New Password' value={values.password}></ThemeInput>
                <ThemeInput className='mb-3' autoComplete='new-password' type='password' onChange={e => setValues({ ...values, confirmPassword: e.target.value })} label='Confirm Password' value={values.confirmPassword}></ThemeInput>
                <MDBCol size='12' className='text-center'>
                    <ThemeButton type='submit' className='px-3' variant='outlined'>Reset Password</ThemeButton>
                </MDBCol>
            </form>
        </MDBCol >
    </MDBRow >
    )
}

export default PasswordRecoveryCallback


