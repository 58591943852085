import axios from 'axios';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getBalanceInFiat, getBearer, setActive } from '../helpers/publicHelper';
import { Context } from '../store';
import { Box, useMediaQuery } from '@mui/material';
import ThemeButton from '../layout/components/ThemeButton';
import LayoutProgress from '../layout/LayoutProgress';
import { measureHeight } from 'react-div-100vh'
import $ from 'jquery';

const url = process.env.REACT_APP_CRASH_HOST;

const Crash = () => {
    const [state, dispatch] = useContext(Context);
    const stateRef = useRef();
    stateRef.current = state;
    const { enqueueSnackbar } = useSnackbar();
    const [crashGame, setCrashGame] = useState({
        ready: false,
        url: url
    })

    useEffect(() => {
        if (state.login) {
            setCrashGame({
                ready: false,
                url: url
            })
            axios.post(process.env.REACT_APP_API_HOST + '/api/users/game/getLaunchUrl',
                { provider: 'crash' },
                {
                    headers: { 'x-auth-token': getBearer() }
                })
                .then(res => {
                    res = res.data;
                    if (res.success) {
                        setCrashGame({
                            url: url + `/?token=${res.result.url}`,
                            ready: true
                        })
                    } else {
                        enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2000 });
                    }
                })
                .catch((err) => {
                    setCrashGame({
                        ready: true,
                        url: url
                    })
                })
        } else {
        }
        setActive(state, dispatch, { layoutDrawer: { right: false, left: false } })
    }, [state.login])

    const pushBalance = () => {
        document.getElementById('CrashiFrame').contentWindow.postMessage({
            type: 'IMCRASH:pushBalance',
            balance: getBalanceInFiat(stateRef.current, stateRef.current.user.profile.currency, stateRef.current.active.fiat) || 0,
            currency: stateRef.current.user.profile.currency
        }, '*');
    }

    useLayoutEffect(() => {
        window.addEventListener("message", (m) => {
            if (m?.data?.type == 'IMCRASH:requestPushBalance') {
                pushBalance();
            }
        })
    }, [])

    useEffect(() => {
        pushBalance();
    }, [stateRef.current.user.profile.currency, stateRef.current.active.fiat, state.user.wallet])
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div>

            <iframe id='CrashiFrame'
                className='position-fixed'
                // allowFullScreen
                allow="autoplay; fullscreen"
                // scrolling="no"
                src={crashGame.ready ? crashGame.url : ''}
                style={{
                    top: state.active.showHeader ? 55 : 1,
                    right: 0,
                    width: isMobile ? '100vw' : 'calc(100vw - 4rem)', //state.active.layoutDrawer.left || state.active.layoutDrawer.right ? 'calc(100vw - 13rem)' : '100vw',
                    height: measureHeight() - (measureHeight() - state.active.height),
                    // iOS hack
                    margin: 0,
                    padding: 0,
                    border: 0,
                    opacity: state.login ? 1 : 0
                    // width: '1px',
                    // minWidth: '100%',
                }}
            />
            {state.login ?
                <></>
                :
                <LayoutProgress>
                    {state.config.init &&
                        <div className='position-relative d-flex m-auto' style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center', width: '15rem', height: '20rem' }}>
                            <div style={{ left: 0, right: 0, marginBottom: '0rem' }} className='neonProgress position-absolute mx-auto'></div>
                            {state.login ? <></> :
                                <ThemeButton
                                    // onClick={() => setActive(state, dispatch, { layoutDrawer: { ...state.active.layoutDrawer, right: true } })}
                                    onClick={() => $('#SignInButton').trigger('click')}
                                    variant='outlined' style={{ marginTop: '10rem' }} className={`mx-auto ${state.config.init ? '' : 'd-none'}`}>Login to Play</ThemeButton>
                            }
                        </div>
                    }
                </LayoutProgress>
            }
        </div>
    )
}

export default Crash
