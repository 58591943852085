import React from 'react';
import Loading from './Loading';
import NightSky from './NightSky';

const Demo = () => {
    return (
        <div className=''>
            {/* <NightSky></NightSky> */}
            <Loading></Loading>
        </div>
    )
}

export default Demo
