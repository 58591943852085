import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Context } from '../../store';
import { useContext } from 'react';
import { Divider, useMediaQuery } from '@mui/material';
import LeftDrawer from './leftDrawer/LeftDrawer';
import RightDrawer from './rightDrawer/RightDrawer';
import BalanceSection from './BalanceSection';
import LoginRegisterButtons from '../loginRegister/LoginRegisterButtons';
import { useSnackbar } from 'notistack';
import { countVerificationIsRequireds, logout, setActive } from '../../helpers/publicHelper';
import { imSocket } from '../../context/socket';
import { useHistory } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import _ from 'lodash';
import $ from 'jquery';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Draggable from 'react-draggable';
import { MDBIcon } from 'mdb-react-ui-kit';
const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, rightopen, leftopen }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...((rightopen == 'true') && {
        marginRight: drawerWidth,
    }),
    ...((leftopen == 'true') && {
        marginLeft: drawerWidth,
    }),
    ...((leftopen == 'true') && (rightopen == 'true') && {
        width: `calc(100% - ${2 * drawerWidth}px)`,
    }),

}));

const Navbar = (props) => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [drawers, setDrawers] = useState({
        leftopen: 'false',
        rightopen: 'false',
    })

    const open = (dir, o) => {
        setDrawers({
            ...drawers,
            [dir + 'open']: o.toString()
        })
    };

    const isMobile = useMediaQuery('(max-width:600px)');

    const history = useHistory();

    React.useEffect(() => {
        imSocket.on('me', message => {
            dispatch({
                type: 'setAll', payload: {
                    user: {
                        ...message
                    },
                    notification: {
                        ...state.notification,
                        verification: _.has(message, 'kyc.isRequired') ? countVerificationIsRequireds(message.kyc.isRequired) : 0,
                    }
                }
            });
        });
        imSocket.on('notification', messageObject => {
            const { message, options } = messageObject;
            enqueueSnackbar(message, options)
        });
        imSocket.on('logout', message => {
            logout(state, dispatch, history)
        });
    }, [])

    const [touchStartTime, setTouchStartTime] = useState()

    const handleTouchStart = (e) => {
        setTouchStartTime(Date.now())
    }

    const handleTouchEnd = (e) => {
        const touchTime = Date.now() - touchStartTime;
        if (touchTime < 450 && touchTime > 30) {
            if (state.active.showHeader) {
                $('.AppBarItem').slideUp(500);
                setActive(state, dispatch, { showHeader: false });
            } else {
                $('.AppBarItem').slideDown(500);
                setActive(state, dispatch, { showHeader: true });
            };
        }
    }

    return (
        <> <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {state.config.init && <AppBar id='AppBar' className='AppBarItem' position="fixed" leftopen={drawers.leftopen.toString()} rightopen={drawers.rightopen.toString()} open={(drawers.rightopen == ' true') || (drawers.leftopen == 'true')}>
                <Toolbar sx={{ justifyContent: 'space-between', bgcolor: '#121212', boxShadow: process.env.REACT_APP_ENV_FILE.indexOf('stage') > -1 ? '0 0 8px #fff' : '0 0 8px #26ddff77' }}>
                    {!(drawers.leftopen == 'true') ? <DrawerOpenButton dir='left' open={drawers.leftopen == 'true'} setOpen={() => setDrawers({ ...drawers, leftopen: 'true', ...(isMobile && { rightopen: 'false' }) })} /> : <span></span>}
                    {!isMobile && <BalanceSection setOpenRight={() => { }} />}
                    {isMobile && state.login && <BalanceSection setOpenRight={() => { }} />}
                    {(state.login && !(drawers.rightopen == 'true')) ? <DrawerOpenButton dir='right' open={(drawers.rightopen == 'true')} setOpen={() => setDrawers({ ...drawers, rightopen: 'true', ...(isMobile && { leftopen: 'false' }) })} /> : <span></span>}
                    {!state.login && <Box className='position-fixed AppBarItem' sx={{ right: 0, top:'0.5rem' }}><LoginRegisterButtons /></Box>}
                </Toolbar>


            </AppBar>
            }
            {
                isMobile && state.active.showHeaderArrow && !state.active.layoutDrawer.right && !state.active.layoutDrawer.left ?
                    <div className={`position-fixed ${state.active.showHeader ? 'mt-n3' : ''} w-100 m-auto d-block`} style={{
                        zIndex: 9999,
                        width: 0,
                        height: 0,
                        top: state.active.showHeader ? '1rem' : '0rem',
                    }}>
                        <Draggable
                            axis="x"
                            handle=".handle"
                            defaultPosition={{ x: (window.innerWidth / 3), y: 0 }}
                            position={null}
                            scale={1}
                            bounds="parent"
                        >
                            <div className='d-inline-block'>
                                <MDBIcon
                                    onTouchStartCapture={handleTouchStart}
                                    onTouchEndCapture={handleTouchEnd}
                                    onClick={() => { setActive(state, dispatch, { showHeader: !state.active.showHeader }) }}
                                    style={{
                                        margin: 'auto',
                                        zIndex: 999,
                                        fontSize: '1rem',
                                        // background: state.active.showHeader ? '#111' : 'transparent',
                                        borderBottomLeftRadius: '1rem',
                                        borderBottomRightRadius: '1rem',
                                    }}
                                    className='handle cursor-pointer d-inline text-neon px-3 pb-2'
                                    icon={state.active.showHeader ? 'angle-double-up' : 'angle-double-down'}
                                ></MDBIcon>
                            </div>
                        </Draggable>
                    </div> : <></>
            }
            <Box component="main" sx={{
                flexGrow: 1, p: 0, mr: [0,
                    // `-65px`
                ], width: '100vw', overflow: 'hidden'
            }}>
                <Toolbar />
                {props.children}
            </Box>

        </Box>
            {
                state.config.init &&
                <>
                    <LeftDrawer open={(drawers.leftopen == 'true')} setOpen={o => open('left', o)} />
                    <RightDrawer open={(drawers.rightopen == 'true')} setOpen={o => open('right', o)} />
                </>
            }
        </>
    );
}

const DrawerOpenButton = (props) => {
    const { open, setOpen, className, dir } = props;
    const isMobile = useMediaQuery('(max-width:600px)');
    const [state, dispatch] = useContext(Context);

    return <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={setOpen}
        edge={dir == 'left' ? 'start' : 'end'}
        sx={{
            ...(open && { display: 'none' }),
        }}
        className={' ' + className}>
        {dir == 'left' ?
            (false && isMobile) ?
                <>
                    <MenuOpenIcon className='text-neon text-shadow-neon fa-rotate-180' />
                    {!state.login &&
                        <img src={`/logo/logo-tm.svg`} className='border-left border-neon ps-2 ms-3 ' style={{ width: '3.5rem', borderLeft: '1px solid #26ddff' }} />
                    }
                </>
                :
                // <MenuIcon className='text-neon text-shadow-neon' />
                <Box sx={{
                    '& svg': {
                        width: '1.5rem',
                        // ml: ['-0.4rem', '0.3rem'],
                        color: '#26ddff'
                    },
                    '& .imgRocket': {
                        height: ['1.2rem', '1.5rem'],
                        width: ['1.2rem', '1.5rem'],
                    }
                }}>
                    {/* <img src={`/logo/logo-mobile.svg`} className='imgRocket' /> */}
                    <Box className='border-left border-neon text-start d-inline h-100' sx={{
                        // borderLeft: ['1px solid #26ddff', 'none'],
                        ms: ['0.2rem', 0],
                        pb: '0.2rem'

                    }}>
                        <MenuIcon />
                    </Box>
                    {!state.login && isMobile &&
                        <img src={`/logo/logo-tm.svg`} className=' ms-3' style={{ width: '3.5rem' }} />
                    }
                </Box>
            :
            <img src={`/alien.svg`} className='' style={{ height: '1.5rem', width: '1.5rem' }} />
        }
    </IconButton>
}

export default Navbar
