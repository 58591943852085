import { AppBar, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import React from 'react'

const ModalHeader = (props) => {
    const { children, setOpen } = props;

    return <AppBar component="nav" position='fixed' className='box-shadow-neon ' sx={{
        // borderTopLeftRadius: '0.75rem !important',
        // borderTopRightRadius: '0.75rem !important',
        top: 0, bgcolor: '#000',
        backgroundImage: 'none'
    }}>
        <Toolbar disableGutters className='d-flex align-items-stretch'>
            <Box className='w-100 d-flex justify-content-between'>
                {children}
            </Box>
            <Box sx={{
                py: ['0.6rem', '0.8rem'],
                px: '0.4rem'
            }} onClick={() => setOpen(false)}>
                <HighlightOffIcon className='cursor-pointer' color='danger' fontSize='small' />
            </Box>
        </Toolbar>
    </AppBar>
}

export default ModalHeader