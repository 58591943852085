import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import ThemeButton from '../components/ThemeButton'
import LoginRegisterTabs from './LoginRegisterTabs'

const LoginRegisterButtons = (props) => {
    const [values, setValues] = useState({
        open: false,
        mode: 0
    })
    return <Box className='me-2'>
        <ThemeButton id='SignInButton'
            sx={{
                fontSize: ['0.6rem', '0.8rem']
            }}
            onClick={() => setValues({
                open: true,
                mode: 0
            })} className='py-1 px-3 me-1' color='neon' variant='outlined'>
            Sign in
        </ThemeButton>
        <ThemeButton
            sx={{
                fontSize: ['0.6rem', '0.8rem']
            }}
            id='RegisterButton' onClick={() => setValues({
                open: true,
                mode: 1
            })} className='ms-md-2 py-1 px-3 btn--shockwave  is-active' color='warning' variant='contained'>
            Register
        </ThemeButton>
        <Modal
            open={values.open}
            disableScrollLock={true}
            onClose={() => setValues({ ...values, open: false })}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                minHeight: '600px'
            }}>
                <LoginRegisterTabs mode={values.mode} />
            </Box>
        </Modal>
    </Box>
}

export default LoginRegisterButtons