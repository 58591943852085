import React from 'react';
import FileUpload from '../components/FileUpload';
import $ from 'jquery';
import { useSnackbar } from 'notistack';

const GameImageUpload = (props) => {
    const { className, render, game_id } = props;
    const { enqueueSnackbar } = useSnackbar();

    const uploadSuccess = (x) => {
        enqueueSnackbar('Image uploaded successfully', {
            variant: 'success', autoHideDuration: 1000
        })
    }

    const uploadError = (x) => {
        enqueueSnackbar('Failed', {
            variant: 'error', autoHideDuration: 3000
        })
    }
    return (<>
        {
            render ? <span onClick={e => {
                //$(e).trigger('click');
                // console.log($(e.target).parent().find('input').trigger('click'))
                console.log('***')
                $(e.target).parent().parent().find('input').trigger('click')
            }} className='position-relative cursor-pointer'>
                <span className='d-none'>
                    <FileUpload
                        multiple
                        params={{
                            game_id,
                            type: 'gamebanner'
                        }}
                        name={''}
                        onUploaded={(file) => uploadSuccess(file)}
                        onError={uploadError}
                    ></FileUpload>
                </span>
                {render}
            </span>
                : <span></span>
        }
    </>)
}

export default GameImageUpload
