import { List, ListItem, ListItemText } from '@mui/material';
import React, { useEffect } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import $ from 'jquery'

const Help = (props) => {
    const { setView } = props;

    useEffect(() => {
        $('#DivLoginHelp').animate({
            opacity: 1
        }, 300)
    }, [])

    return (
        <div id='DivLoginHelp' className='pt-2' style={{ opacity: 0, display: 'block', height: '13.6rem' }}>
            <span className='cursor-pointer text-neon' onClick={() => setView('loginSignup')} >
                <ChevronLeftIcon />
                <small style={{ fontSize: '0.6rem' }}>Back to Login/SignUp</small>
            </span>
            <List className='text-small small pt-4'>
                <ListItem dense className='px-1 text-center'>
                    <ListItemText className='cursor-pointer text-hover-neon text-hover-shadow-neon' onClick={() => setView('forgotPassword')}>Forgot password?</ListItemText>
                </ListItem>
                <ListItem dense className='px-1 text-center'>
                    <ListItemText className='cursor-pointer text-hover-neon text-hover-shadow-neon'>Lost your Email?</ListItemText>
                </ListItem>
                <ListItem dense className='px-1 text-center'>
                    <ListItemText className='cursor-pointer text-hover-neon text-hover-shadow-neon'>Forgot phone number?</ListItemText>
                </ListItem>
                <ListItem dense className='px-1 text-center'>
                    <ListItemText className='cursor-pointer text-hover-neon text-hover-shadow-neon'>Lost Two-FA authenticator?</ListItemText>
                </ListItem>
            </List>
        </div>
    )
}

export default Help
