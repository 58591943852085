import { Box, Modal, Typography } from '@mui/material'
import _ from 'lodash';
import React, { useContext, useState } from 'react'
import { Context } from '../../store';
import ModalContent from '../modal/ModalContent';
import PageModal from '../navbar/PageModal';

const TermsAndConditions = (props) => {
    const { selectedIndex } = props;
    const [state, dispatch] = useContext(Context);

    const pages = _.values(state?.pages?.['Legal']);
    const _id = pages?.[selectedIndex]._id;
    const [open, setOpen] = useState(false);

    return <>
        <span onClick={() => setOpen(true)} style={{ padding: 2, cursor: 'pointer' }}> {pages?.[selectedIndex]?.title} </span>
        <Modal
            open={open}
            disableScrollLock={false}
            onClose={() => setOpen(false)}>
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: ['90vw', '80vw'],
                height: ['70vh'],
            }}>
                <ModalContent
                    setOpen={setOpen}
                    header={<Box className='ps-2 d-flex w-100 justify-content-start align-items-center position-relative'>
                        <span>
                            Legal

                        </span>
                    </Box>}>
                    <PageModal pages={pages} selectedId={_id} />
                </ModalContent>
            </Box>
        </Modal>
    </>
}

export default TermsAndConditions