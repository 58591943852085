
import React from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdb-react-ui-kit'

const CIL = (props) => {
    return (
        <MDBCard className='mx-2 position-fixe' style={{ backgroundColor: '#242329', minHeight: 'calc(100vh - 140px)' }}>
            <MDBCardBody className='p-2 text-gray position-relative_' style={{
            }}>
                <MDBRow className='position-absolute_' style={{
                    bottom: 30,
                }}>
                    <MDBCol size='12' className='text-center py-0'>
                        <img src='/cil/cil.png' className='img-fluid' />
                    </MDBCol>
                    <MDBCol size='12' className='text-center py-0'>
                        <small className='text-justify'>
                            imoon.com is operated by iMoon B.V registered in the Commercial Register of Curaçao under number 158383 and address Emancipatie Boulevard Dominico F Don Martina 31 Curaçao.
                            iMoon B.V. is duly licensed through a sublicense granted by C.I.L. Curaçao Interactive Licensing N.V., pursuant to Master Gaming License #5536/JAZ issued by the Governor of Curaçao.
                        </small>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard >
    )
}

export default CIL
