import React, { useContext } from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import List from '@mui/material/List';
import { Context } from '../../store';
import KYCEmail from './kyc/KYCEmail';
import KYCMobile from './kyc/KYCMobile';
import KYCAddress from './kyc/KYCAddress';
import KYCDoc from './kyc/KYCDoc';

const Verification = () => {
    const [state, dispatch] = useContext(Context);
    return (<>

        <MDBRow className='mx-0'>
            <MDBCol size='12' className='px-0'>
                <List dense>
                    {state.user ? <>
                        <div className='border-bottom border-neon'>
                            <KYCEmail></KYCEmail>
                        </div>
                        <div className='border-bottom border-neon'>
                            <KYCMobile></KYCMobile>
                        </div>
                        <div className='border-bottom border-neon'>
                            <KYCAddress></KYCAddress>
                        </div>
                        {state.config.kycList.map((name, i) => <KYCDoc state={state} key={i} name={name} {...state.user.kyc[name]}></KYCDoc>)}
                    </> :
                        <></>
                    }
                </List>
            </MDBCol>
        </MDBRow>
    </>
    )
}


export default Verification
