import React from 'react'

const LiveScore = () => {
    return (
        <iframe style={{
            width: '100%',
            height: 'calc(100vh - 70px)'
        }} src='https://livescore.imoon.com/1'></iframe>
    )
}

export default LiveScore
