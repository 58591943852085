import React, { useEffect, useContext, useState, useRef } from 'react';
import { Context, GameListContext } from '../store';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useSnackbar } from 'notistack';
import { Card, CardContent, Grid } from '@mui/material';
import ThemeButton from '../layout/components/ThemeButton';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import ThemeInput from '../layout/components/ThemeInput';
import GameImageUpload from './GameImageUpload';
import axios from 'axios';
import { favorite, getBearer } from '../helpers/publicHelper';
import { ProviderLogo } from '../helpers/gamesHelper';

const flipGameCard = (ref) => {
    var $elem = $(ref);

    $({ deg: 0 }).animate({ deg: 90 }, {
        duration: 500,
        step: function (now) {
            $elem.css({
                transform: 'rotateY(' + now + 'deg)'
            });
        },
        complete: () => {
            $elem.find('.gameCardFront').toggleClass('d-none');
            $elem.find('.gameCardBack').toggleClass('d-none');
            $({ deg: 90 }).animate({ deg: 0 }, {
                duration: 500,
                step: function (now) {
                    $elem.css({
                        transform: 'rotateY(' + now + 'deg)'
                    });
                },
                complete: () => {
                    // $elem.find('.cardFront').toggleClass('d-none');
                    // $elem.find('.cardBack').toggleClass('d-none');
                }
            });
        }
    });
}

const getGameImage = (props) => {
    const { provider, meta, media } = props;
    if (media && media.defaultImage && media.defaultImage.url) return media.defaultImage.url;
    if (provider == 'evolution' || provider == 'redtiger' || provider == 'netent') {
        return `https://cdn.imoon.com/images/${meta.providerData.gameType}.jpg?${Date.now()}`
    } else if (provider == 'pragmatic') {
        const gameDomain = 'imoon.prerelease-env.biz';
        return `https://${gameDomain}/game_pic/rec/325/${meta.providerData.gameID}.png?${Date.now()}`;
    } else {
        return ''
    }
}

const GameListCard = (props) => {
    const [state, dispatch] = useContext(Context);
    const [contextGameList, setContextGameList] = useContext(GameListContext);

    const [defaultImage, setDefaultImage] = useState({
        url: getGameImage(props),
        index: 0,
        currentIndex: 0
    });


    const {
        _id,
        gameId,
        title,
        gameType,
        provider,
        type,
        meta,
        live,
        media,
        order,
        env,
        hideExtra,
        imageHeight
    } = props;

    const dgaData = contextGameList && contextGameList.dga && contextGameList.dga[gameId]; // ? props.dgaData[gameId] : null;

    const [gameOrder, setGameOrder] = useState(order);


    const mediaImages = (media && media.images) ? media.images.length : 0;

    const saveImage = (img) => {
        axios.post(process.env.REACT_APP_API_HOST + '/graphist/design/setGameBanner', {
            _id,
            image: img
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then((res) => {

            })
            .catch((err) => {

            })
    }

    const saveGameOrder = (order) => {
        axios.post(process.env.REACT_APP_API_HOST + '/graphist/design/setGameOrder', {
            _id,
            order
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then((res) => {
                window.location.reload()
            })
            .catch((err) => {

            })
    }

    const nextImage = (defaultImage) => {
        if (!(media && media.images && media.images[0] && media.images[0].url)) return;

        let newImage = defaultImage.index < media.images.length ? media.images[defaultImage.index] : media.images[0];

        if (newImage.url == defaultImage.url) {
            setDefaultImage({
                currentIndex: ((defaultImage.index + 1) < media.images.length) ? (defaultImage.index + 1) : 0,
                index: (defaultImage.index + 1) < media.images.length ? (defaultImage.index + 1) : 0,
                url: media.images[(defaultImage.index + 1) < media.images.length ? (defaultImage.index + 1) : 0].url
            });
            saveImage(media.images[((defaultImage.index + 1) < media.images.length) ? (defaultImage.index + 1) : 0])
        } else {
            setDefaultImage({
                currentIndex: defaultImage.index,
                index: defaultImage.index < media.images.length ? (defaultImage.index + 1) : 0,
                url: newImage.url
            });
            saveImage(media.images[defaultImage.index])
        }
    }

    useEffect(() => {
        setDefaultImage({
            url: getGameImage(props),
            index: 0,
            currentIndex: 0
        });
        setGameOrder(props.order)
        return () => {
            setDefaultImage({
                url: '/default-game.jpg',
                index: 0,
                currentIndex: 0
            });
        }

    }, [_id])

    const cardRef = useRef();

    return <Grid item className='my-1 overflow-hidden'
        xs={1}
        // md={2}
        sx={{
            willChange: 'transform, opacity',
            width: '139px !important',
            // maxWidth: '33%',
            p: '5px',
        }}
    >
        <Card ref={cardRef} isd={gameId} sx={{
            boxShadow: `${env == 'stage' ? '0px 0px 10px  #f90' : '0px 0px 5px  #26ddff88'}`,
            // width: '139px !important',
            maxWidth: '100%',
        }} className='_gameCard position-relative box-hover-shadow-neon cursor-pointer rounded-5 overflow-hidden'>
            <div className='gameCardFront'>
                <div className='position-relative'
                    onMouseEnter={e => {
                        $(e.currentTarget).find('.gameMask_').removeClass('d-none');
                        $(e.currentTarget).find('.gameMask_').stop().animate({ opacity: 1 }, 300);
                    }}
                    onMouseLeave={e => {
                        $(e.currentTarget).find('.gameMask_').addClass('d-none');
                        $(e.currentTarget).find('.gameMask_').stop().animate({ opacity: 0 }, 300)
                    }}
                    onClick={e => {
                        $(e.currentTarget).find('.gameMask_').removeClass('d-none');
                        $(e.currentTarget).find('.gameMask_').stop().animate({ opacity: 1 }, 300);
                    }}>
                    <img width='100%'
                        // height={imageHeight || '173px'}
                        style={{ zIndex: 1 }}
                        title={getGameImage(props)} src={defaultImage.url} className='img-fluid_' onError={(e) => { e.target.src = '/default-game.jpg'; e.target.onError = ''; }} />
                    <div className='gameMask_ position-absolute w-100 h-100 d-none' style={{ top: 0, left: 0, background: '#000c', opacity: 0, zIndex: 2 }}>
                        {dgaData ?
                            <div
                                onClick={() => flipGameCard(cardRef.current)}
                                className='position-absolute small cursor-pointer'
                                style={{
                                    top: 0,
                                    right: 0,
                                    padding: '2px 4px',
                                    zIndex: 9999
                                }}>
                                <small>
                                    <MDBIcon icon='info-circle'></MDBIcon>
                                </small>
                            </div> : <></>
                        }
                        <div className='position-absolute text-center pt-3 px-0 small w-100' style={{ top: 0, left: 0, fontSize: '0.7rem' }}>
                            <strong className='text-white small'>{title}</strong>
                        </div>
                        <div
                            className='position-absolute text-center'
                            style={{ top: '40%', left: '50%', transform: 'translate(-50%, 0%)' }}>
                            <Link to={`/Game/${provider}/${type}/${_id}`}>
                                <ThemeButton size='small' className='playButton px-4'>Play</ThemeButton>
                            </Link>
                            <DGADataFront {...dgaData}></DGADataFront>
                        </div>
                        {state.login ?
                            <div className='position-absolute px-0 text-end rounded-8' style={{ bottom: 0, right: 4, zIndex: 10, fontSize: '0.6rem' }}>
                                {state.user.profile.favorite && state.user.profile.favorite.games && state.user.profile.favorite.games[_id] ?
                                    <MDBIcon onClick={() => favorite(state, 'remove', 'games', _id)} className='text-neon' icon='star'></MDBIcon>
                                    :
                                    <MDBIcon onClick={() => favorite(state, 'add', 'games', _id, {})} className='font-weight-light' icon='star'></MDBIcon>
                                }
                            </div> : <></>
                        }
                        <div className='position-absolute px-0 text-start rounded-8' style={{ bottom: 0, left: 4, zIndex: 9, fontSize: '0.6rem' }}>
                            <small className={`${type == 'tablegames' ? 'text-success' : type == 'slots' ? 'text-warning' : 'text-info'}  text-shadow-neon text-capitalize`} style={{ padding: '1px 2px', backgroundColor: '#000b' }}>
                                {type == 'tablegames' ? 'table' : type}
                            </small>
                        </div>
                    </div>
                    <div className='position-absolute px-0 text-end rounded-8' style={{ bottom: 0, right: 4, zIndex: 9, fontSize: '0.6rem' }}>
                        {state.login && state.user.profile.favorite && state.user.profile.favorite.games && state.user.profile.favorite.games[_id] ?
                            <MDBIcon className='text-neon' onClick={() => favorite(state, 'remove', 'games', _id)} icon='star'></MDBIcon>
                            :
                            <></>
                        }
                    </div>
                    <div className='position-absolute px-0 text-center small _m-auto rounded-8  d-flex align-items-bottom justifuy-content-center w-100 _h-auto' style={{ bottom: '3px', left: 0, zIndex: 1 }}>
                        <ProviderLogo className='m-auto' style={{
                            width: '10%',
                            height: 'calc(2%)'
                        }} color={true} provider={provider}></ProviderLogo>
                    </div>


                    {live ? <div className='position-absolute px-0 text-left rounded-8' style={{ top: 0, left: 4, zIndex: 9, fontSize: '0.6rem' }}>
                        <small className='border_ border-neon text-warning text-shadow-neon _box-shadow-neon rounded-1' style={{ padding: '1px 2px', backgroundColor: '#000b' }}>Live</small>
                    </div> : ''}
                </div>
            </div>
            <div className='gameCardBack d-none bg-black'>
                <div className='position-relative'>
                    <DGADataBack flipGameCard={flipGameCard} cardRef={cardRef.current} {...dgaData}></DGADataBack>
                    <img
                        width='100%'
                        // height='173'
                        style={{ zIndex: 1 }}
                        title={getGameImage(props)} src={defaultImage.url} className='invisible' onError={(e) => { e.target.src = '/default-game.jpg'; e.target.onError = ''; }} />
                </div>
            </div>

            {!hideExtra && state.login && (state.user.profile.role.indexOf('graphist') > -1) ?
                <CardContent className='py-1 px-1'>
                    <MDBRow className='m-0'>
                        <MDBCol size='12' className='text-center px-0 small'>
                            <div className={`d-flex justify-content-between px-1`}>
                                <GameImageUpload
                                    game_id={_id}
                                    render={<ThemeButton size='small' variant='outlined' className='py-0'>
                                        <MDBIcon icon='upload' className='pe-1'></MDBIcon>
                                        upload</ThemeButton>}
                                    className='bg-danger d-inline-block'></GameImageUpload>

                                {(mediaImages && (state.user.profile.role.indexOf('superGraphist')))
                                    ?
                                    <span onClick={() => nextImage(defaultImage)} className='cursor-pointer text-hover-neon'>
                                        <small>
                                            {mediaImages}
                                            <MDBIcon className='ps-1' icon='chevron-circle-right'></MDBIcon>
                                        </small>
                                    </span>
                                    : <></>}
                            </div>
                            {
                                state.user.profile.role.indexOf('superGraphist') ?
                                    <div className='small d-flex w-100 justify-content-center align-items-center'>
                                        <div className='w-50 px-1'>
                                            <ThemeInput
                                                type='number'
                                                inputStyle={{
                                                    fontSize: '0.5rem',
                                                    minHeight: 'auto',
                                                    height: 'auto',
                                                    border: '1px solid #26ddff',
                                                    boxShadow: '0px 0px 10px #26ddff',
                                                    lineHeight: '0.9rem'
                                                }}
                                                className='p-0 m-0'
                                                inputClassName='m-0 small box-shadow-neon rounded-pill text-start pe-2 py-0'
                                                size='small' value={gameOrder} onChange={e => setGameOrder(e.target.value)}></ThemeInput>
                                        </div>
                                        <small className='w-50 d-inline-block mt-2 px-1'>
                                            {/* #{order} */}
                                            <ThemeButton style={{
                                                minWidth: 'auto'
                                            }} variant='outlined' color='warning' size='small' className='px-2 py-0' onClick={() => saveGameOrder(gameOrder)}>Save</ThemeButton>
                                        </small>
                                    </div>
                                    :
                                    <></>
                            }
                        </MDBCol>
                    </MDBRow>
                </CardContent>
                : <></>}

        </Card>
    </Grid >
}


const DGADataFront = (props) => {
    if (!props.tableId) return <></>;
    const { tableLimits } = props;
    return <div className='small text-white pt-2'>
        <small className='px-1'>
            ${tableLimits && tableLimits.minBet}
        </small>
        -
        <small className='px-1'>
            ${tableLimits && tableLimits.maxBet}
        </small>
    </div>
}

const DGADataBack = (props) => {
    const {
        multiseat,
        multiseatLimit,
        betbehind,
        tableLimits,
        dealer,
        availableSeats,
        hotNumbers,
        coldNumbers,
        totalSeatedPlayers,
        tableImage,
        last20Results,
        tableType,
        sidebets,
        gameResult,
        baccaratShoeSummary,
        sicBoMultipliers,
        flipGameCard,
        tableId,
        cardRef
    } = props;

    if (!tableId) return <>
        <div
            onClick={() => flipGameCard(cardRef)}
            className='position-absolute small p-1 cursor-pointer bg-info' style={{
                top: 0,
                right: 0,
                zIndex: 9999
            }}>
            <small>
                <MDBIcon className='text-white' icon='reply'></MDBIcon>
            </small>
        </div>
    </>;

    return <>

        <div className='small position-absolute w-100 h-100 text-center rounded-3 overflow-hidden'
            style={{
                backgroundImage: `url('${tableImage}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                zIndex: 1
            }}
        >

            <div className='h-100 pt-2 overflow-scroll noScrollbar' style={{
                backgroundColor: '#000c'
            }}>
                {/* <small className='px-1'>
                ${tableLimits && tableLimits.minBet}
            </small>
            -
            <small className='px-1'>
                ${tableLimits && tableLimits.maxBet}
            </small> */}
                <div
                    onClick={() => flipGameCard(cardRef)}
                    className='position-fixed small p-1 cursor-pointer' style={{
                        top: 0,
                        right: 0,
                        zIndex: 999
                    }}>
                    <small>
                        <MDBIcon className='text-white' icon='reply'></MDBIcon>
                    </small>
                </div>
                <div className='small text-center mb-1'>
                    <small className='d-block opacity-60'>Dealer</small>
                    {dealer.name}
                </div>
                <div className='small text-center mb-1'>
                    <small className='d-block opacity-60'>Max Players</small>
                    {tableLimits && tableLimits.maxPlayers}
                </div>
                {tableLimits && (totalSeatedPlayers || availableSeats) ?
                    <div className='small text-center mb-1'>
                        <small className='d-block opacity-60'>Seats</small>
                        {totalSeatedPlayers}/{availableSeats || tableLimits.maxPlayers}
                    </div> : <></>
                }
                {hotNumbers || coldNumbers ?
                    <div className='small text-center mb-1'>
                        <small className='d-block opacity-60'>Hot Numbers</small>
                        {hotNumbers}
                        <small className='d-block opacity-60'>Cold Numbers</small>
                        {coldNumbers}
                    </div> : <></>
                }
                {
                    betbehind ? <div className='small text-center mb-1'>
                        <small className='d-block opacity-60'>Bet Behind</small>
                        Yes
                    </div> : <></>
                }
                {
                    sidebets ? <div className='small text-center mb-1'>
                        <small className='d-block opacity-60'>Side Bets</small>
                        Yes
                    </div> : <></>
                }
                {
                    multiseat && multiseatLimit ? <div className='small text-center mb-1'>
                        <small className='d-block opacity-60'>Multi-Seat</small>
                        {multiseatLimit}
                    </div> : <></>
                }
                <div className='small text-center mb-2' style={{
                }}>
                    {last20Results ? <small className='d-block opacity-60'>Last 20 Rounds</small> : <></>}
                    <MDBRow className='mx-1 small'>

                        {
                            tableType == 'BLACKJACK' && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x, i) => <>
                                {props['seat' + x] === true || props['seat' + x] === false ?
                                    <MDBCol key={i} size='12 px-0'>
                                        <MDBRow className='mx-0 justify-content-center'>
                                            <MDBCol size='5' className='mb-1 px-1 opacity-60 text-capitalize small'>
                                                {['seat ' + x]}
                                            </MDBCol>
                                            <MDBCol size='5' className='mb-1 px-1 opacity-60 small'>
                                                {props['seat' + x] ? 'Player' : 'Empty'}
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                    : <></>}
                            </>)
                        }
                        {
                            tableType == 'BACCARAT' && baccaratShoeSummary && <MDBCol size='12 px-0'>
                                <MDBRow className='mx-0 justify-content-center border-bottom border-light mb-1 pb-1'>
                                    <MDBCol size='12' className={`px-1 opacity-60 text-capitalize`}>
                                        <small style={{ fontSize: '0.5rem' }}>Total Result</small>
                                    </MDBCol>
                                    <MDBCol size='4' className={`px-1 opacity-60 text-capitalize text-center`}>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>Player</small>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>{baccaratShoeSummary.playerWinCounter}</small>
                                    </MDBCol>
                                    <MDBCol size='4' className={`px-1 opacity-60 text-capitalize text-center`}>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>Tie</small>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>{baccaratShoeSummary.tieCounter}</small>
                                    </MDBCol>
                                    <MDBCol size='4' className={`px-1 opacity-60 text-capitalize text-center`}>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>Banker</small>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>{baccaratShoeSummary.bankerWinCounter}</small>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='mx-0 justify-content-center border-bottom border-light mb-1 pb-1'>
                                    <MDBCol size='12' className={`px-1 opacity-60 text-capitalize`}>
                                        <small style={{ fontSize: '0.5rem' }}>Total Pair</small>
                                    </MDBCol>
                                    <MDBCol size='6' className={`px-0 opacity-60 text-capitalize text-center`}>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>Player Pair</small>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>{baccaratShoeSummary.playerPairCounter}</small>
                                    </MDBCol>
                                    <MDBCol size='6' className={`px-0 opacity-60 text-capitalize text-center`}>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>Banker Pair</small>
                                        <small className='d-block' style={{ fontSize: '0.5rem' }}>{baccaratShoeSummary.bankerPairCounter}</small>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        }
                        {
                            tableType == 'BACCARAT' && gameResult.map((x, i) => {
                                const playerColor = x.winner == 'PLAYER_WIN' ? 'success' : x.winner == 'TIE' ? 'warning' : 'danger';
                                const bankerColor = x.winner == 'BANKER_WIN' ? 'success' : x.winner == 'TIE' ? 'warning' : 'danger';
                                return <MDBCol key={i} size='12 px-0'>
                                    <MDBRow className='mx-0 justify-content-center'>
                                        <MDBCol size='5' className={`mb-1 px-1 text-${playerColor}`}>
                                            <small style={{ fontSize: '0.4rem' }}>Player</small>
                                            <div className='text-center'>
                                                <CustomBadge className={`border m-auto border-${playerColor}`} value={x.player}></CustomBadge>
                                            </div>
                                        </MDBCol>
                                        <MDBCol size='5' className={`mb-1 px-1 text-${bankerColor}`}>
                                            <small style={{ fontSize: '0.4rem' }}>Banker</small>
                                            <div className='text-center'>
                                                <CustomBadge className={`border m-auto border-${bankerColor}`} value={x.banker}></CustomBadge>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            })
                        }
                        {
                            tableType == 'ROULETTE' && last20Results && last20Results.map((x, i) => {
                                const bgColor = x.color;
                                const mega = x.slots && true;
                                return <MDBCol key={i}
                                    size={mega ? '12' : '3'}
                                    style={{
                                        borderBottom: mega ? '1px solid #fff4' : 'none'
                                    }}
                                    className={`${mega ? 'py-1 text-center d-flex align-items-center' : ''} mb-1 px-1 `}>
                                    <div className='w-25'>
                                        <CustomBadge style={{
                                            backgroundColor: bgColor
                                        }} className={`m-auto border border-${mega && x.multiplier ? 'neon box-shadow-neon' : 'white'}`} value={x.result}></CustomBadge>
                                        {mega && x.multiplier ? <small className='text-neon text-shadow-neon'>{x.multiplier}x</small> : <></>}
                                    </div>
                                    {
                                        mega && <div className='w-75'>
                                            {/* <small>Slots</small> */}
                                            <div className='justify-content-center'>
                                                {Object.keys(x.slots).map((s, k) => {
                                                    const megaWin = s == x.result;
                                                    return <div style={{ fontSize: '0.5rem', marginBottom: '1px' }}
                                                        className={`d-flex justify-content-between d-inline-block px-3 ${megaWin ? 'text-neon text-shadow-neon font-weight-bold' : 'opacity-60'} `}>
                                                        <CustomBadge style={{
                                                            color: megaWin ? '#26ddff' : 'white'
                                                        }} size='0.9rem' className={`mx-1 p-1 d-inline-block ${megaWin ? 'border-neon box-shadow-neon' : 'border-white'} border `} value={s}></CustomBadge>
                                                        <span className={`px-1 ${megaWin ? '' : ''} `}>
                                                            {x.slots[s]}
                                                        </span>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                </MDBCol>
                            })
                        }
                        {
                            tableType == 'ONEBJ' && <MDBCol size='3' className='mb-1 px-1'>

                            </MDBCol>
                        }
                        {
                            tableType == 'DRAGONTIGER' && gameResult && gameResult.map((x, i) => {
                                const { winner } = x;
                                const color = winner == 'TIGER' ? 'warning' : winner == 'DRAGON' ? 'danger' : 'success';
                                return <MDBCol key={i} size='12 px-0'>
                                    <MDBRow className='mx-0 justify-content-center'>
                                        <MDBCol size='5' className='mb-1 px-1 small text-center font-weight-bold'>
                                            <small className={`text-${winner == 'DRAGON' ? 'danger' : winner == 'TIGER' ? 'danger opacity-30' : 'success'}`}>
                                                Dragon
                                            </small>
                                        </MDBCol>
                                        <MDBCol size='2' className='mb-1 px-0'>
                                            <CustomBadge className={`border border-${color} box-shadow-${color}`} value={x.gameResult}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='5' className='mb-1 px-1 text-center small font-weight-bold'>
                                            <small className={`text-${winner == 'TIGER' ? 'warning' : winner == 'DRAGON' ? 'warning opacity-30' : 'success'}`}>
                                                Tiger
                                            </small>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            })
                        }
                        {
                            tableType == 'ANDARBAHAR' && gameResult && gameResult.map((x, i) => {
                                const { winner } = x;
                                const color = winner == 'BAHAR' ? 'danger' : winner == 'ANDAR' ? 'info' : 'success';
                                return <MDBCol key={i} size='12 px-0'>
                                    <MDBRow className='mx-0 justify-content-center align-items-center py-1'>
                                        <MDBCol size='5' className='px-1 small text-center font-weight-bold'>
                                            <small className={`text-${winner == 'ANDAR' ? 'info' : winner == 'BAHAR' ? 'info opacity-30' : 'success'}`}>
                                                Ander
                                            </small>
                                        </MDBCol>
                                        <MDBCol size='2' className='px-0'>
                                            <CustomBadge className={`border border-${color} box-shadow-${color}`} value={x.result}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='5' className='px-1 text-center small font-weight-bold'>
                                            <small className={`text-${winner == 'BAHAR' ? 'danger' : winner == 'ANDAR' ? 'danger opacity-30' : 'success'}`}>
                                                Bahar
                                            </small>
                                        </MDBCol>
                                        <MDBCol size='12' className='mb-1 opacity-40 small text-center'>
                                            <small className='pe-1'>
                                                Joker Score
                                            </small>
                                            <small>
                                                {x.jokerScore}
                                            </small>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            })
                        }
                        {
                            tableType == 'SWEETBONANZA' && gameResult && gameResult.map((x, i) => {
                                const { multiplier, payout, result, sbmul, sugarbomb, winner } = x;
                                return <MDBCol key={i} size='12' className={`mb-1 px-1 py-2 border-bottom border-neon`}>
                                    <MDBRow className='mx-0'>
                                        <MDBCol size='6' className='mb-1 px-1 text-center'>
                                            <small className='d-block opacity-60'>Multiplier</small>
                                            {multiplier}x
                                        </MDBCol>
                                        <MDBCol size='6' className='mb-1 px-1 text-center'>
                                            <small className='d-block opacity-60'>Winner</small>
                                            {winner}
                                        </MDBCol>
                                        <MDBCol size='6' className='mb-1 px-1 text-center'>
                                            <small className='d-block opacity-60'>Payout</small>
                                            {JSON.stringify(payout)}
                                        </MDBCol>
                                        <MDBCol size='6' className='mb-1 px-1 text-center'>
                                            <small className='d-block opacity-60'>Result</small>
                                            {result}
                                        </MDBCol>
                                        <MDBCol size='6' className='mb-1 px-1 text-center'>
                                            <small className='d-block opacity-60'>Sugarbomb</small>
                                            {sugarbomb ? 'Yes' : 'No'}
                                        </MDBCol>
                                        <MDBCol size='6' className='mb-1 px-1 text-center'>
                                            <small className='d-block opacity-60'>sbmul</small>
                                            {JSON.stringify(sbmul)}
                                        </MDBCol>
                                    </MDBRow>

                                </MDBCol>
                            })
                        }
                        {
                            tableType == 'SicBO' && last20Results && last20Results.map((x, i) =>
                                <MDBCol key={i} size='12 px-0'>
                                    <MDBRow className='mx-0'>
                                        <MDBCol size='3' className='mb-1 px-1 opacity-60'>
                                            <CustomBadge className='border border-white' value={x.die1}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='3' className='mb-1 px-1 opacity-60'>
                                            <CustomBadge className='border border-white' value={x.die2}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='3' className='mb-1 px-1 opacity-60'>
                                            <CustomBadge className='border border-white' value={x.die3}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='3' className='mb-1 px-1'>
                                            <CustomBadge className='border border-neon box-shadow-neon' value={x.totalSum}></CustomBadge>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            )
                        }
                        {
                            tableType == 'SicBO' && sicBoMultipliers && sicBoMultipliers.map((x, i) =>
                                <MDBCol key={i} size='12 px-0'>
                                    <MDBRow className='mx-0'>
                                        <MDBCol size='3' className='mb-1 px-1 opacity-60'>
                                            <CustomBadge className='border border-white' value={x.multiplier}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='3' className='mb-1 px-1 opacity-60'>
                                            <CustomBadge className='border border-white' value={x.number}></CustomBadge>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            )
                        }


                        {
                            tableType == 'MEGAWHEEL' && last20Results && last20Results.map((x, i) =>
                                <MDBCol key={i} size='12 px-0'>
                                    <MDBRow className='mx-0'>
                                        <MDBCol size='4' className='mb-1 px-1 opacity-60'>
                                            <small style={{ fontSize: '0.4rem' }}>Multiplier</small>
                                            <CustomBadge size='1.5rem' className='border border-white' value={x.multiplier}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='4' className='mb-1 px-1 opacity-60'>
                                            <small style={{ fontSize: '0.4rem' }}>Slot</small>
                                            <CustomBadge size='1.5rem' className='border border-white' value={x.slot}></CustomBadge>
                                        </MDBCol>
                                        <MDBCol size='4' className='mb-1 px-1'>
                                            <small style={{ fontSize: '0.4rem' }}>Result</small>
                                            <CustomBadge size='1.5rem' className='border border-neon box-shadow-neon' value={x.result}></CustomBadge>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            )
                        }

                    </MDBRow>
                </div>
            </div>

        </div></>
}

const CustomBadge = (props) => {
    const { className, value, size, style } = props;
    return <span className={'d-flex rounded-circle justify-content-center align-items-center ' + className} style={{
        width: size || '1rem',
        height: size || '1rem',
        fontSize: '0.5rem',
        ...style
    }}>
        <span>
            {value}
        </span>
    </span>
}

export default GameListCard
