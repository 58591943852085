import React, { useContext, useEffect } from 'react'
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Context } from '../store';
import { Box, Grid } from '@mui/material';
import { loginSuccess } from '../helpers/publicHelper';

const TelegramCallback = (props) => {
    const code = props.match.params.code;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        // var postData = _.pick(values, ['password', 'confirmPassword', 'code']);
                        const headers = { captcha };
                        axios.post(process.env.REACT_APP_API_HOST + '/api/users/telegram-login-verify', { loginCode: code }, { headers })
                            .then((res) => {
                                const token = res.headers['x-auth-token'];
                                res = res.data;
                                if (res.success) {
                                    enqueueSnackbar('Successfully logged in.', {
                                        variant: 'success', autoHideDuration: 3000
                                    })
                                    loginSuccess(state, dispatch, res.result, token)
                                    setTimeout(() => {
                                        window.location.href = '/'
                                    }, 500);
                                } else {
                                    enqueueSnackbar(res.message, {
                                        variant: 'warning', autoHideDuration: 3000
                                    })
                                }
                            })
                            .catch((err) => {
                                enqueueSnackbar('Error', {
                                    variant: 'error', autoHideDuration: 3000
                                })
                            })
                    })
            })
        } else {
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }
    }, [])

    return (
        <Grid container justifyContent="center" alignItems='center' height='calc(80vh - 5rem)'>
            <Box justifyContent='center' textAlign='center' sx={{ m: 1 }}>
             
            </Box>
        </Grid>
    )
}

export default TelegramCallback
