import React, { useContext, useState } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CustomIcon from '../../../components/CustomIcon';
import { Link } from 'react-router-dom'
import { Avatar, Badge, Box, List, ListItemButton, Modal, Tooltip, Typography, useMediaQuery, Zoom } from '@mui/material';
import { Context } from '../../../store';
import styled from '@emotion/styled';
import Assist from '../../assist/Assist'
import * as Icon from '@mui/icons-material';
import _ from 'lodash';
import PageItem from '../PageItem';
import { listItemIconSX } from '../listItemIconSX';

const LeftDrawerList = (props) => {
    const [state, dispatch] = useContext(Context);

    const isMobile = useMediaQuery('(max-width:600px)');
    const leftItemsList = [
        {
            text: 'iMoon',
            icon: <CustomIcon icon='im-moon2'></CustomIcon>,
            url: '/'
        },
        {
            divider: true,
        },
        {
            text: <Box className='d-flex w-100 justify-content-start align-items-center'>Crash <small className='ps-1' style={{ color: '#26ddff' }}>iMoon originals</small></Box>,
            icon: <CustomIcon icon='im-crash2'></CustomIcon>,
            url: '/games/crash'
        },
        {
            divider: true,
        },
        {
            text: <Box className='d-flex w-100 justify-content-start align-items-center'>All <small className='ps-1' style={{ color: '#26ddff' }}>Live Casino Games</small></Box>,
            icon: <CustomIcon icon='im-casino'></CustomIcon>,
            url: '/gamelist/casino/all/all/live',
            live: true
        },
        {
            text: <Box className='d-flex w-100 justify-content-start align-items-center'>Evolution</Box>,
            icon: <CustomIcon icon='im-evolution'></CustomIcon>,
            url: '/gamelist/casino/evolution/all/live',
        },
        {
            text: <Box className='d-flex w-100 justify-content-start align-items-center'>Pragmatic</Box>,
            icon: <CustomIcon className='mt-n1' icon='im-pragmatic2'></CustomIcon>,
            url: '/gamelist/casino/pragmatic/all/live',
        },
        {
            divider: true,
        },
        {
            text: <Box className='d-flex w-100 justify-content-start align-items-center'>All <small className='ps-1' style={{ color: '#26ddff' }}>Slot Games</small></Box>,
            icon: <CustomIcon icon='im-slots'></CustomIcon>,
            url: '/gamelist/casino/all/slots',
            slot: true
        },
        {
            text: <span>Netent</span>,
            icon: <CustomIcon icon='im-netent'></CustomIcon>,
            url: '/gamelist/casino/netent/slots'
        },
        {
            text: <Box className='d-flex w-100 justify-content-start align-items-center'>Pragmatic</Box>,
            icon: <CustomIcon className='mt-n1' icon='im-pragmatic2'></CustomIcon>,
            url: '/gamelist/casino/pragmatic/slots'
        },
        {
            text: <span>Red Tiger</span>,
            icon: <CustomIcon icon='im-redTiger'></CustomIcon>,
            url: '/gamelist/casino/redtiger/slots'
        },
        {
            divider: true,
        },
        {
            text: <span>Sports Betting</span>,
            icon: <CustomIcon icon='im-everyMatrix'></CustomIcon>,
            url: '/Games/SportsBetting/Sport'
        },
        {
            text: <span>Virtual Sport</span>,
            icon: <CustomIcon icon='im-virtualGames2'></CustomIcon>,
            url: '/gamelist/casino/all/virtualsport'
        },
        {
            text: <span>Bingo</span>,
            icon: <CustomIcon icon='im-bingo'></CustomIcon>,
            url: '/gamelist/casino/all/bingo'
        },
        {
            text: <span>Table Games</span>,
            icon: <CustomIcon icon='im-tableGames2'></CustomIcon>,
            url: '/gamelist/casino/all/tablegames'
        },
    ]

    return (
        <List>
            {leftItemsList.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    {item.divider ?
                        <hr className='my-1' />
                        :
                        <Link to={item.url}>

                            <ListItemButton className='py-0 text-white'
                                sx={{
                                    '& small': {
                                        fontSize: '0.6rem'
                                    }
                                }}
                            >
                                <Tooltip
                                    TransitionComponent={Zoom}
                                    placement="right" classes={{ tooltip: 'bg-black -box-shadow-neon px-2 m-0 ms-n2' }}
                                    title={
                                        <Box className='align-items-center'>
                                            {item.text}
                                        </Box>
                                    }>
                                    <div>
                                        <ListItemIcon sx={listItemIconSX}>
                                            {item.icon}
                                            {/* {item.live && !isMobile && <Typography variant='caption' className='text-neon text-shadow-neon position-absolute' sx={{ marginLeft: '2px', zIndex: 9, right: '-0.2rem', top: '0.3rem' }}>L</Typography>} */}
                                            {/* {item.slot && !isMobile && <Typography variant='caption' className='text-neon text-shadow-neon position-absolute' sx={{ marginLeft: '2px', zIndex: 9, right: '-0.2rem', top: '0.3rem' }}>S</Typography>} */}
                                        </ListItemIcon>
                                    </div>
                                </Tooltip>

                                <ListItemText primary={item.text} primaryTypographyProps={{ fontSize: ['0.7rem', '0.9rem'] }} />
                            </ListItemButton>
                        </Link>
                    }

                </ListItem>
            ))}
            <hr className='my-1' />

            <LegalPages />
            {state.login &&
                <OnlineSupportItem ListItemIconSX={listItemIconSX} isMobile={isMobile} />
            }

        </List>
    )
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    width: '42px',
    height: '42px',
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));


const OnlineSupportItem = (props) => {
    const { ListItemIconSX } = props;
    const [values, setValues] = useState({
        open: false,
        mode: 'login'
    })

    return <>

        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton className='py-0' onClick={() => setValues({ ...values, open: true })}>
                <Tooltip
                    TransitionComponent={Zoom}
                    placement="right" classes={{ tooltip: 'bg-black -box-shadow-neon px-2 m-0' }}
                    title={
                        <div>
                            <span>Online Support <small className='ps-1' style={{ color: '#26ddff', fontSize: '0.7rem' }}></small></span>
                        </div>
                    }>
                    <div>
                        <ListItemIcon sx={ListItemIconSX}>
                            <CustomIcon fill='#26ddff' className='svg-shadow-neon' icon='im-support'></CustomIcon>
                        </ListItemIcon>
                    </div>
                </Tooltip>

                <ListItemText primary={
                    <span>Online Support <small className='ps-1' style={{ color: '#26ddff', fontSize: '0.7rem' }}></small></span>
                } primaryTypographyProps={{ fontSize: ['0.7rem', '0.9rem'] }} sx={{
                    //  opacity: open ? 1 : 0 
                }} />
            </ListItemButton>
        </ListItem>

        <Modal
            open={values.open}
            disableScrollLock={true}
            onClose={() => setValues({ mode: 0, open: false })}
        >
            <Box className='box-shadow-neon bg-black rounded-6 overflow-scroll noScrollbar' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                minHeight: '500px'
            }}>
                <Box sx={{ borderBottom: '1px solid #26ddff' }}>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot">
                        <Avatar alt="Alien" src="/alien.svg" className='m-2 box-shadow-neon p-1' sx={{
                            width: '30px',
                            height: '30px',
                            display: 'block',
                            '& img': {
                                objectFit: 'contain'
                            }
                        }} />
                    </StyledBadge>
                </Box>
                <Assist />
            </Box>
        </Modal>
    </>
}


const LegalPages = (props) => {
    const [state, dispatch] = useContext(Context);
    const legalPages = _.values(state?.pages?.['Legal']);
    const IconComponent = Icon['Gavel'];
    return <>
        {(legalPages.length > 0) && <PageItem pages={legalPages} {...legalPages[0]} title='Legal' icon={<IconComponent />} />}
        {/* {
            legalPages.map((page, i) => {
                const IconComponent = Icon[page.icon || 'Help'];
                return <PageItem pages={legalPages} {...page} key={i} icon={<IconComponent />} />
            })
        } */}
    </>

}

export default LeftDrawerList
