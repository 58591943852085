import './styles/App.scss';
import React, { useEffect, useContext, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import { Context, GameListContext } from './store'
import Lobby from './layout/lobby/Lobby';
import Poker from './games/Poker';
// import CrashFrame from './games/Crash';
import Crash from './games/Crash';
import CrashStage from './games/CrashStage';
import PasswordRecoveryCallback from './layout/auth/PasswordRecoveryCallback';
import Backdrop from '@mui/material/Backdrop';
import TelegramCallback from './user/TelegramCallback';
import { getState, setActive, updateWallet } from './helpers/publicHelper';
import Stats from './games/sport/Stats';
import LiveScore from './games/sport/LiveScore';
// import Simulate from './lobby/Simulate';
// import UI from './lobby/UI';
import EMSport from './games/sportsbetting/EMSport';
import Game from './games/Game';
import GameList from './games/GameList';
import GeoBlock from './user/GeoBlock';
import PragmaticDGA from './games/pragmatic/PragmaticDGA';
import Demo from './gasp/Demo';
import IMoonLogoAnimation from './components/IMoonLogoAnimation';
import Updating from './layout/Updating';
import LogoAnimation from './LogoAnimation';
import GameLaunch from './user/GameLaunch';
import Theme from './Theme';
import { useMediaQuery } from '@mui/material';

function App(props) {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [state, dispatch] = useContext(Context);
  const [contextGameList, setContextGameList] = useContext(GameListContext);

  const stateRef = useRef();
  stateRef.current = state;

  const setActiveHeight = (showHeader) => {
    setActive(state, dispatch, {
      height: window.innerHeight - (showHeader ? 65 : 0)
    })
  }


  useEffect(() => {
    const token = new URL(window.location.href).searchParams.get('token');
    getState(state, dispatch, setContextGameList, true, token, null, isMobile);
    updateWallet(state);

    // getGameList(setContextGameList);

    window.addEventListener('resize', e => {
      if (stateRef.current.active && (stateRef.current.active.layoutDrawer.left || stateRef.current.active.layoutDrawer.right)) {
      } else {
        setActiveHeight(stateRef.current.active.showHeader)
      }
    })
  }, [])

  useEffect(() => {
    // console.log('show header useeffect:', state.active.height);
    setActiveHeight(stateRef.current.active.showHeader)
  }, [state.active.showHeader])

  return (
    <>
      {/* { (window.location.href.indexOf('3001') > -1) && <DeviceSize></DeviceSize>} */}

      {!state.config.init || state.active.backdrop ?
        <Backdrop open={true} in={true} style={{ zIndex: 999999 }}>
          <LogoAnimation />

          <div className='text-center text-primary'>
            {/* <div>
              <CircularProgress className='text-primary' />
            </div>
            <div>
              Loading
            </div> */}
          </div>
        </Backdrop>
        : <>
          <GeoBlock mode='imoon'></GeoBlock>
          <PragmaticDGA></PragmaticDGA>
        </>}
      <Router>
        <Switch>
          {/* <Route path='/simulate/' exact component={Simulate} /> */}
          <Route path='/GSAP/Demo' exact component={Demo} />
          <Route path='/loading' exact component={LogoAnimation} />

          <Route path='/' exact>
            <Layout>
              <Switch>
                <Route path='/' exact component={Lobby} />
                <Route path='/Lobby' exact component={Lobby} />
              </Switch>
            </Layout>
          </Route>
          <Route>
            <Layout>
              <Switch>
                <Route path='/Theme' exact component={Theme} />

                {/* <Route path='/UI' exact component={UI} /> */}
                <Route path='/tgLogin/:code?' exact component={TelegramCallback} />
                {/* *************** */}
                {/* <Route path='/Games/Digitain/Sport/European/:currentPage?' exact component={isMobile ? MobileView : EuropeanView} />
                <Route path='/Games/Digitain/Sport/MobileView' exact component={MobileView} />
                <Route path='/Games/Digitain/Sport/Asian' exact component={AsianView} />
                <Route path='/Games/Digitain/ESport' exact component={ESport} /> */}
                {/* *************** */}
                <Route path='/Games/SportsBetting/Sport' exact component={EMSport} />
                {/* <Route path='/Games/Sport/Mobile' exact component={MobileView} /> */}
                <Route path='/Games/Stats' exact component={Stats} />
                <Route path='/Games/LiveScore' exact component={LiveScore} />
                {/* Games */}
                <Route path='/Games/Poker' exact component={Poker} />
                {/* <Route path='/Games/Crash' exact component={CrashFrame} /> */}
                <Route path='/Games/Crash' exact component={Crash} />
                <Route path='/Games/CrashStage' exact component={CrashStage} />
                {/* <Route path='/Games/Crash' exact component={Updating} /> */}
                <Route path='/Updating' exact component={Updating} />
                <Route path='/User/PasswordRecoveryCallback/:code?' exact component={PasswordRecoveryCallback} />
                {/* GameLaunch */}
                <Route path='/Game/:provider/:type/:_id' exact component={Game} />
                <Route path='/GameList/:cat/:provider/:type?/:mode?' exact component={GameList} />
                {/* Help */}
                {/* <Route path='/Certificate' exact component={ITechLab} /> */}
                <Route path='/GameLaunch' exact component={GameLaunch} />
                <Route path='/IMoonLogoAnimation' exact component={IMoonLogoAnimation} />
              </Switch>
            </Layout>
          </Route>

        </Switch>
      </Router>
    </>
  );
}
export default App;



