import { MDBIcon } from 'mdb-react-ui-kit'
import React from 'react'

const PlayerTier = (props) => {
    const { tier } = props;
    return (<>
        {[0, 1, 2, 3, 4].map(i => <MDBIcon key={i} className={tier > i ? 'text-neon text-shadow-neon' : 'opacity-80 text-neon fw-light'} icon='star' />)}
    </>
    )
}

export default PlayerTier