import { Box, Typography } from '@mui/material';
import React from 'react'

const ModalHeaderTabs = (props) => {
    const { tabs, setTab, tab } = props;
    const tabCount = tabs.length;
    return <Box className='d-flex flex-column w-100 justify-content-center align-items-center  position-relative'>
        <Box className='d-flex w-100 position-relative'>
            {tabs.map((title, i) => <ModalHeaderTab tab={tab} setTab={setTab} key={i} title={title} index={i} />)}
        </Box>
        <Box className='d-flex w-100 position-absolute' sx={{bottom: 0}}>
            {tabs.map((title, i) => <Bar tab={tab} key={i} index={i} />)}
        </Box>
    </Box>
}

const ModalHeaderTab = (props) => {
    const { setTab, title, index, tab } = props;
    return <Typography
        variant='body2'
        onClick={() => setTab(index)}
        className={`w-100 text-center px-1 cursor-pointer py-md-3 ${tab == index ? 'text-neon' : ''}`}
        sx={{
            flexGrow: 1,
            ...(tab == index && { borderColor: '#26ddff' })
        }}
    >
        {title}
    </Typography>
}

const Bar = (props) => {
    const { index, tab } = props;

    return <Box className='w-100' sx={{
        borderBottom: '2px solid #26ddff',
        borderBottomColor: index == tab ? '#26ddff' : '#000'
    }}>
    </Box>
}

export default ModalHeaderTabs