import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { getBearer, setActive } from '../../helpers/publicHelper';
import { Context } from '../../store';
import { useSnackbar } from 'notistack';
import { Box, useMediaQuery } from '@mui/material';

const url = {
    stage: 'https://sports2-imoon-stage.everymatrix.com/en/',
    prod: 'https://sports2.imoon.com/en/'
}

const EMSport = () => {
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [everyMatrixSport, setEveryMatrixSport] = useState({
        ready: false,
        url: url.stage
    })

    useEffect(() => {
        window.addEventListener("message", (m) => {
            if (m.data.type == 'OMFE:goToRegister') {
                document.getElementById('OpenRight').click();
            }
        })

        if (state.login) {
            setEveryMatrixSport({
                ready: false,
                url: url[state.user.env || 'prod']
            })
            axios.post(process.env.REACT_APP_API_HOST + '/api/users/game/getLaunchUrl',
                { provider: 'everyMatrix' },
                {
                    headers: { 'x-auth-token': getBearer() }
                })
                .then(res => {
                    res = res.data;
                    if (res.success) {
                        setEveryMatrixSport({
                            url: res.result.url || (url[state.user.env || 'prod'] + '?currentSession=' + res.result.ceSession),
                            ready: true
                        })
                    } else {
                        enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2000 });
                    }
                })
                .catch((err) => {
                    setEveryMatrixSport({
                        ready: true,
                        url: url.prod
                    })
                })
        } else {
            if (everyMatrixSport.ready) {
                setEveryMatrixSport({
                    ready: false,
                    url: url.prod
                })
            }
            setTimeout(() => {
                setEveryMatrixSport({
                    ready: true,
                    url: url.prod
                })
            }, 500);
        }
        setActive(state, dispatch, { layoutDrawer: { right: false, left: false } })
    }, [state.login])

    const isMobile = useMediaQuery('(max-width:600px)');


    return (
        <div>
            {everyMatrixSport.ready ?
                <Box sx={{
                    '& iframe': {
                        width: isMobile ? '100vw' : 'calc(100vw - 4rem)',
                    }
                }}>
                    <iframe id='EMiFrame'
                        className='position-fixed'
                        allow="autoplay; fullscreen"
                        // width='100%'
                        src={everyMatrixSport.url}
                        style={{
                            top: state.active.showHeader ? 65 : 1,
                            right: 0,
                           // width: '100vw', //state.active.layoutDrawer.left || state.active.layoutDrawer.right ? 'calc(100vw - 13rem)' : '100vw',
                            height: state.active.height,
                            // iOS hack
                            margin: 0,
                            padding: 0,
                            border: 0,
                            // width: '1px',
                            // minWidth: '100%',
                        }}></iframe>
                </Box>
                : <></>
            }
        </div >
    )
}

export default EMSport
