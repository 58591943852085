
import React, { useState } from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'

const ITech = () => {

    const [country, setCountry] = useState('UK');

    return (
        <MDBCard className='mx-2 position-fixed_' style={{ backgroundColor: '#242329', minHeight: 'calc(100vh - 140px)' }}>
            {/* <ModalPageHeader title={t('RNG Certificate')} {...props}></ModalPageHeader> */}
            <MDBCardBody className='p-2 text-gray position-relative_' style={{
                // minHeight: '80vh'
            }}>
                <MDBRow className='position-absolute_' style={{
                    bottom: 30,
                }}>
                    <MDBCol size='12' md='2' xl='1' className='text-center py-0 text-white'>
                        <MDBRow>
                            <MDBCol size='6' md='12' className='px-0 py-3'>
                                <div className='py-md-3 text-center cursor-pointer' onClick={() => setCountry('UK')}>
                                    <img src='/itechlab/united-kingdom.svg' alt='uk certificate' height='30' />
                                    <h6 className='my-0 mx-1 d-none d-md-inline-block'>UK</h6>
                                    {country === 'UK' ? <MDBIcon className='ms-2 pt-1 d-none d-md-inline-block' icon='chevron-right'></MDBIcon> : <></>}
                                    {country === 'UK' ? <MDBIcon className='ms-2 d-md-none' icon='chevron-down'></MDBIcon> : <></>}
                                </div>
                            </MDBCol>
                            <MDBCol size='6' md='12' className='px-0 py-3'>
                                <div className='py-md-3 text-center cursor-pointer' onClick={() => setCountry('MT')}>
                                    <img src='/itechlab/malta.svg' alt='malta certificate' height='30' />
                                    <h6 className='my-0 mx-1 d-none d-md-inline-block'>Malta</h6>
                                    {country === 'MT' ? <MDBIcon className='ms-2 pt-1 d-none d-md-inline-block' icon='chevron-right'></MDBIcon> : <></>}
                                    {country === 'MT' ? <MDBIcon className='ms-2 d-md-none' icon='chevron-down'></MDBIcon> : <></>}
                                </div>
                            </MDBCol>
                        </MDBRow>


                    </MDBCol>
                    <MDBCol size='12' md='10' xl='11' className='text-center py-0 overflow-scroll'>
                        <img src={`/itechlab/RNG_Certificate_${country}_iMoonBV_16June2021.png`} alt={country} className='img-fluid' />
                        <a className='position-absolute'
                            rel="noreferrer"
                            style={{
                                opacity: 0,
                                top: '52.5%',
                                left: '35%',
                                width: '7%',
                                height: '2%'
                            }}
                            target='_blank' href={`https://itechlabs.com/certificates/imoonbv/RNG_Certificate_${country}_iMoonBV_16June2021.pdf`}>.</a>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard >
    )
}

export default ITech
