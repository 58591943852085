import { Box, Typography } from '@mui/material'
import React from 'react'

const Theme = () => {
    return <Box sx={{
        paddingLeft: ['0.5rem', '4.5rem']
    }}>
        {
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'subtitle1', 'subtitle2', 'button', 'caption', 'overline'].map((variant, i) =>
                <Box key={i}>
                    <Typography variant={variant}>{variant}</Typography>
                </Box>
            )
        }
    </Box>
}

export default Theme