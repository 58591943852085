import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const Updating = (props) => {
    const { title } = props;
    return (
        <Box sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
                <Typography sx={{
                    transform: 'translateY(-50%)'
                }} variant='h6' className='text-neon text-center'>
                    <CircularProgress color='info'></CircularProgress>
                    <div className=''>
                        {title} is updating...
                    </div>
                </Typography>
        </Box>
    )
}

export default Updating