import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { getBearer, setActive } from '../helpers/publicHelper';
import { Context } from '../store';
import LayoutProgress from '../layout/LayoutProgress';
import ThemeButton from '../layout/components/ThemeButton';
import GeoBlock from '../user/GeoBlock';
import { useMediaQuery } from '@mui/material';
import $ from 'jquery'
const Game = (props) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const provider = props.match.params.provider;
    const type = props.match.params.type;
    const _id = props.match.params._id;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        url: '',
        ready: false
    });

    const getLaunchURL = (setValues) => {
        let params = {};
        // if ((provider == 'pragmatic') && (state.user.profile.role.indexOf('test') == -1)) {
        //     return enqueueSnackbar('Not Available', { variant: 'error', autoHideDuration: 2000 });
        // }
        if (type == 'bingo') {
            params = {
                gameURLs: {
                    lobbyURL: '',
                    profileURL: '',
                    promotionURL: '',
                    rcHistoryUrl: '',
                    rcCloseUrl: '',
                    gamesLobby: 'https://www.imoon.com/gamelist/casino/all/bingo',
                    responsibleGaming: '',
                    selfTestPageURL: '',
                    budgetURL: '',
                    suspensionURL: ''
                }
            }
        }
        axios.post(process.env.REACT_APP_API_HOST + '/api/users/game/getLaunchUrl', {
            _id,
            params
        }, {
            headers: { 'x-auth-token': getBearer() }
        })
            .then((res) => {
                res = res.data;
                if (res.success) {
                    setValues({
                        ready: true,
                        url: res.result.url // res.result.result ? res.result.result.entryEmbedded : res.result
                    });
                    setActive(state, dispatch, { layoutDrawer: { right: false, left: false } })
                } else {
                    enqueueSnackbar(res.message, { variant: 'error', autoHideDuration: 2000 });
                }
            })
            .catch((err) => {
                try {
                    console.log('err', err.response.data.message);
                } catch (error) {
                    console.log('error');
                }

            })
    }

    useEffect(() => {
        console.log('state.login::', state.login)
        if (state.login) {
            getLaunchURL(setValues);
        }
    }, [state.login])

    const mql = window.matchMedia('(orientation: portrait)');
    const [deviceOrientation, setDeviceOrientation] = useState(mql && mql.matches ? 'portrait' : 'landscape')
    useEffect(() => {
        window.addEventListener('message', (e) => {
            const { data } = e;
            if (!data) return;
            // console.log('Window Message Listenerd: ', e);
            if ((e.origin == 'https://imoon.prerelease-env.biz') && (data.name == 'openCashier')) {
                console.log('Open Cashier Post Message');
                document.getElementById('OpenRight').click()

            } else {
            }
        })

        mql.addEventListener('change', m => {
            setDeviceOrientation(m.matches ? 'portrait' : 'landscape')
        })
    }, [_id])

    useEffect(() => {
        if (deviceOrientation == 'landscape' && isMobile) {
            // $('#AppBar').slideUp(100)
            // setActive(state, dispatch, { showHeader: false })
        } else {
            // $('#AppBar').slideDown(100)
            // setActive(state, dispatch, { showHeader: true })
        }
    }, [deviceOrientation])

    return (
        <div id='GameLaunchWrapper'>
            {/* <DeviceSize></DeviceSize> */}
            {<GeoBlock mode={provider}></GeoBlock>}

            {state.login ?
                <>
                    {values.ready ?
                        //  allowFullScreen webkitallowfullscreen mozallowfullscreen allow="xr-spatial-tracking fullscreen" (Pragmatic)
                        <iframe
                            allowFullScreen
                            allow="xr-spatial-tracking"
                            name="gameIFrame"
                            id="gameIFrame"
                            src={values.url}
                            className='position-fixed'
                            style={{
                                top: state.active.showHeader ? 65 : 1,
                                left: 0,
                                width: '100vw',
                                height: state.active.height
                            }}></iframe> : <></>}
                </>
                :
                <LayoutProgress>
                    {state.config.init &&
                        <div className='position-relative d-flex m-auto' style={{ textAlign: 'center', alignItems: 'center', alignContent: 'center', width: '15rem', height: '20rem' }}>
                            <div style={{ left: 0, right: 0, marginBottom: '0rem' }} className='neonProgress position-absolute mx-auto'></div>

                            {state.login ? <></> :
                                <ThemeButton
                                    // onClick={() => setActive(state, dispatch, { layoutDrawer: { ...state.active.layoutDrawer, right: true } })}
                                    onClick={() => $('#SignInButton').trigger('click')}
                                    variant='outlined' style={{ marginTop: '10rem' }} className={`mx-auto ${state.config.init ? '' : 'd-none'}`}>Login to Play</ThemeButton>
                            }
                        </div>
                    }
                </LayoutProgress>
            }

        </div>
    )
}

export default Game
