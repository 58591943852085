import grey from '@mui/material/colors/grey';
import { amber, green, lime, orange, pink, yellow } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material';


const defaultColor = {
    main: '#fff',
    dark: '#121318',
    light: '#333',
    contrastText: "#000"
}

const neonColor = {
    main: '#26ddff'
}

let initTheme = createTheme();

let theme = createTheme({
    palette: {
        shadows: {
            neon: {
                1: '0px 0px 10px #26ddff !important'
            }
        },
        mode: 'dark',
        primary: {
            main: grey[900],
        },
        secondary: {
            // main: amber[700],
            main: '#26ddff',
        },
        danger: {
            main: '#f93154'
        },
        success: {
            main: '#0f6'
        },
        warning: {
            main: '#fc0'
        },
        neon: {
            main: '#26ddff',
            dark: '#26ddff',
            light: '#26ddff',
            contrastText: '#000',
        },
        pink: {
            main: pink[800]
        },
        green: {
            main: green[500]
        },
        orange: {
            main: orange[800]
        },
        yellow: {
            main: yellow[700],
            dark: yellow[900],
            light: yellow[500],
            contrastText: "#000"
        }
    },
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: defaultColor.dark,
                color: '#fff'
            },
        },
        MuiRadio: {
            root: {
                backgroundColor: defaultColor.dark,
                color: '#fff6'
            },
        },
        MuiInputLabel: {
            color: "#fff !important",
            root: {
                color: "#fff !important",
            },
        }
    },
    typography: {
        // body1: {
        //     fontSize: '0.9rem',
        //     [initTheme.breakpoints.down("sm")]: {
        //         fontSize: "1rem"
        //     }
        // },
        // body2: {
        //     fontSize: '0.7rem',
        //     [initTheme.breakpoints.down("sm")]: {
        //         fontSize: "0.8rem"
        //     }
        // },
        // subtitle1: {
        //     fontSize: '0.7rem',
        //     [initTheme.breakpoints.down("sm")]: {
        //         fontSize: "0.6rem"
        //     },
        // },
        // subtitle2: {
        //     fontSize: '0.6rem',
        //     [initTheme.breakpoints.down("sm")]: {
        //         fontSize: "0.6rem"
        //     }
        // },
    },
    // ***************************************************//
    gradientBG: {
        yellow: 'linear-gradient(90deg, #FF8800 10.41%, #FFCD1E 85.35%)'
    },
    gradientText: {
        yellow: {
            background: 'linear-gradient(90deg, #FF8800 10.41%, #FFCD1E 85.35%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
        }
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    color: defaultColor.main,
                    // filter: `drop-shadow(0px 0px 3px ${neonColor.main})`,
                    '&.Mui-selected': {
                        color: neonColor.main,
                        // filter: `drop-shadow(0px 0px 3px ${neonColor.main})`,
                    },
                }
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '3rem !important',
                    [initTheme.breakpoints.down("sm")]: {
                        minHeight: '2.5rem !important'
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 99999
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    padding: '1rem 0',
                },
                MuiInputLabel: {
                    display: 'none'
                },
                input: {
                    fontSize: '0.8rem !important',
                    padding: '0 !important'
                }
            }
        },
        MuiDrawer: {
            // display: 'none !important',
            // backgroundColor: '#000 !important',
            // styleOverrides: {
            //   root: {
            //     display: 'none !important',
            //     backgroundColor: '#000 !important',
            //   },
            //   MuiPaper: {
            //     display: 'none !important',
            //     backgroundColor: '#000 !important'
            //   },
            // }
        },
        // MuiFormControlLabel: {
        //   root: {
        //     // marginle
        //   }
        // },
        // MuiTextField: {
        //   root: {
        //     '& .MuiFormLabel-root.Mui-disabled': {
        //       color: 'red',
        //     },
        //   },
        // }
    },
});

// theme = responsiveFontSizes(theme, {
//     breakpoints: ['sm', 'md', 'lg'],
//     factor: 0.4
// });


export default theme